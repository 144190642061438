import React from 'react';
import styled from 'styled-components';
import { useCustomContext } from '../store/CustomContext';

const IndexDiv = styled.div`
    margin: 10px;
    height: 100%;
    overflow-y: auto;
`;

const Directions = () => {
  const {appState } = useCustomContext();
  const { selectedRecipe } = appState;

  return (
    <IndexDiv>
        <p style={{whiteSpace: 'pre-line', lineHeight:'1.5em'}}>{decodeURIComponent(selectedRecipe.directions)}</p>
    </IndexDiv>
  )
}
export default Directions;