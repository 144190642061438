import React from 'react';
import styled from 'styled-components';
import TreeView from '@mui/lab/TreeView';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import TreeItem from '@mui/lab/TreeItem';
import { useCustomContext } from '../store/CustomContext';
import { SELECT_RECIPE } from '../store/Actions';
import { useNavigate } from 'react-router-dom';

const IndexDiv = styled.div`
    height: 100%;
    width: 420px;
    border: solid 1px #808080;
`;


const RecipeList = () => {

    const {appState } = useCustomContext();
    const { recipesSorted, categories } = appState;
    const navigate = useNavigate();
    
    const findCatName = catId => {
        let catName = categories.find(c => {return c.categoryId == catId});
        return catName ? catName.categoryName : 'Unlabeled';
    }

    const selectRecipe = (recipe, categoryId) => {
        navigate(`${categoryId}/${recipe.id}`);
    }

  return (
    <IndexDiv>

        <TreeView
            aria-label="file system navigator"
            defaultCollapseIcon={<ExpandMoreIcon />}
            defaultExpandIcon={<ChevronRightIcon />}
            sx={{ height: '95%', width: '100%', overflowY: 'auto', overflowX:'hidden', margin: '10px 0' }}
        >
            {Object.keys(recipesSorted).sort((a,b) => findCatName(a) > findCatName(b) ? 1 : -1).map(catId => <TreeItem key={`category-${catId}`} nodeId={catId} label={findCatName(catId)}>
                {recipesSorted[catId].map(r => <TreeItem key={`recipe-${r.title}`} nodeId={`${r.title}`} label={`• ${r.title}`} onClick={() => selectRecipe(r, catId)}
                sx={{overflowWrap: "break-word", textIndent: '-1.2em', paddingLeft:'0.2em'}}></TreeItem>)}
            </TreeItem>)}
        
        </TreeView>
    </IndexDiv>
  )
}

export default RecipeList;