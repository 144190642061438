import React, {useEffect} from 'react'
import { useCustomContext } from '../store/CustomContext';
import { LOAD_CATEGORIES } from '../store/Actions';
import styled from 'styled-components';
import Category from './Category';

const IndexDiv = styled.div`
    margin: 0 10px;
    display: flex;
    flex-direction: row;
    font-size: 14px;
`;

const Categories = ({recipe}) => {
    const {appState, appDispatch } = useCustomContext();
    const { selectedRecipe, selectedCategories } = appState;

    useEffect(() => {
      appDispatch({type:LOAD_CATEGORIES, value:selectedRecipe.id});
    }, [selectedRecipe])
    
  return (
    <>
      <IndexDiv> 
        <div style={{fontSize: '14px'}}>Categories:</div>
        {selectedCategories.map(c => <Category key={`categorylist-${c.categoryId}`} data={c} />)}
      </IndexDiv>
    </>
  )
}
export default Categories;