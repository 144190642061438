import { Typography } from '@mui/material';
import React, { useRef, useEffect } from 'react';
import styled from 'styled-components';
import { useCustomContext } from '../store/CustomContext';
import { makeStyles } from '@material-ui/core/styles';
import IngredientsList from './IngredientsList';
import Directions from './Directions';
import Categories from './Categories';
import { useParams } from 'react-router-dom';
import { SELECT_RECIPE } from '../store/Actions';

const IndexDiv = styled.div`
    width: 100%;
    border: solid 1px #808080;
    overflow-y: hidden;
`;
const EmptyLbl = styled.div`
    height: 100%;
    width: 100%;
    text-align: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    color: #9c9c9c;
`;

const useStyles = makeStyles((theme) => ({
    Title: {
        fontSize: '24px !important',
    },
    Source: {
        fontSize: '14px !important',
        padding: '0 10px'


    }
}));

const RecipeForm = () => {
    const {appState, appDispatch } = useCustomContext();
    const { selectedRecipe, recipesSorted } = appState;
    const classes = useStyles();
    const scrollRef = useRef(null);
    const params = useParams();

    useEffect(() => {
        if(selectedRecipe && selectedRecipe.id == params.recipeId) return;
        if(!recipesSorted.length > 0) return;
        
        appDispatch({type:SELECT_RECIPE, value:{categoryId: params.catId, recipeId: params.recipeId}});
      }, [params, recipesSorted])
        
    useEffect(() => {
      if(scrollRef.current){
        scrollRef.current.scrollTo(0, 0);
      }
    }, [selectedRecipe])
    
    if(!selectedRecipe) 
        return (<IndexDiv><EmptyLbl>Please select a recipe on the left.</EmptyLbl></IndexDiv>);

  return (
    <IndexDiv>
        <div ref={scrollRef} style={{ padding: '10px 20px', overflowY:'auto', height:'100%'}}>
            <Typography className={classes.Title}>{selectedRecipe.title}</Typography>
            {selectedRecipe.source && <div style={{display:'flex', flexDirection:'row'}}><Typography className={classes.Source} style={{width:'66 px'}}>{`From: `}</Typography><Typography className={classes.Source}>{`${selectedRecipe.source}`}</Typography></div> }
            
            <Categories />
            <IngredientsList />
            <Directions />
        </div>
    </IndexDiv>
  )
}

export default RecipeForm;