import React, {useEffect} from 'react'
import { useCustomContext } from '../store/CustomContext';
import { LOAD_INGREDIENTS } from '../store/Actions';
import Ingredient from './Ingredient';
import styled from 'styled-components';

const IndexDiv = styled.div`
    margin: 10px;
    padding-left: 50px;
`;

const IngredientsList = () => {
    const {appState, appDispatch } = useCustomContext();
    const { selectedRecipe, selectedIngredients } = appState;

    useEffect(() => {
      appDispatch({type:LOAD_INGREDIENTS, value:selectedRecipe.id});
    }, [selectedRecipe])
    
  return (
    <>
        <div style={{fontSize: '14px', padding:'0 0 0 10px', marginTop:'-8px'}}>Ingredients:</div>
        <IndexDiv>
            {selectedIngredients.map(i => <Ingredient key={`ingredient-${i.ingredientId}`} data={i} />)}
        </IndexDiv>
    </>
  )
}
export default IngredientsList;