import React from 'react'
import styled from 'styled-components';

const IndexDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  padding-bottom: 10px;
`;
const Amount = styled.div`
  width: 50px;
`;
const Measurement = styled.div`
  width: 170px
`;
const IngName = styled.div`
  width: 50%;
`;

const Ingredient = ({data}) => {
  return (
    <IndexDiv>
      <Amount>{data.amount}</Amount>
      <Measurement>{data.measurement}</Measurement>
      <IngName>{data.ingredientName}</IngName>
    </IndexDiv>
  )
}
export default Ingredient;