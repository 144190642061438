import { LOAD_RECIPES, RESET, SELECT_RECIPE, LOAD_CATEGORIES } from '../store/Actions';
import CATEGORIES from '../data/categories';
import RECIPE_CATEGORIES from '../data/recipeCategories';
import INGREDIENTS from '../data/ingredients';
import RECIPES from '../data/recipes';
import { LOAD_INGREDIENTS } from './Actions';
import { css } from '@emotion/react';

const initialState = {
    categories: CATEGORIES,
    ingredients: INGREDIENTS,
    recipes: RECIPES,
    recipesSorted: [],
    recipeCategories: RECIPE_CATEGORIES,
    selectedRecipe: null,
    selectedIngredients: [],
    selectedCategories: [],
    errors: {},
    validating: false,
    valid: false,
    confirmed: false,
    dataConsolidated: false
  };

export function initializeState() {
    return Object.assign({}, initialState);
}

export const  appReducer=(state = [], action)=> {
    switch (action.type) {
        case LOAD_RECIPES:
            if(state.dataConsolidated) return state;
            state.dataConsolidated = true;

            let grouped = state.recipeCategories.reduce(((all, r) => {if(!all[r.categoryId]) all[r.categoryId] = []; all[r.categoryId].push(r); return all; }), []);
            let matched = grouped.map(g => {let g2 = g.map(i => state.recipes.find(r => r.id === i.recipeId)); return g2;});
            
            state.recipesSorted = matched;
            return {
                ...state,
                recipesSorted :  matched
            };
        case SELECT_RECIPE:
            let selRecipe = state.recipesSorted[action.value.categoryId].find(r => { return r.id == action.value.recipeId});
            state.selectedRecipe = selRecipe;

            return {
                ...state,
                selectedRecipe :  selRecipe
            };
        case LOAD_INGREDIENTS:
            let ings = state.ingredients.filter(i => i.recipeId === action.value);
            
            state.selectedIngredients = ings;
            return {
                ...state,
                selectedIngredients :  ings
            };
        case LOAD_CATEGORIES:
            let cats = state.recipeCategories.filter(i => i.recipeId === action.value);
            let cs = cats.map(c => {let cat = state.categories.find(c2 => c.categoryId === c2.categoryId); return cat;});
            
            state.selectedCategories = cs;
            return {
                ...state,
                selectedCategories :  cs
            };
        case RESET:
            return initialState;
        default:
            return state;
    }
}

