import React from 'react'
import styled from 'styled-components';

const IndexDiv = styled.div`
  display: flex;
  flex-direction: row;
  padding-bottom: 10px;
`;
const Amount = styled.div`
 padding: 0 10px;
`;

const Category = ({data}) => {
  return (
    <IndexDiv>
      <Amount>{`◦	 ${data.categoryName}`}</Amount>
    </IndexDiv>
  )
}
export default Category;