const RECIPE_CATEGORIES = [
  {
    "recipeId": 3,
    "categoryId": 1
  },
  {
    "recipeId": 3,
    "categoryId": 19
  },
  {
    "recipeId": 3,
    "categoryId": 11
  },
  {
    "recipeId": 3,
    "categoryId": 13
  },
  {
    "recipeId": 3,
    "categoryId": 18
  },
  {
    "recipeId": 4,
    "categoryId": 1
  },
  {
    "recipeId": 4,
    "categoryId": 19
  },
  {
    "recipeId": 4,
    "categoryId": 20
  },
  {
    "recipeId": 5,
    "categoryId": 1
  },
  {
    "recipeId": 5,
    "categoryId": 9
  },
  {
    "recipeId": 5,
    "categoryId": 20
  },
  {
    "recipeId": 6,
    "categoryId": 1
  },
  {
    "recipeId": 6,
    "categoryId": 19
  },
  {
    "recipeId": 7,
    "categoryId": 1
  },
  {
    "recipeId": 7,
    "categoryId": 9
  },
  {
    "recipeId": 7,
    "categoryId": 18
  },
  {
    "recipeId": 10,
    "categoryId": 1
  },
  {
    "recipeId": 12,
    "categoryId": 1
  },
  {
    "recipeId": 12,
    "categoryId": 15
  },
  {
    "recipeId": 13,
    "categoryId": 1
  },
  {
    "recipeId": 14,
    "categoryId": 1
  },
  {
    "recipeId": 14,
    "categoryId": 9
  },
  {
    "recipeId": 14,
    "categoryId": 20
  },
  {
    "recipeId": 15,
    "categoryId": 1
  },
  {
    "recipeId": 15,
    "categoryId": 17
  },
  {
    "recipeId": 16,
    "categoryId": 1
  },
  {
    "recipeId": 16,
    "categoryId": 20
  },
  {
    "recipeId": 19,
    "categoryId": 1
  },
  {
    "recipeId": 20,
    "categoryId": 1
  },
  {
    "recipeId": 20,
    "categoryId": 19
  },
  {
    "recipeId": 20,
    "categoryId": 14
  },
  {
    "recipeId": 20,
    "categoryId": 18
  },
  {
    "recipeId": 21,
    "categoryId": 1
  },
  {
    "recipeId": 24,
    "categoryId": 1
  },
  {
    "recipeId": 25,
    "categoryId": 1
  },
  {
    "recipeId": 25,
    "categoryId": 9
  },
  {
    "recipeId": 25,
    "categoryId": 11
  },
  {
    "recipeId": 26,
    "categoryId": 1
  },
  {
    "recipeId": 27,
    "categoryId": 1
  },
  {
    "recipeId": 28,
    "categoryId": 1
  },
  {
    "recipeId": 28,
    "categoryId": 17
  },
  {
    "recipeId": 29,
    "categoryId": 1
  },
  {
    "recipeId": 30,
    "categoryId": 1
  },
  {
    "recipeId": 30,
    "categoryId": 9
  },
  {
    "recipeId": 30,
    "categoryId": 18
  },
  {
    "recipeId": 32,
    "categoryId": 1
  },
  {
    "recipeId": 32,
    "categoryId": 16
  },
  {
    "recipeId": 33,
    "categoryId": 1
  },
  {
    "recipeId": 33,
    "categoryId": 9
  },
  {
    "recipeId": 34,
    "categoryId": 1
  },
  {
    "recipeId": 34,
    "categoryId": 19
  },
  {
    "recipeId": 34,
    "categoryId": 9
  },
  {
    "recipeId": 35,
    "categoryId": 1
  },
  {
    "recipeId": 37,
    "categoryId": 1
  },
  {
    "recipeId": 37,
    "categoryId": 9
  },
  {
    "recipeId": 37,
    "categoryId": 20
  },
  {
    "recipeId": 38,
    "categoryId": 1
  },
  {
    "recipeId": 39,
    "categoryId": 2
  },
  {
    "recipeId": 39,
    "categoryId": 19
  },
  {
    "recipeId": 40,
    "categoryId": 2
  },
  {
    "recipeId": 41,
    "categoryId": 2
  },
  {
    "recipeId": 41,
    "categoryId": 19
  },
  {
    "recipeId": 42,
    "categoryId": 2
  },
  {
    "recipeId": 42,
    "categoryId": 19
  },
  {
    "recipeId": 44,
    "categoryId": 3
  },
  {
    "recipeId": 44,
    "categoryId": 19
  },
  {
    "recipeId": 44,
    "categoryId": 20
  },
  {
    "recipeId": 44,
    "categoryId": 12
  },
  {
    "recipeId": 45,
    "categoryId": 3
  },
  {
    "recipeId": 45,
    "categoryId": 19
  },
  {
    "recipeId": 45,
    "categoryId": 20
  },
  {
    "recipeId": 45,
    "categoryId": 12
  },
  {
    "recipeId": 46,
    "categoryId": 3
  },
  {
    "recipeId": 46,
    "categoryId": 8
  },
  {
    "recipeId": 46,
    "categoryId": 21
  },
  {
    "recipeId": 47,
    "categoryId": 3
  },
  {
    "recipeId": 47,
    "categoryId": 21
  },
  {
    "recipeId": 48,
    "categoryId": 3
  },
  {
    "recipeId": 48,
    "categoryId": 19
  },
  {
    "recipeId": 48,
    "categoryId": 4
  },
  {
    "recipeId": 48,
    "categoryId": 8
  },
  {
    "recipeId": 48,
    "categoryId": 12
  },
  {
    "recipeId": 49,
    "categoryId": 3
  },
  {
    "recipeId": 49,
    "categoryId": 18
  },
  {
    "recipeId": 50,
    "categoryId": 3
  },
  {
    "recipeId": 51,
    "categoryId": 3
  },
  {
    "recipeId": 51,
    "categoryId": 4
  },
  {
    "recipeId": 52,
    "categoryId": 3
  },
  {
    "recipeId": 52,
    "categoryId": 19
  },
  {
    "recipeId": 52,
    "categoryId": 4
  },
  {
    "recipeId": 52,
    "categoryId": 8
  },
  {
    "recipeId": 53,
    "categoryId": 3
  },
  {
    "recipeId": 53,
    "categoryId": 19
  },
  {
    "recipeId": 53,
    "categoryId": 12
  },
  {
    "recipeId": 54,
    "categoryId": 3
  },
  {
    "recipeId": 54,
    "categoryId": 18
  },
  {
    "recipeId": 54,
    "categoryId": 21
  },
  {
    "recipeId": 55,
    "categoryId": 3
  },
  {
    "recipeId": 56,
    "categoryId": 3
  },
  {
    "recipeId": 56,
    "categoryId": 19
  },
  {
    "recipeId": 56,
    "categoryId": 8
  },
  {
    "recipeId": 56,
    "categoryId": 12
  },
  {
    "recipeId": 57,
    "categoryId": 3
  },
  {
    "recipeId": 57,
    "categoryId": 12
  },
  {
    "recipeId": 58,
    "categoryId": 19
  },
  {
    "recipeId": 58,
    "categoryId": 8
  },
  {
    "recipeId": 58,
    "categoryId": 20
  },
  {
    "recipeId": 60,
    "categoryId": 19
  },
  {
    "recipeId": 61,
    "categoryId": 19
  },
  {
    "recipeId": 62,
    "categoryId": 19
  },
  {
    "recipeId": 62,
    "categoryId": 18
  },
  {
    "recipeId": 63,
    "categoryId": 19
  },
  {
    "recipeId": 63,
    "categoryId": 4
  },
  {
    "recipeId": 63,
    "categoryId": 8
  },
  {
    "recipeId": 64,
    "categoryId": 19
  },
  {
    "recipeId": 64,
    "categoryId": 8
  },
  {
    "recipeId": 64,
    "categoryId": 12
  },
  {
    "recipeId": 65,
    "categoryId": 19
  },
  {
    "recipeId": 65,
    "categoryId": 4
  },
  {
    "recipeId": 65,
    "categoryId": 8
  },
  {
    "recipeId": 65,
    "categoryId": 20
  },
  {
    "recipeId": 66,
    "categoryId": 19
  },
  {
    "recipeId": 66,
    "categoryId": 8
  },
  {
    "recipeId": 67,
    "categoryId": 19
  },
  {
    "recipeId": 68,
    "categoryId": 19
  },
  {
    "recipeId": 68,
    "categoryId": 4
  },
  {
    "recipeId": 68,
    "categoryId": 8
  },
  {
    "recipeId": 69,
    "categoryId": 19
  },
  {
    "recipeId": 69,
    "categoryId": 4
  },
  {
    "recipeId": 69,
    "categoryId": 8
  },
  {
    "recipeId": 70,
    "categoryId": 19
  },
  {
    "recipeId": 70,
    "categoryId": 4
  },
  {
    "recipeId": 70,
    "categoryId": 8
  },
  {
    "recipeId": 70,
    "categoryId": 14
  },
  {
    "recipeId": 71,
    "categoryId": 19
  },
  {
    "recipeId": 71,
    "categoryId": 11
  },
  {
    "recipeId": 73,
    "categoryId": 19
  },
  {
    "recipeId": 73,
    "categoryId": 13
  },
  {
    "recipeId": 73,
    "categoryId": 16
  },
  {
    "recipeId": 74,
    "categoryId": 19
  },
  {
    "recipeId": 74,
    "categoryId": 16
  },
  {
    "recipeId": 75,
    "categoryId": 19
  },
  {
    "recipeId": 75,
    "categoryId": 20
  },
  {
    "recipeId": 76,
    "categoryId": 19
  },
  {
    "recipeId": 76,
    "categoryId": 20
  },
  {
    "recipeId": 77,
    "categoryId": 19
  },
  {
    "recipeId": 77,
    "categoryId": 14
  },
  {
    "recipeId": 78,
    "categoryId": 19
  },
  {
    "recipeId": 78,
    "categoryId": 18
  },
  {
    "recipeId": 79,
    "categoryId": 19
  },
  {
    "recipeId": 79,
    "categoryId": 18
  },
  {
    "recipeId": 81,
    "categoryId": 19
  },
  {
    "recipeId": 81,
    "categoryId": 13
  },
  {
    "recipeId": 82,
    "categoryId": 19
  },
  {
    "recipeId": 82,
    "categoryId": 6
  },
  {
    "recipeId": 82,
    "categoryId": 21
  },
  {
    "recipeId": 83,
    "categoryId": 19
  },
  {
    "recipeId": 83,
    "categoryId": 20
  },
  {
    "recipeId": 83,
    "categoryId": 16
  },
  {
    "recipeId": 84,
    "categoryId": 4
  },
  {
    "recipeId": 84,
    "categoryId": 8
  },
  {
    "recipeId": 85,
    "categoryId": 4
  },
  {
    "recipeId": 85,
    "categoryId": 8
  },
  {
    "recipeId": 85,
    "categoryId": 20
  },
  {
    "recipeId": 86,
    "categoryId": 4
  },
  {
    "recipeId": 86,
    "categoryId": 7
  },
  {
    "recipeId": 86,
    "categoryId": 8
  },
  {
    "recipeId": 87,
    "categoryId": 4
  },
  {
    "recipeId": 87,
    "categoryId": 20
  },
  {
    "recipeId": 87,
    "categoryId": 14
  },
  {
    "recipeId": 88,
    "categoryId": 4
  },
  {
    "recipeId": 88,
    "categoryId": 7
  },
  {
    "recipeId": 88,
    "categoryId": 8
  },
  {
    "recipeId": 89,
    "categoryId": 4
  },
  {
    "recipeId": 89,
    "categoryId": 7
  },
  {
    "recipeId": 89,
    "categoryId": 8
  },
  {
    "recipeId": 90,
    "categoryId": 4
  },
  {
    "recipeId": 90,
    "categoryId": 8
  },
  {
    "recipeId": 91,
    "categoryId": 4
  },
  {
    "recipeId": 91,
    "categoryId": 7
  },
  {
    "recipeId": 91,
    "categoryId": 8
  },
  {
    "recipeId": 92,
    "categoryId": 4
  },
  {
    "recipeId": 92,
    "categoryId": 7
  },
  {
    "recipeId": 92,
    "categoryId": 8
  },
  {
    "recipeId": 93,
    "categoryId": 4
  },
  {
    "recipeId": 93,
    "categoryId": 8
  },
  {
    "recipeId": 93,
    "categoryId": 21
  },
  {
    "recipeId": 94,
    "categoryId": 4
  },
  {
    "recipeId": 94,
    "categoryId": 8
  },
  {
    "recipeId": 95,
    "categoryId": 5
  },
  {
    "recipeId": 95,
    "categoryId": 8
  },
  {
    "recipeId": 97,
    "categoryId": 5
  },
  {
    "recipeId": 97,
    "categoryId": 8
  },
  {
    "recipeId": 98,
    "categoryId": 5
  },
  {
    "recipeId": 98,
    "categoryId": 7
  },
  {
    "recipeId": 98,
    "categoryId": 8
  },
  {
    "recipeId": 99,
    "categoryId": 5
  },
  {
    "recipeId": 99,
    "categoryId": 8
  },
  {
    "recipeId": 100,
    "categoryId": 7
  },
  {
    "recipeId": 101,
    "categoryId": 7
  },
  {
    "recipeId": 102,
    "categoryId": 7
  },
  {
    "recipeId": 103,
    "categoryId": 7
  },
  {
    "recipeId": 103,
    "categoryId": 8
  },
  {
    "recipeId": 105,
    "categoryId": 7
  },
  {
    "recipeId": 105,
    "categoryId": 8
  },
  {
    "recipeId": 106,
    "categoryId": 7
  },
  {
    "recipeId": 107,
    "categoryId": 7
  },
  {
    "recipeId": 108,
    "categoryId": 7
  },
  {
    "recipeId": 109,
    "categoryId": 7
  },
  {
    "recipeId": 109,
    "categoryId": 8
  },
  {
    "recipeId": 110,
    "categoryId": 7
  },
  {
    "recipeId": 111,
    "categoryId": 7
  },
  {
    "recipeId": 112,
    "categoryId": 7
  },
  {
    "recipeId": 113,
    "categoryId": 7
  },
  {
    "recipeId": 114,
    "categoryId": 7
  },
  {
    "recipeId": 114,
    "categoryId": 8
  },
  {
    "recipeId": 116,
    "categoryId": 7
  },
  {
    "recipeId": 118,
    "categoryId": 7
  },
  {
    "recipeId": 120,
    "categoryId": 8
  },
  {
    "recipeId": 120,
    "categoryId": 20
  },
  {
    "recipeId": 120,
    "categoryId": 14
  },
  {
    "recipeId": 121,
    "categoryId": 8
  },
  {
    "recipeId": 121,
    "categoryId": 20
  },
  {
    "recipeId": 121,
    "categoryId": 21
  },
  {
    "recipeId": 122,
    "categoryId": 8
  },
  {
    "recipeId": 122,
    "categoryId": 22
  },
  {
    "recipeId": 123,
    "categoryId": 8
  },
  {
    "recipeId": 123,
    "categoryId": 14
  },
  {
    "recipeId": 124,
    "categoryId": 8
  },
  {
    "recipeId": 124,
    "categoryId": 9
  },
  {
    "recipeId": 125,
    "categoryId": 8
  },
  {
    "recipeId": 125,
    "categoryId": 9
  },
  {
    "recipeId": 126,
    "categoryId": 8
  },
  {
    "recipeId": 127,
    "categoryId": 8
  },
  {
    "recipeId": 127,
    "categoryId": 12
  },
  {
    "recipeId": 128,
    "categoryId": 8
  },
  {
    "recipeId": 128,
    "categoryId": 20
  },
  {
    "recipeId": 129,
    "categoryId": 8
  },
  {
    "recipeId": 129,
    "categoryId": 21
  },
  {
    "recipeId": 130,
    "categoryId": 23
  },
  {
    "recipeId": 130,
    "categoryId": 20
  },
  {
    "recipeId": 132,
    "categoryId": 23
  },
  {
    "recipeId": 133,
    "categoryId": 23
  },
  {
    "recipeId": 134,
    "categoryId": 23
  },
  {
    "recipeId": 135,
    "categoryId": 20
  },
  {
    "recipeId": 135,
    "categoryId": 21
  },
  {
    "recipeId": 136,
    "categoryId": 20
  },
  {
    "recipeId": 137,
    "categoryId": 20
  },
  {
    "recipeId": 137,
    "categoryId": 13
  },
  {
    "recipeId": 137,
    "categoryId": 16
  },
  {
    "recipeId": 137,
    "categoryId": 21
  },
  {
    "recipeId": 139,
    "categoryId": 10
  },
  {
    "recipeId": 140,
    "categoryId": 10
  },
  {
    "recipeId": 140,
    "categoryId": 18
  },
  {
    "recipeId": 142,
    "categoryId": 10
  },
  {
    "recipeId": 142,
    "categoryId": 24
  },
  {
    "recipeId": 143,
    "categoryId": 10
  },
  {
    "recipeId": 143,
    "categoryId": 11
  },
  {
    "recipeId": 144,
    "categoryId": 10
  },
  {
    "recipeId": 144,
    "categoryId": 24
  },
  {
    "recipeId": 144,
    "categoryId": 17
  },
  {
    "recipeId": 144,
    "categoryId": 21
  },
  {
    "recipeId": 145,
    "categoryId": 11
  },
  {
    "recipeId": 146,
    "categoryId": 11
  },
  {
    "recipeId": 146,
    "categoryId": 15
  },
  {
    "recipeId": 147,
    "categoryId": 11
  },
  {
    "recipeId": 149,
    "categoryId": 11
  },
  {
    "recipeId": 151,
    "categoryId": 11
  },
  {
    "recipeId": 151,
    "categoryId": 21
  },
  {
    "recipeId": 153,
    "categoryId": 11
  },
  {
    "recipeId": 158,
    "categoryId": 11
  },
  {
    "recipeId": 159,
    "categoryId": 11
  },
  {
    "recipeId": 161,
    "categoryId": 11
  },
  {
    "recipeId": 164,
    "categoryId": 11
  },
  {
    "recipeId": 164,
    "categoryId": 16
  },
  {
    "recipeId": 165,
    "categoryId": 11
  },
  {
    "recipeId": 166,
    "categoryId": 11
  },
  {
    "recipeId": 167,
    "categoryId": 11
  },
  {
    "recipeId": 168,
    "categoryId": 11
  },
  {
    "recipeId": 169,
    "categoryId": 11
  },
  {
    "recipeId": 169,
    "categoryId": 21
  },
  {
    "recipeId": 170,
    "categoryId": 12
  },
  {
    "recipeId": 170,
    "categoryId": 21
  },
  {
    "recipeId": 171,
    "categoryId": 13
  },
  {
    "recipeId": 171,
    "categoryId": 18
  },
  {
    "recipeId": 171,
    "categoryId": 21
  },
  {
    "recipeId": 173,
    "categoryId": 13
  },
  {
    "recipeId": 174,
    "categoryId": 13
  },
  {
    "recipeId": 174,
    "categoryId": 21
  },
  {
    "recipeId": 175,
    "categoryId": 13
  },
  {
    "recipeId": 175,
    "categoryId": 17
  },
  {
    "recipeId": 175,
    "categoryId": 21
  },
  {
    "recipeId": 176,
    "categoryId": 13
  },
  {
    "recipeId": 176,
    "categoryId": 21
  },
  {
    "recipeId": 177,
    "categoryId": 15
  },
  {
    "recipeId": 178,
    "categoryId": 15
  },
  {
    "recipeId": 178,
    "categoryId": 21
  },
  {
    "recipeId": 180,
    "categoryId": 15
  },
  {
    "recipeId": 181,
    "categoryId": 15
  },
  {
    "recipeId": 181,
    "categoryId": 16
  },
  {
    "recipeId": 182,
    "categoryId": 15
  },
  {
    "recipeId": 183,
    "categoryId": 15
  },
  {
    "recipeId": 184,
    "categoryId": 15
  },
  {
    "recipeId": 184,
    "categoryId": 21
  },
  {
    "recipeId": 185,
    "categoryId": 15
  },
  {
    "recipeId": 186,
    "categoryId": 15
  },
  {
    "recipeId": 187,
    "categoryId": 15
  },
  {
    "recipeId": 187,
    "categoryId": 21
  },
  {
    "recipeId": 188,
    "categoryId": 15
  },
  {
    "recipeId": 189,
    "categoryId": 26
  },
  {
    "recipeId": 190,
    "categoryId": 26
  },
  {
    "recipeId": 190,
    "categoryId": 21
  },
  {
    "recipeId": 191,
    "categoryId": 26
  },
  {
    "recipeId": 192,
    "categoryId": 26
  },
  {
    "recipeId": 193,
    "categoryId": 26
  },
  {
    "recipeId": 194,
    "categoryId": 26
  },
  {
    "recipeId": 195,
    "categoryId": 26
  },
  {
    "recipeId": 197,
    "categoryId": 16
  },
  {
    "recipeId": 197,
    "categoryId": 18
  },
  {
    "recipeId": 198,
    "categoryId": 16
  },
  {
    "recipeId": 199,
    "categoryId": 16
  },
  {
    "recipeId": 200,
    "categoryId": 16
  },
  {
    "recipeId": 201,
    "categoryId": 16
  },
  {
    "recipeId": 201,
    "categoryId": 18
  },
  {
    "recipeId": 201,
    "categoryId": 21
  },
  {
    "recipeId": 203,
    "categoryId": 16
  },
  {
    "recipeId": 203,
    "categoryId": 21
  },
  {
    "recipeId": 204,
    "categoryId": 16
  },
  {
    "recipeId": 205,
    "categoryId": 16
  },
  {
    "recipeId": 205,
    "categoryId": 18
  },
  {
    "recipeId": 205,
    "categoryId": 21
  },
  {
    "recipeId": 206,
    "categoryId": 24
  },
  {
    "recipeId": 207,
    "categoryId": 24
  },
  {
    "recipeId": 207,
    "categoryId": 17
  },
  {
    "recipeId": 208,
    "categoryId": 17
  },
  {
    "recipeId": 210,
    "categoryId": 17
  },
  {
    "recipeId": 213,
    "categoryId": 9
  },
  {
    "recipeId": 213,
    "categoryId": 17
  },
  {
    "recipeId": 214,
    "categoryId": 17
  },
  {
    "recipeId": 215,
    "categoryId": 17
  },
  {
    "recipeId": 216,
    "categoryId": 17
  },
  {
    "recipeId": 218,
    "categoryId": 18
  },
  {
    "recipeId": 219,
    "categoryId": 18
  },
  {
    "recipeId": 220,
    "categoryId": 18
  },
  {
    "recipeId": 221,
    "categoryId": 18
  },
  {
    "recipeId": 222,
    "categoryId": 18
  },
  {
    "recipeId": 223,
    "categoryId": 18
  },
  {
    "recipeId": 225,
    "categoryId": 18
  },
  {
    "recipeId": 225,
    "categoryId": 21
  },
  {
    "recipeId": 226,
    "categoryId": 18
  },
  {
    "recipeId": 226,
    "categoryId": 21
  },
  {
    "recipeId": 227,
    "categoryId": 18
  },
  {
    "recipeId": 227,
    "categoryId": 21
  },
  {
    "recipeId": 228,
    "categoryId": 18
  },
  {
    "recipeId": 229,
    "categoryId": 18
  },
  {
    "recipeId": 230,
    "categoryId": 18
  },
  {
    "recipeId": 231,
    "categoryId": 18
  },
  {
    "recipeId": 232,
    "categoryId": 18
  },
  {
    "recipeId": 233,
    "categoryId": 18
  },
  {
    "recipeId": 234,
    "categoryId": 18
  },
  {
    "recipeId": 234,
    "categoryId": 21
  },
  {
    "recipeId": 235,
    "categoryId": 18
  },
  {
    "recipeId": 236,
    "categoryId": 18
  },
  {
    "recipeId": 236,
    "categoryId": 21
  },
  {
    "recipeId": 237,
    "categoryId": 18
  },
  {
    "recipeId": 237,
    "categoryId": 21
  },
  {
    "recipeId": 238,
    "categoryId": 18
  },
  {
    "recipeId": 238,
    "categoryId": 21
  },
  {
    "recipeId": 240,
    "categoryId": 18
  },
  {
    "recipeId": 241,
    "categoryId": 18
  },
  {
    "recipeId": 242,
    "categoryId": 18
  },
  {
    "recipeId": 243,
    "categoryId": 18
  },
  {
    "recipeId": 245,
    "categoryId": 18
  },
  {
    "recipeId": 246,
    "categoryId": 19
  },
  {
    "recipeId": 246,
    "categoryId": 18
  },
  {
    "recipeId": 247,
    "categoryId": 18
  },
  {
    "recipeId": 247,
    "categoryId": 21
  },
  {
    "recipeId": 248,
    "categoryId": 18
  },
  {
    "recipeId": 249,
    "categoryId": 18
  },
  {
    "recipeId": 249,
    "categoryId": 21
  },
  {
    "recipeId": 250,
    "categoryId": 18
  },
  {
    "recipeId": 250,
    "categoryId": 21
  },
  {
    "recipeId": 251,
    "categoryId": 18
  },
  {
    "recipeId": 252,
    "categoryId": 21
  },
  {
    "recipeId": 253,
    "categoryId": 21
  },
  {
    "recipeId": 254,
    "categoryId": 21
  },
  {
    "recipeId": 255,
    "categoryId": 21
  },
  {
    "recipeId": 256,
    "categoryId": 21
  },
  {
    "recipeId": 257,
    "categoryId": 21
  },
  {
    "recipeId": 258,
    "categoryId": 4
  },
  {
    "recipeId": 258,
    "categoryId": 8
  },
  {
    "recipeId": 258,
    "categoryId": 20
  },
  {
    "recipeId": 258,
    "categoryId": 21
  },
  {
    "recipeId": 259,
    "categoryId": 21
  },
  {
    "recipeId": 260,
    "categoryId": 1
  },
  {
    "recipeId": 260,
    "categoryId": 18
  },
  {
    "recipeId": 260,
    "categoryId": 21
  },
  {
    "recipeId": 261,
    "categoryId": 21
  },
  {
    "recipeId": 262,
    "categoryId": 1
  },
  {
    "recipeId": 262,
    "categoryId": 20
  },
  {
    "recipeId": 263,
    "categoryId": 1
  },
  {
    "recipeId": 264,
    "categoryId": 1
  },
  {
    "recipeId": 131,
    "categoryId": 2
  },
  {
    "recipeId": 131,
    "categoryId": 23
  },
  {
    "recipeId": 17,
    "categoryId": 1
  },
  {
    "recipeId": 17,
    "categoryId": 2
  },
  {
    "recipeId": 17,
    "categoryId": 19
  },
  {
    "recipeId": 18,
    "categoryId": 1
  },
  {
    "recipeId": 18,
    "categoryId": 2
  },
  {
    "recipeId": 18,
    "categoryId": 19
  },
  {
    "recipeId": 18,
    "categoryId": 23
  },
  {
    "recipeId": 265,
    "categoryId": 7
  },
  {
    "recipeId": 267,
    "categoryId": 11
  },
  {
    "recipeId": 268,
    "categoryId": 11
  },
  {
    "recipeId": 268,
    "categoryId": 15
  },
  {
    "recipeId": 269,
    "categoryId": 22
  },
  {
    "recipeId": 272,
    "categoryId": 17
  },
  {
    "recipeId": 8,
    "categoryId": 1
  },
  {
    "recipeId": 8,
    "categoryId": 19
  },
  {
    "recipeId": 11,
    "categoryId": 1
  },
  {
    "recipeId": 11,
    "categoryId": 3
  },
  {
    "recipeId": 11,
    "categoryId": 19
  },
  {
    "recipeId": 22,
    "categoryId": 1
  },
  {
    "recipeId": 22,
    "categoryId": 19
  },
  {
    "recipeId": 104,
    "categoryId": 7
  },
  {
    "recipeId": 104,
    "categoryId": 8
  },
  {
    "recipeId": 115,
    "categoryId": 7
  },
  {
    "recipeId": 115,
    "categoryId": 8
  },
  {
    "recipeId": 138,
    "categoryId": 20
  },
  {
    "recipeId": 138,
    "categoryId": 15
  },
  {
    "recipeId": 138,
    "categoryId": 16
  },
  {
    "recipeId": 138,
    "categoryId": 17
  },
  {
    "recipeId": 150,
    "categoryId": 11
  },
  {
    "recipeId": 150,
    "categoryId": 13
  },
  {
    "recipeId": 150,
    "categoryId": 21
  },
  {
    "recipeId": 152,
    "categoryId": 11
  },
  {
    "recipeId": 156,
    "categoryId": 11
  },
  {
    "recipeId": 156,
    "categoryId": 21
  },
  {
    "recipeId": 157,
    "categoryId": 11
  },
  {
    "recipeId": 172,
    "categoryId": 13
  },
  {
    "recipeId": 172,
    "categoryId": 17
  },
  {
    "recipeId": 172,
    "categoryId": 21
  },
  {
    "recipeId": 179,
    "categoryId": 15
  },
  {
    "recipeId": 179,
    "categoryId": 21
  },
  {
    "recipeId": 202,
    "categoryId": 16
  },
  {
    "recipeId": 202,
    "categoryId": 21
  },
  {
    "recipeId": 209,
    "categoryId": 17
  },
  {
    "recipeId": 209,
    "categoryId": 21
  },
  {
    "recipeId": 212,
    "categoryId": 17
  },
  {
    "recipeId": 217,
    "categoryId": 17
  },
  {
    "recipeId": 217,
    "categoryId": 21
  },
  {
    "recipeId": 9,
    "categoryId": 1
  },
  {
    "recipeId": 23,
    "categoryId": 1
  },
  {
    "recipeId": 23,
    "categoryId": 20
  },
  {
    "recipeId": 31,
    "categoryId": 1
  },
  {
    "recipeId": 31,
    "categoryId": 18
  },
  {
    "recipeId": 59,
    "categoryId": 19
  },
  {
    "recipeId": 59,
    "categoryId": 8
  },
  {
    "recipeId": 59,
    "categoryId": 20
  },
  {
    "recipeId": 59,
    "categoryId": 16
  },
  {
    "recipeId": 72,
    "categoryId": 19
  },
  {
    "recipeId": 72,
    "categoryId": 18
  },
  {
    "recipeId": 96,
    "categoryId": 5
  },
  {
    "recipeId": 96,
    "categoryId": 7
  },
  {
    "recipeId": 96,
    "categoryId": 8
  },
  {
    "recipeId": 117,
    "categoryId": 7
  },
  {
    "recipeId": 119,
    "categoryId": 8
  },
  {
    "recipeId": 119,
    "categoryId": 20
  },
  {
    "recipeId": 148,
    "categoryId": 11
  },
  {
    "recipeId": 154,
    "categoryId": 11
  },
  {
    "recipeId": 155,
    "categoryId": 11
  },
  {
    "recipeId": 160,
    "categoryId": 11
  },
  {
    "recipeId": 162,
    "categoryId": 11
  },
  {
    "recipeId": 163,
    "categoryId": 11
  },
  {
    "recipeId": 196,
    "categoryId": 16
  },
  {
    "recipeId": 196,
    "categoryId": 18
  },
  {
    "recipeId": 196,
    "categoryId": 21
  },
  {
    "recipeId": 211,
    "categoryId": 17
  },
  {
    "recipeId": 239,
    "categoryId": 18
  },
  {
    "recipeId": 244,
    "categoryId": 18
  },
  {
    "recipeId": 266,
    "categoryId": 7
  },
  {
    "recipeId": 266,
    "categoryId": 8
  },
  {
    "recipeId": 270,
    "categoryId": 15
  },
  {
    "recipeId": 43,
    "categoryId": 3
  },
  {
    "recipeId": 43,
    "categoryId": 4
  },
  {
    "recipeId": 43,
    "categoryId": 8
  },
  {
    "recipeId": 80,
    "categoryId": 19
  },
  {
    "recipeId": 80,
    "categoryId": 20
  },
  {
    "recipeId": 80,
    "categoryId": 18
  },
  {
    "recipeId": 274,
    "categoryId": 18
  },
  {
    "recipeId": 275,
    "categoryId": 19
  },
  {
    "recipeId": 275,
    "categoryId": 4
  },
  {
    "recipeId": 275,
    "categoryId": 8
  },
  {
    "recipeId": 276,
    "categoryId": 19
  },
  {
    "recipeId": 276,
    "categoryId": 11
  },
  {
    "recipeId": 277,
    "categoryId": 7
  },
  {
    "recipeId": 277,
    "categoryId": 8
  },
  {
    "recipeId": 224,
    "categoryId": 16
  },
  {
    "recipeId": 224,
    "categoryId": 18
  },
  {
    "recipeId": 279,
    "categoryId": 7
  },
  {
    "recipeId": 279,
    "categoryId": 8
  },
  {
    "recipeId": 280,
    "categoryId": 7
  },
  {
    "recipeId": 280,
    "categoryId": 8
  },
  {
    "recipeId": 281,
    "categoryId": 29
  },
  {
    "recipeId": 282,
    "categoryId": 29
  },
  {
    "recipeId": 283,
    "categoryId": 5
  },
  {
    "recipeId": 283,
    "categoryId": 7
  },
  {
    "recipeId": 283,
    "categoryId": 8
  },
  {
    "recipeId": 284,
    "categoryId": 1
  },
  {
    "recipeId": 284,
    "categoryId": 11
  },
  {
    "recipeId": 285,
    "categoryId": 19
  },
  {
    "recipeId": 285,
    "categoryId": 4
  },
  {
    "recipeId": 285,
    "categoryId": 7
  },
  {
    "recipeId": 285,
    "categoryId": 8
  },
  {
    "recipeId": 285,
    "categoryId": 12
  },
  {
    "recipeId": 286,
    "categoryId": 1
  },
  {
    "recipeId": 287,
    "categoryId": 10
  }
]

export default RECIPE_CATEGORIES;