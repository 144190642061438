const RECIPES = [
  {
    "id": 3,
    "timestamp": 2454959.26100694,
    "title": "Antipasto on a Stick",
    "source": "Bonnie",
    "time": "",
    "directions": "Thread on skewer.  Place in pan.  Top with Italian dressing.%0D%0D%0ARefrigerate overnight.",
    "equipment": "",
    "prepTime": "",
    "servingIdeas": "",
    "VARCHAR": "",
    "suggestedWine": "",
    "notes": "",
    "servings": "1"
  },
  {
    "id": 4,
    "timestamp": 2454959.26194444,
    "title": "Apple and Cheddar Sandwiches",
    "source": "Family Circle Christmas Helps",
    "time": "",
    "directions": "Toss apple slices with lemon juice%3B  set aside.%0D%0D%0ACut each slice of date-nut bread in half.  Top each piece of bread with a slice of cheese, a slice of apple and a dollop of apple chutney.%0D%0D%0A%0D%0D%0AApple Chutney :  Buy chunky cinnamon applesauce and mix with raisins.",
    "equipment": "",
    "prepTime": "",
    "servingIdeas": "",
    "VARCHAR": "",
    "suggestedWine": "",
    "notes": "",
    "servings": "24"
  },
  {
    "id": 5,
    "timestamp": 2454959.26253472,
    "title": "Apple-Nut Fruit Dip",
    "source": "Bonnie",
    "time": "",
    "directions": "Beat cream cheese and apple juice until smooth.%0D%0D%0ACover and chill.%0D%0D%0AAt serving time sprinkle with pecans.%0D%0D%0AServe with apple wedges, pear wedges, pineapple spears, strawberries",
    "equipment": "",
    "prepTime": "",
    "servingIdeas": "",
    "VARCHAR": "",
    "suggestedWine": "",
    "notes": "",
    "servings": "1"
  },
  {
    "id": 6,
    "timestamp": 2454959.2640625,
    "title": "Artichoke Appetizer Squares",
    "source": "Pillsbury",
    "time": "",
    "directions": "Unroll dough into long rectangle in ungreased 9x13%22 pan.  Seal perforations.  Press over bottom and 1/2%22 up sides to form crust.%0D%0D%0ASprinkle with parmesan cheese.  Bake at 375 for 5 minutes.%0D%0D%0AIn small bowl beat cream cheese until smooth.%0D%0D%0AAdd sour cream, dill, salt and egg%3B  Blend well.%0D%0D%0ASpread over partially baked crust.%0D%0D%0ATop with remaining ingredients.%0D%0D%0AReturn to oven and bake and additional 13 - 19 minutes or until edges are deep golden brown and center is set.  %0D%0D%0ATo serve cut into squares.  Serve warm or cold.  Store in refrigerator.",
    "equipment": "",
    "prepTime": "",
    "servingIdeas": "",
    "VARCHAR": "",
    "suggestedWine": "",
    "notes": "",
    "servings": "24"
  },
  {
    "id": 7,
    "timestamp": 2454959.2647338,
    "title": "Artichoke Dip",
    "source": "Bonnie",
    "time": "",
    "directions": "Mix all ingredients.%0D%0D%0ABake at 350 for 25 -30 minutes.%0D%0D%0A%0D%0D%0ADo not use microwave.",
    "equipment": "",
    "prepTime": "",
    "servingIdeas": "",
    "VARCHAR": "",
    "suggestedWine": "",
    "notes": "",
    "servings": "1"
  },
  {
    "id": 8,
    "timestamp": 2454959.26568287,
    "title": "Baked Brie",
    "source": "BH G Holiday Cooking",
    "time": "",
    "directions": "With serrated knife, cut the Brie horizontally in half.  Sprinkle the bottom half with chopped parsley and tomatoes.  Replace top half of Brie%3B  set aside.%0D%0D%0AGrease a cookie sheet.%0D%0D%0ABrush 1 sheet of phyllo with butter.  Place Brie in center of phyllo.  Wrap Brie with phyllo, pressing phyllo against Brie.  Brush another sheet of phyllo with butter.  Turn Brie over and place in center of sheet.  Wrap as with first sheet.  Repeat with 2 more sheets of phyllo, turning Brie over each time.%0D%0D%0APlace remaining 4 sheets of phyllo on work surface, overlapping edges to measure 24%22 x 22%22.  Brush with butter.  Place Brie in center of phyllo.  Bring side of phyllo up over Brie to cover completely, bunching edges together to form a ruffled bow on top of packet%3B  brush with remaining butter.  Secure ruffle with toothpicks, if necessary.%0D%0D%0ABake at 375 degrees for 30 - 35 minutes.  Cover with foil during last 15 minutes.%0D%0D%0APlace baked Brie in refrigerator about 1 hour or until Brie is room temperature.  Or, if made early in day, keep Brie refrigerated and remove 2 hours before serving and let stand at room temperature.%0D%0D%0ATo serve, arrange Brie, crackers, grapes, and dried fruit on large platter.",
    "equipment": "",
    "prepTime": "",
    "servingIdeas": "",
    "VARCHAR": "",
    "suggestedWine": "",
    "notes": "",
    "servings": "24"
  },
  {
    "id": 9,
    "timestamp": 2454959.26612269,
    "title": "Baked Goat Cheese and Salsa",
    "source": "Bonnie",
    "time": "",
    "directions": "Combine cheeses and mix well.%0D%0D%0APlace in center of pie plate.%0D%0D%0ASpoon salsa around and on top of cheese.%0D%0D%0ABake at 350 degrees for 20 minutes.%0D%0D%0AServe with crackers, bread or tortilla chips.",
    "equipment": "",
    "prepTime": "",
    "servingIdeas": "",
    "VARCHAR": "",
    "suggestedWine": "",
    "notes": "",
    "servings": "1"
  },
  {
    "id": 10,
    "timestamp": 2454959.26674769,
    "title": "Brie en Croute",
    "source": "Debby",
    "time": "",
    "directions": "Place wheel of brie in center of puff pastry.%0D%0D%0ACover brie with apricot preserves and pecans.%0D%0D%0AGather pastry on top of cheese and tie with cotton twine.%0D%0D%0APlace on lightly greased cookie sheet and refrigerate for 1 hour.%0D%0D%0ABrush dough with egg yolk.%0D%0D%0ABake at 400 degrees for 25-30 minutes.",
    "equipment": "",
    "prepTime": "",
    "servingIdeas": "",
    "VARCHAR": "",
    "suggestedWine": "",
    "notes": "",
    "servings": "6"
  },
  {
    "id": 11,
    "timestamp": 2454959.26856481,
    "title": "Bruschetta",
    "source": "BH G Holiday Cooking",
    "time": "",
    "directions": "For olive paste :  Combine olives, vinegar, garlic, capers, and 1 tsp. olive oil in food processor bowl.  Cover and blend till smooth paste forms.  (Can be kept in airtight container and chilled for 2 days.%0D%0D%0A%0D%0D%0AFor tomato topping :  Stir together tomatoes, green onion, 1 tbs. olive oil, basil, and pepper.  (Can be kept in refrigerator for 2 days.)%0D%0D%0A%0D%0D%0AFor toast :  Cut bread into 1/2 inch slices.  Brush both sides with olive oil.  Place slices in a single layer on an ungreased baking sheet.%0D%0D%0A%0D%0D%0ABake slices in a 425 degree oven for 5 minutes or till crisp and lightly browned, turning once.%0D%0D%0A%0D%0D%0ATo assemble :  Spread each piece of toast with a thin layer of olive paste.  Top each with about 2 tbs. of tomato topping.  Sprinkle with cheese.  Return to ungreased cookie sheet.%0D%0D%0A%0D%0D%0ABake in a 425 degree oven for 2 - 3 minutes or till cheese starts to melt and toppings are heated through.  Serve warm.",
    "equipment": "",
    "prepTime": "",
    "servingIdeas": "",
    "VARCHAR": "",
    "suggestedWine": "",
    "notes": "",
    "servings": "24"
  },
  {
    "id": 12,
    "timestamp": 2454959.32664352,
    "title": "Buffalo Wings",
    "source": "Bonnie",
    "time": "",
    "directions": "Melt butter with Louisiana hot sauce.%0D%0D%0AFry chicken wings for 15 minutes.%0D%0D%0ADip wings in hot sauce.%0D%0D%0A%0D%0D%0AIn blender, mix bleu cheese, heavy cream, sour cream and vinegar.",
    "equipment": "",
    "prepTime": "0 :03",
    "servingIdeas": "",
    "VARCHAR": "",
    "suggestedWine": "",
    "notes": "",
    "servings": "1"
  },
  {
    "id": 13,
    "timestamp": 2454963.25403935,
    "title": "Chex Party Mix",
    "source": "Bonnie",
    "time": "",
    "directions": "Melt butter.  Stir garlic salt and worcestershire sauce into butter.%0D%0D%0AMix cereals, pretzels and nuts and coat with sauce.%0D%0D%0AMicrowave on high for 6 minutes.  Stir every 2 minutes.%0D%0D%0A%0D%0D%0A(Can be baked at 350 for 20 - 25 minutes.)",
    "equipment": "",
    "prepTime": "",
    "servingIdeas": "",
    "VARCHAR": "",
    "suggestedWine": "",
    "notes": "",
    "servings": "1"
  },
  {
    "id": 14,
    "timestamp": 2454963.25489583,
    "title": "Egg Nog Dip",
    "source": "Bonnie",
    "time": "",
    "directions": "Cook eggnog and cornstarch until thick and bubbly.%0D%0D%0ACook and stir 2 minutes more.%0D%0D%0ARemove from heat, cover surface with plastic wrap, and cool.%0D%0D%0ABeat heavy cream and sugar. %0D%0D%0AFold heavy cream, sour cream and rum into eggnog.%0D%0D%0AChill, covered, overnight.%0D%0D%0AServe with fruit or pound cake. ",
    "equipment": "",
    "prepTime": "",
    "servingIdeas": "",
    "VARCHAR": "",
    "suggestedWine": "",
    "notes": "",
    "servings": "1"
  },
  {
    "id": 15,
    "timestamp": 2454963.25587963,
    "title": "Garlic Crab Artichoke Dip",
    "source": "Bonnie",
    "time": "",
    "directions": "Combine all ingredients in a 1 1/2 qt. casserole dish.%0D%0D%0AMix well.%0D%0D%0ABake, uncovered, at 350 degrees for 30 minutes.%0D%0D%0AServe with crackers or bagel chips.",
    "equipment": "",
    "prepTime": "",
    "servingIdeas": "",
    "VARCHAR": "",
    "suggestedWine": "",
    "notes": "",
    "servings": "8"
  },
  {
    "id": 16,
    "timestamp": 2454963.25646991,
    "title": "Golden Apple Half-Moons",
    "source": "Bonnie",
    "time": "",
    "directions": "Cut each apple into 24 slices with peel.%0D%0D%0AToss with lemon juice.%0D%0D%0ACut ham into 1-2%22 strips.%0D%0D%0ACut chives into 3%22 lengths.%0D%0D%0A%0D%0D%0APrepare honey-mustard mayo :  Mix 2/3 cup mayo,  2 tsp.honey mustard and 4 tsp. minced scallions.%0D%0D%0A%0D%0D%0ATo assemble : Spread mayo on one side of ham strip.  Place 2 chive pieces on top of each apple slice and wrap a ham strip around middle of each slice.  Cover and refrigerate. May be made 2-3 hours ahead. ",
    "equipment": "",
    "prepTime": "",
    "servingIdeas": "",
    "VARCHAR": "",
    "suggestedWine": "",
    "notes": "",
    "servings": "72"
  },
  {
    "id": 17,
    "timestamp": 2454963.2591088,
    "title": "Ham-It-Up Crescent Snacks",
    "source": "Pillsbury",
    "time": "",
    "directions": "Unroll dough into 4 large rectangles.%0D%0D%0APress and seal perforations.%0D%0D%0APlace a slice of ham on each rectangle.%0D%0D%0ASpread with mustard.%0D%0D%0ASprinkle with cheese.%0D%0D%0ARoll up from short end.%0D%0D%0ACoat rolls with sesame seeds.%0D%0D%0ACut each roll into 5 slices.%0D%0D%0APlace on ungreased cookie sheet.%0D%0D%0ABake at 375 for 15 - 20 minutes.",
    "equipment": "",
    "prepTime": "",
    "servingIdeas": "",
    "VARCHAR": "",
    "suggestedWine": "",
    "notes": "",
    "servings": "20"
  },
  {
    "id": 18,
    "timestamp": 2454963.25952546,
    "title": "Herb Cheese Crescents",
    "source": "Pillsbury",
    "time": "",
    "directions": "Unroll dough into 8 triangles.%0D%0D%0ACut each triangle in half lengthwise.%0D%0D%0ASpread 1 tsp. of cheese of each triangle.%0D%0D%0ARoll up from wide end.%0D%0D%0APlace on ungreased cookie sheet.%0D%0D%0ABake at 375 for 10 - 12 minutes.%0D%0D%0A%0D%0D%0A",
    "equipment": "",
    "prepTime": "",
    "servingIdeas": "",
    "VARCHAR": "",
    "suggestedWine": "",
    "notes": "",
    "servings": "1"
  },
  {
    "id": 19,
    "timestamp": 2454963.26019676,
    "title": "Hidden Valley Cheese Fingers",
    "source": "Hidden Valley",
    "time": "",
    "directions": "Cut bread lengthwise.%0D%0D%0AMix cream cheese and salad dressing.%0D%0D%0ASpread on bread.%0D%0D%0AAdd toppings.%0D%0D%0ABroil until bubbly.%0D%0D%0ASlice in 1 inch fingers.%0D%0D%0A",
    "equipment": "",
    "prepTime": "",
    "servingIdeas": "",
    "VARCHAR": "",
    "suggestedWine": "",
    "notes": "",
    "servings": "1"
  },
  {
    "id": 20,
    "timestamp": 2454963.26113426,
    "title": "Italian Easter Pie",
    "source": "Bonnie",
    "time": "",
    "directions": "Stir sugar and cinnamon into ricotta.%0D%0D%0ABlend in 3 eggs.%0D%0D%0ADivide filling into 2 pie shells.%0D%0D%0ATop each with sliced egg and pepperoni.%0D%0D%0ATop with pie crust.%0D%0D%0ACover edges of pie with aluminum foil.%0D%0D%0ABake at 350 degrees for 30 minutes.%0D%0D%0ARemove aluminum foil and bake 15 minutes more.%0D%0D%0A%0D%0D%0AChill and serve cool.",
    "equipment": "",
    "prepTime": "",
    "servingIdeas": "",
    "VARCHAR": "",
    "suggestedWine": "",
    "notes": "",
    "servings": "1"
  },
  {
    "id": 21,
    "timestamp": 2454963.26195602,
    "title": "Mini Reubens",
    "source": "Bonnie",
    "time": "",
    "directions": "Mix cheese, corned beef, dressing and sauerkraut.  %0D%0D%0ASpread over each slice of bread.%0D%0D%0APlace on ungreased cookie sheet and broil for 2 - 3 minutes or until golden.  %0D%0D%0ACut each pickle in half lengthwise.%0D%0D%0ATop with 2 pickle halves.",
    "equipment": "",
    "prepTime": "",
    "servingIdeas": "",
    "VARCHAR": "",
    "suggestedWine": "",
    "notes": "",
    "servings": "1"
  },
  {
    "id": 22,
    "timestamp": 2454963.26298611,
    "title": "Mozzarella and Ham Kabobs",
    "source": "BH G Holiday Cooking",
    "time": "",
    "directions": "Mix together olive oil, salt, pepper, oregano, basil, and crushed red pepper.  Add mozzarella, tossing to coat well.  Cover and refrigerate several hours or overnight.%0D%0D%0ACut roasted peppers in 1%22 pieces.  On tooth pick, thread 1 piece of pepper, 1 piece of mozzarella, and a piece of prosciutto.%0D%0D%0ACover and refrigerate if not serving right away.%0D%0D%0A",
    "equipment": "",
    "prepTime": "",
    "servingIdeas": "",
    "VARCHAR": "",
    "suggestedWine": "",
    "notes": "",
    "servings": "2"
  },
  {
    "id": 23,
    "timestamp": 2454963.26402778,
    "title": "Peaches and Brie Quesadillas",
    "source": "Bonnie",
    "time": "",
    "directions": "Combine honey and lime juice.%0D%0D%0A%0D%0D%0ACombine peaches, chives and brown sugar.%0D%0D%0AArrange brie on half of tortilla.  Top with peaches.  Fold tortilla in half.%0D%0D%0AHeat frying pan over medium-high heat.  Coat with Pam.%0D%0D%0ABrown tortilla and cut into pieces.%0D%0D%0A%0D%0D%0AServe with sauce.",
    "equipment": "",
    "prepTime": "",
    "servingIdeas": "",
    "VARCHAR": "",
    "suggestedWine": "",
    "notes": "",
    "servings": "1"
  },
  {
    "id": 24,
    "timestamp": 2454963.27516204,
    "title": "Pickle Roll-Ups",
    "source": "Bonnie",
    "time": "",
    "directions": "Spread 1 side of ham with cream cheese.%0D%0D%0APlace pickle on ham and roll up.%0D%0D%0ACover and refrigerate 1 hour.%0D%0D%0ACut into slices.%0D%0D%0A",
    "equipment": "",
    "prepTime": "",
    "servingIdeas": "",
    "VARCHAR": "",
    "suggestedWine": "",
    "notes": "",
    "servings": "1"
  },
  {
    "id": 25,
    "timestamp": 2454963.27637731,
    "title": "Reuben Dip",
    "source": "Bonnie",
    "time": "",
    "directions": "Mix all ingredients except rye bread.%0D%0D%0A%0D%0D%0ABake at 350 degrees for 20 minutes.%0D%0D%0A%0D%0D%0AServe with rye bread.",
    "equipment": "",
    "prepTime": "",
    "servingIdeas": "",
    "VARCHAR": "",
    "suggestedWine": "",
    "notes": "",
    "servings": "1"
  },
  {
    "id": 26,
    "timestamp": 2454963.27747685,
    "title": "Reuben Potato Skins",
    "source": "Bonnie",
    "time": "",
    "directions": "Bake potatoes at 425 for 40 minutes.%0D%0D%0ACut in half and scoop out potato leaving 3/8 in. shell.%0D%0D%0ACut each potato in half lengthwise.%0D%0D%0ABrush inside and outside of potato with butter.%0D%0D%0APlace cut side up and bake at 425 for 15 minutes.%0D%0D%0AStir remaining ingredients together.%0D%0D%0APlace 1 tbs. of mixture on each potato and bake 5 minutes more.",
    "equipment": "",
    "prepTime": "",
    "servingIdeas": "",
    "VARCHAR": "",
    "suggestedWine": "",
    "notes": "",
    "servings": "1"
  },
  {
    "id": 27,
    "timestamp": 2454963.27822917,
    "title": "Ricotta Puffs",
    "source": "Bonnie",
    "time": "",
    "directions": "Mix first 5 ingredients in a large bowl.%0D%0D%0AForm into small balls.%0D%0D%0ARoll in breadcrumbs mixed with 3 tbs. of Parmesan cheese.%0D%0D%0AHeat oil.  When hot, drop in the ricotta balls.%0D%0D%0ACook for 3 minutes and drain on paper towel.%0D%0D%0APlace on platter and serve with sauce.",
    "equipment": "",
    "prepTime": "",
    "servingIdeas": "",
    "VARCHAR": "",
    "suggestedWine": "",
    "notes": "",
    "servings": "8"
  },
  {
    "id": 28,
    "timestamp": 2454963.27885417,
    "title": "Shrimp Bundles",
    "source": "Bonnie",
    "time": "",
    "directions": "Place scallion tops in boiling minute for 1 minute.%0D%0D%0ARemove and cool.  Cut in thin strips 8%22 long.%0D%0D%0ALay 1 shrimp over mushroom cap - stem side down.%0D%0D%0AWrap scallion around mushroom/shrimp.%0D%0D%0AMix mustard, wine and worcestershire sauce together.%0D%0D%0ABrush bottom  and top with mixture.%0D%0D%0ACover and refrigerate 2 hours.%0D%0D%0ABroil 4%22 from heat for 4 -5 minutes.%0D%0D%0A",
    "equipment": "",
    "prepTime": "",
    "servingIdeas": "",
    "VARCHAR": "",
    "suggestedWine": "",
    "notes": "",
    "servings": "1"
  },
  {
    "id": 29,
    "timestamp": 2454964.12050926,
    "title": "Smoked Salmon Pinwheels",
    "source": "Bonnie",
    "time": "",
    "directions": "Spread cream cheese over tortilla. (Save a small amount for the end.)%0D%0D%0APlace spinach leaves and pepper strips on cream cheese.%0D%0D%0ARoll up tortilla tightly%3B  spread with additional cream cheese to seal roll.  %0D%0D%0AWrap securely with plastic wrap and refrigerate at least 2 hours.%0D%0D%0ATo serve, cut into 1 inch pieces.",
    "equipment": "",
    "prepTime": "",
    "servingIdeas": "",
    "VARCHAR": "",
    "suggestedWine": "",
    "notes": "",
    "servings": "1"
  },
  {
    "id": 30,
    "timestamp": 2454964.12991898,
    "title": "Spinach Dip",
    "source": "Bonnie",
    "time": "",
    "directions": "Mix all ingredients.%0D%0D%0ARefrigerate at least 2 hours.%0D%0D%0A",
    "equipment": "",
    "prepTime": "0 :15",
    "servingIdeas": "",
    "VARCHAR": "",
    "suggestedWine": "",
    "notes": "",
    "servings": "1"
  },
  {
    "id": 31,
    "timestamp": 2454964.13112269,
    "title": "Stuffed Mushrooms",
    "source": "Bonnie",
    "time": "",
    "directions": "Chop stems.  Saute with onion and butter. (5min.)  %0D%0D%0AAdd bread crumbs and cook 2 min.%0D%0D%0AStir in lemon juice, salt and Wor. sauce.%0D%0D%0AFill caps.%0D%0D%0ABake at 400 for 15 minutes.%0D%0D%0ATop with cheese and bake 5 minutes more.l",
    "equipment": "",
    "prepTime": "0 :20",
    "servingIdeas": "",
    "VARCHAR": "",
    "suggestedWine": "",
    "notes": "",
    "servings": "8"
  },
  {
    "id": 32,
    "timestamp": 2454964.13275463,
    "title": "Stuffed Pepperoncini",
    "source": "Bonnie",
    "time": "",
    "directions": "Cook bacon.  Remove and drain.%0D%0D%0ACook onion and red pepper in oil, about 5 minutes.%0D%0D%0AIn small bowl, with mixer at medium speed, beat cream cheese and milk until smooth.  Crumble bacon.  By hand, mix in bacon, onion and red pepper and mix just until combined.  %0D%0D%0ACut a slit lengthwise in each pepper being careful not to cut all the way through the pepper.  Fill each pepper with cream cheese mixture.  Cover and refrigerate up to 1 day.  Makes about 28 stuffed peppers.%0D%0D%0A",
    "equipment": "",
    "prepTime": "",
    "servingIdeas": "",
    "VARCHAR": "",
    "suggestedWine": "",
    "notes": "",
    "servings": "1"
  },
  {
    "id": 33,
    "timestamp": 2454964.13418981,
    "title": "Taco Dip",
    "source": "Debby",
    "time": "",
    "directions": "Blend sour cream and cream cheese.%0D%0D%0ASpread in casserole dish.%0D%0D%0ASpread taco sauce over cream cheese mixture.%0D%0D%0AAdd a layer of shredded cheese, green pepper, onions and tomatoes.%0D%0D%0ATop with cheddar cheese, black olives, and salsa.%0D%0D%0AServe with tortilla chips.",
    "equipment": "",
    "prepTime": "",
    "servingIdeas": "",
    "VARCHAR": "",
    "suggestedWine": "",
    "notes": "",
    "servings": "1"
  },
  {
    "id": 34,
    "timestamp": 2454964.13615741,
    "title": "Tex-Mex Dip",
    "source": "Bonnie",
    "time": "",
    "directions": "Spread bean dip on platter.%0D%0D%0AMash avocado. (Add water, if necessary.)  Add lemon juice, salt and pepper.%0D%0D%0ASpread over bean dip.%0D%0D%0AMix sour cream, mayonnaise and taco seasoning and spread over avocado.%0D%0D%0ATop with onion, tomato, olives and cheese.",
    "equipment": "",
    "prepTime": "",
    "servingIdeas": "",
    "VARCHAR": "",
    "suggestedWine": "",
    "notes": "",
    "servings": "1"
  },
  {
    "id": 35,
    "timestamp": 2454964.1490162,
    "title": "Tortellini Appetizer",
    "source": "Bonnie",
    "time": "",
    "directions": "Cook and drain tortellini.%0D%0D%0AThread 3 on each skewer and arrange on platter.%0D%0D%0AServe with tomato sauce and ranch cheese sauce.%0D%0D%0A%0D%0D%0ARanch Cheese Sauce :  Mix 1/2 cup of ranch salad dressing with 1/2 cup parmesan cheese. %0D%0D%0A%0D%0D%0AServe at room temperature.%0D%0D%0A",
    "equipment": "",
    "prepTime": "",
    "servingIdeas": "",
    "VARCHAR": "",
    "suggestedWine": "",
    "notes": "",
    "servings": "1"
  },
  {
    "id": 37,
    "timestamp": 2454964.15356481,
    "title": "Waldorf Dip",
    "source": "Bonnie",
    "time": "",
    "directions": "Combine first 5 ingredients and beat until smooth.%0D%0D%0AStir in celery and raisins.  Add more milk, 1 tbs. at a time, until mixture is dipping consistency.%0D%0D%0ACover and chill.  %0D%0D%0AAt serving time sprinkle with nuts.%0D%0D%0AServe with apple and pear wedges.",
    "equipment": "",
    "prepTime": "",
    "servingIdeas": "",
    "VARCHAR": "",
    "suggestedWine": "",
    "notes": "",
    "servings": "1"
  },
  {
    "id": 38,
    "timestamp": 2454964.15449074,
    "title": "Warm Pastrami Dip",
    "source": "Bonnie",
    "time": "",
    "directions": "Combine all ingredients.%0D%0D%0ASpread in 9%22 pie plate.%0D%0D%0ABake at 350 degrees for 20 minutes%0D%0D%0AServe with bagel chips.%0D%0D%0AMakes 4 cups of dip.",
    "equipment": "",
    "prepTime": "",
    "servingIdeas": "",
    "VARCHAR": "",
    "suggestedWine": "",
    "notes": "",
    "servings": "4"
  },
  {
    "id": 39,
    "timestamp": 2454964.15623843,
    "title": "Coconut Egg Nog",
    "source": "Bonnie",
    "time": "",
    "directions": "Beat yolks and cream of coconut until thick.  (10 minutes)  Cover and refrigerate.%0D%0D%0A%0D%0D%0AMix yolk mixture and milk in punch bowl.%0D%0D%0ABeat egg whites until stiff and fold into egg yolks.%0D%0D%0ABeat cream until stiff and fold into egg yolks.%0D%0D%0AStir in coconut flakes.",
    "equipment": "",
    "prepTime": "",
    "servingIdeas": "",
    "VARCHAR": "",
    "suggestedWine": "",
    "notes": "",
    "servings": ""
  },
  {
    "id": 40,
    "timestamp": 2454964.1569213,
    "title": "Eggnog",
    "source": "Bonnie",
    "time": "",
    "directions": "Beat egg yolks and light cream.%0D%0D%0AAdd sugar and salt.%0D%0D%0AStir in vanilla and rum.%0D%0D%0AChill 3 - 4 hours.%0D%0D%0AWhip egg whites until stiff.%0D%0D%0AWhip heavy cream until stiff.%0D%0D%0AFold all 3 together and sprinkle with cinnamon.",
    "equipment": "",
    "prepTime": "",
    "servingIdeas": "",
    "VARCHAR": "",
    "suggestedWine": "",
    "notes": "",
    "servings": "1"
  },
  {
    "id": 41,
    "timestamp": 2454964.16152778,
    "title": "Holiday Eggnog",
    "source": "Bonnie",
    "time": "",
    "directions": "Combine eggs with sugar, milk and vanilla.%0D%0D%0ABeat with wire whip until fluffy.%0D%0D%0ABeat cream until stiff.%0D%0D%0AFold into egg mixture.%0D%0D%0AMix in brandy to taste.%0D%0D%0APour into punch cups and sprinkle with nutmeg.%0D%0D%0A%0D%0D%0AMakes 1 quart.",
    "equipment": "",
    "prepTime": "",
    "servingIdeas": "",
    "VARCHAR": "",
    "suggestedWine": "",
    "notes": "",
    "servings": "1"
  },
  {
    "id": 42,
    "timestamp": 2454964.17726852,
    "title": "Pina Colada Punch",
    "source": "Bonnie",
    "time": "",
    "directions": "Mix all ingredients in punch bowl.%0D%0D%0ASprinkle with coconut and/or frozen strawberries.",
    "equipment": "",
    "prepTime": "",
    "servingIdeas": "",
    "VARCHAR": "",
    "suggestedWine": "",
    "notes": "",
    "servings": "1"
  },
  {
    "id": 43,
    "timestamp": 2454964.18386574,
    "title": "Amish Friendship Bread",
    "source": "Bonnie",
    "time": "",
    "directions": "Starter :  Mix 1/2 cup EACH of flour, skim milk, sugar, and 1 tbs. of yellow corn meal in plastic bowl and cover loosely with aluminum foil.  Stir each day with wooden spoon.  (It will take more than a week - It will have a yeast smell when ready.)  Begin at day 5.%0D%0D%0A%0D%0D%0ADay 1 :  Do nothing.%0D%0D%0ADay 2, 3, 4 :  Stir with a wooden spoon.%0D%0D%0ADay 5 :  Add 1 c. flour, 1 c. sugar, and 1 c. milk.  Place in bowl with lid.%0D%0D%0ADay 6, 7, 8, 9 :  Stir with wooden spoon.%0D%0D%0ADay 10 :  Add 1 c.flour, 1 c. sugar, and 1 c. milk.%0D%0D%0AMix and pour 1 cup of mixture into 3 separate containers.(There will be 1/2 cup remaining.)%0D%0D%0A%0D%0D%0AAdd all ingredients to 1/2 cup of starter and mix by hand.  Pour into 2 greased and sugared loaf pans or 1 tube/bundt pan.%0D%0D%0ABake at 350 for 45 minutes. (Cover with foil after 25 minutes.)  %0D%0D%0ACool 10 minutes and remove from pan.",
    "equipment": "",
    "prepTime": "",
    "servingIdeas": "",
    "VARCHAR": "",
    "suggestedWine": "",
    "notes": "",
    "servings": "1"
  },
  {
    "id": 44,
    "timestamp": 2454964.18493056,
    "title": "Blueberry Cornmeal Muffins",
    "source": "Bonnie",
    "time": "",
    "directions": "Mix dry ingredients.%0D%0D%0ACut in butter with pastry blender.%0D%0D%0AStir in yogurt and egg.%0D%0D%0AAdd berries.%0D%0D%0ABake at 350 for 20 - 25 minutes.",
    "equipment": "",
    "prepTime": "0 :15",
    "servingIdeas": "",
    "VARCHAR": "",
    "suggestedWine": "",
    "notes": "",
    "servings": "12"
  },
  {
    "id": 45,
    "timestamp": 2454964.18596065,
    "title": "Blueberry Muffins",
    "source": "Bonnie",
    "time": "",
    "directions": "Mix butter and sugar.%0D%0D%0AAdd eggs.%0D%0D%0AAdd vanilla, baking powder and salt.%0D%0D%0AFold in half of the milk and half of the flour. Repeat.%0D%0D%0AAdd berries.%0D%0D%0ABake at 375 for 25 - 30 minutes.",
    "equipment": "",
    "prepTime": "0 :15",
    "servingIdeas": "",
    "VARCHAR": "",
    "suggestedWine": "",
    "notes": "",
    "servings": "18"
  },
  {
    "id": 46,
    "timestamp": 2454964.18855324,
    "title": "Bread Pudding",
    "source": "Weight Watchers Favorite Recipes",
    "time": "",
    "directions": "Preheat oven to 350. Spray 2 cup casserole with nonstick spray.%0D%0D%0AArrange bread cubes and apple in 2 cup casserole.%0D%0D%0AIn small bowl combine milk, egg, sugar and vanilla and beat until smooth.%0D%0D%0APour milk mixture over bread and apple. Sprinkle with cinnamon and nutmeg.%0D%0D%0ABake for 35 - 40 minutes (until pudding is lightly browned and knife inserted in center comes out clean.)%0D%0D%0ARemove from oven and let stand 15 minutes before serving.%0D%0D%0A%0D%0D%0AMakes 1 serving.%0D%0D%0AEach serving provides :  1 protein, 1 bread, 1 fruit, 1 milk, %0D%0D%0A                                   20 optional calories %0D%0D%0AVariation :  Substitute 2 tbs. raisins for the apple.                                 ",
    "equipment": "",
    "prepTime": "",
    "servingIdeas": "",
    "VARCHAR": "",
    "suggestedWine": "",
    "notes": "",
    "servings": "1"
  },
  {
    "id": 47,
    "timestamp": 2454964.18959491,
    "title": "Broccoli Cornbread",
    "source": "Weight Watchers Favorite Recipes",
    "time": "",
    "directions": "Combine broccoli, cottage cheese, onion, egg, margarine, and salt until blended%3B  stir in cornmeal.%0D%0D%0ASpray 9' glass pie plate with Pam.  Spread mixture into pie plate.%0D%0D%0ABake at 350 for 30 minutes.  Let stand 10 minutes before serving.%0D%0D%0A%0D%0D%0APer serving :  142 calories%0D%0D%0A%0D%0D%0AEach serving provides :  1 fat, 1/2 protein, 1/2 vegetable, 1 bread, 5 optional calories%0D%0D%0A%0D%0D%0A3 points",
    "equipment": "",
    "prepTime": "",
    "servingIdeas": "",
    "VARCHAR": "",
    "suggestedWine": "",
    "notes": "",
    "servings": "8"
  },
  {
    "id": 48,
    "timestamp": 2454964.19107639,
    "title": "Carrot-Cake Muffins",
    "source": "Bonnie",
    "time": "",
    "directions": "Mix sugar, flour, cinnamon, baking soda and salt.%0D%0D%0AAdd fruit and nuts.%0D%0D%0AIn separate bowl, beat eggs, oil and vanilla.%0D%0D%0APour egg mixture into dry ingredients and blend.%0D%0D%0ASpoon batter into muffin papers.%0D%0D%0ABake at 350 for 25 minutes.%0D%0D%0A",
    "equipment": "",
    "prepTime": "",
    "servingIdeas": "",
    "VARCHAR": "",
    "suggestedWine": "",
    "notes": "",
    "servings": "12"
  },
  {
    "id": 49,
    "timestamp": 2454964.19212963,
    "title": "Cornbread with corn",
    "source": "Bonnie",
    "time": "",
    "directions": "Combine first 5 ingredients in large bowl.  Mix well.%0D%0D%0ACombine corn, butter, milk and eggs.  Pour into flour mixture and stir to blend.  %0D%0D%0APour into 8 inch greased square pan.  (or 12 muffin cups)%0D%0D%0ABake at 400 degrees for 25 - 30 minutes.  (bake 15-20 min. for muffins.)",
    "equipment": "",
    "prepTime": "",
    "servingIdeas": "",
    "VARCHAR": "",
    "suggestedWine": "",
    "notes": "",
    "servings": "12"
  },
  {
    "id": 50,
    "timestamp": 2454964.19258102,
    "title": "Cranberry Butter",
    "source": "Bonnie",
    "time": "",
    "directions": "Chop cranberries and sugar in food processor.%0D%0D%0ACombine butter and chopped cranberries and mix by hand until blended but still chunky.%0D%0D%0ACover and refrigerate.%0D%0D%0A%0D%0D%0A(You can prepare this a week in advance.)",
    "equipment": "",
    "prepTime": "",
    "servingIdeas": "",
    "VARCHAR": "",
    "suggestedWine": "",
    "notes": "",
    "servings": "1"
  },
  {
    "id": 51,
    "timestamp": 2454964.19454861,
    "title": "Gingerbread Squares",
    "source": "Family Circle Christmas Helps",
    "time": "",
    "directions": "Stir together the flours, baking powder, ginger, cinnamon, nutmeg, cloves, mustard, salt, cayenne and black pepper.  %0D%0D%0AIn a separate bowl, cream together butter and sugar until fluffy.  Beat in molasses and eggs.  Stir in 1/4 tsp. of baking soda until bubbles form.%0D%0D%0ACombine boiling water and remaining baking soda.  Stir into the butter mixture, blending well.  Add dry ingredients.  Mix well.%0D%0D%0APour batter into a greased 9%22 square pan.%0D%0D%0ABake at 350 degrees for 45 - 60 minutes, until a skewer inserted into the center comes out clean.  ",
    "equipment": "",
    "prepTime": "",
    "servingIdeas": "",
    "VARCHAR": "",
    "suggestedWine": "",
    "notes": "",
    "servings": "16"
  },
  {
    "id": 52,
    "timestamp": 2454964.19527778,
    "title": "Lauren's monkey bread",
    "source": "Lauren",
    "time": "",
    "directions": "Melt the butter and mix with water and brown sugar.  %0D%0D%0ACut the biscuits into quarters.  %0D%0D%0ADip the pieces into the mixture and put on a plate around a circular jar.  %0D%0D%0AMicrowave for 2-2 1/2 minutes. ",
    "equipment": "",
    "prepTime": "",
    "servingIdeas": "",
    "VARCHAR": "",
    "suggestedWine": "",
    "notes": "",
    "servings": "1"
  },
  {
    "id": 53,
    "timestamp": 2454964.19636574,
    "title": "Peanut Biscuits",
    "source": "Debby",
    "time": "",
    "directions": "Separate biscuits into 20 pieces.%0D%0D%0ACut cream cheese into 20 pieces.%0D%0D%0ASlit biscuit and insert cream cheese.  Pinch closed.%0D%0D%0ACombine sugar, nuts and orange rind.%0D%0D%0ADip biscuit in butter and sugar mixture.%0D%0D%0APlace side-by-side in round pan.%0D%0D%0ASprinkle remaining mixture on top of biscuits.%0D%0D%0ABake at 350 for 30 - 35 minutes.%0D%0D%0ACool 2 minutes and serve.",
    "equipment": "",
    "prepTime": "",
    "servingIdeas": "",
    "VARCHAR": "",
    "suggestedWine": "",
    "notes": "",
    "servings": "20"
  },
  {
    "id": 54,
    "timestamp": 2454964.19796296,
    "title": "Pesto Pizza",
    "source": "Bonnie",
    "time": "",
    "directions": "Blend spinach, basil, oil and garlic in food processor.%0D%0D%0ASpread pesto on pizza crust.%0D%0D%0ATop with vegetables and cheese.%0D%0D%0ABake at 450 degrees until cheese melts.  (10 minutes)",
    "equipment": "",
    "prepTime": "",
    "servingIdeas": "",
    "VARCHAR": "",
    "suggestedWine": "",
    "notes": "",
    "servings": "1"
  },
  {
    "id": 55,
    "timestamp": 2454964.19912037,
    "title": "Pumpkin Spice Bread",
    "source": "Family Circle Christmas Helps",
    "time": "",
    "directions": "Combine flour, sugar, baking soda, cinnamon, salt, cloves, nutmeg and baking powder%3B  Stir in raisins.%0D%0D%0AIn another bowl, beat together pumpkin, eggs, oil and water with a fork.  %0D%0D%0AAdd pumpkin mixture to flour mixture and stir just until moistened.%0D%0D%0APour batter into a greased loaf pan.%0D%0D%0ABake at 350 degrees for 1 hour or until toothpick comes out clean.%0D%0D%0ACook in pan for 10 minutes.  Remove from pan and cool completely.",
    "equipment": "",
    "prepTime": "",
    "servingIdeas": "",
    "VARCHAR": "",
    "suggestedWine": "",
    "notes": "",
    "servings": "16"
  },
  {
    "id": 56,
    "timestamp": 2454964.20322917,
    "title": "Sticky Buns",
    "source": "Bonnie",
    "time": "",
    "directions": "Cut rolls into 8 slices.%0D%0D%0APut brown sugar, corn syrup and butter in 8%22 pan and heat in oven until sugar dissolves.%0D%0D%0AStir in vanilla and nuts.%0D%0D%0AArrange slices in pan.%0D%0D%0ABake at 375 for 15 minutes.%0D%0D%0A",
    "equipment": "",
    "prepTime": "",
    "servingIdeas": "",
    "VARCHAR": "",
    "suggestedWine": "",
    "notes": "",
    "servings": "8"
  },
  {
    "id": 57,
    "timestamp": 2454964.20423611,
    "title": "Sweet Potato Muffins",
    "source": "Bonnie",
    "time": "",
    "directions": "Cream butter, sugar and sweet potatoes.%0D%0D%0AAdd eggs and blend.%0D%0D%0AMix flour, baking powder and spices together and add alternately with milk.%0D%0D%0ADO NOT OVERMIX.%0D%0D%0AFold in raisins and nuts.%0D%0D%0ABake at 400 for 25 minutes.",
    "equipment": "",
    "prepTime": "",
    "servingIdeas": "",
    "VARCHAR": "",
    "suggestedWine": "",
    "notes": "",
    "servings": "24"
  },
  {
    "id": 58,
    "timestamp": 2454964.20731481,
    "title": "Apple Lasagna",
    "source": "Bonnie",
    "time": "",
    "directions": "Mix cheddar cheese, ricotta, egg, sugar, and almond extract.%0D%0D%0A%0D%0D%0ASpread 1 can of apple pie filling in bottom of greased 9 x 13%22 pan.%0D%0D%0ALayer 1/2 noodles over filling, spread cheese mixture over noodles.%0D%0D%0ATop with remaining noodles and remaining can of pie filling.%0D%0D%0A%0D%0D%0ACombine flour, 6 tbs. brown sugar, oats, cinnamon, and nutmeg.  %0D%0D%0ACut in butter until crumbly.%0D%0D%0ASprinkle over pie filling.%0D%0D%0A%0D%0D%0ABake at 350 degrees for 45 minutes.  Cool 15 minutes.%0D%0D%0A%0D%0D%0ABlend sour cream and 1/3 cup brown sugar until smooth. Refrigerate.%0D%0D%0A%0D%0D%0ATo serve, cut lasagna into squares and top with sour cream mixture.",
    "equipment": "",
    "prepTime": "",
    "servingIdeas": "",
    "VARCHAR": "",
    "suggestedWine": "",
    "notes": "",
    "servings": "12"
  },
  {
    "id": 59,
    "timestamp": 2454964.20844907,
    "title": "Apple Salad",
    "source": "Bonnie",
    "time": "",
    "directions": "Chop apples w/skin.  Add walnuts, raisins and cherries.%0D%0D%0AIn separate bowl beat heavy cream w/ sugar and vanilla.%0D%0D%0AStir in cherry juice to make the cream pink.%0D%0D%0AFold cream into apples.%0D%0D%0ARefrigerate until ready to serve.%0D%0D%0AGarnish with additional cherries",
    "equipment": "",
    "prepTime": "",
    "servingIdeas": "",
    "VARCHAR": "",
    "suggestedWine": "",
    "notes": "",
    "servings": "10"
  },
  {
    "id": 60,
    "timestamp": 2454964.20976852,
    "title": "Blintz Souffle",
    "source": "Bonnie",
    "time": "",
    "directions": "Mix first 8 ingredients together for the batter.%0D%0D%0AMix next 5 ingredients together for the filling.%0D%0D%0APour half the batter into buttered 9x13%22 pan.%0D%0D%0ATop with filling.%0D%0D%0APour remaining batter over filling.%0D%0D%0ABake at 350 for 45 minutes.%0D%0D%0AUnbaked souffle may be covered and refrigerated for several hours or overnight.  Before baking, bring to room temperature.%0D%0D%0AServe with sour cream and jams.",
    "equipment": "",
    "prepTime": "",
    "servingIdeas": "",
    "VARCHAR": "",
    "suggestedWine": "",
    "notes": "",
    "servings": "8"
  },
  {
    "id": 61,
    "timestamp": 2454964.21131944,
    "title": "Breakfast Crepes",
    "source": "Bonnie",
    "time": "",
    "directions": "Add milk to eggs.  Whisk in flour, butter, vanilla and salt.  Mix until smooth.  Pour 1/4 cup of batter into pan and brown lightly.%0D%0D%0AFill crepes with ricotta filling or banana filling.%0D%0D%0ADust with powdered sugar.%0D%0D%0A",
    "equipment": "",
    "prepTime": "",
    "servingIdeas": "",
    "VARCHAR": "",
    "suggestedWine": "",
    "notes": "",
    "servings": "1"
  },
  {
    "id": 62,
    "timestamp": 2454964.21196759,
    "title": "Candied Sweet Potatoes",
    "source": "Bonnie",
    "time": "",
    "directions": "Boil, drain, peel and slice sweet potatoes.%0D%0D%0APlace in single layer of pan.%0D%0D%0ABoil remaining ingredients and pour over potatoes.%0D%0D%0ASprinkle with nuts.%0D%0D%0ABake at 350 for 20 minutes.",
    "equipment": "",
    "prepTime": "",
    "servingIdeas": "",
    "VARCHAR": "",
    "suggestedWine": "",
    "notes": "",
    "servings": "4"
  },
  {
    "id": 63,
    "timestamp": 2454964.21293981,
    "title": "Caramel Biscuit Ring",
    "source": "Bonnie",
    "time": "",
    "directions": "Heat all ingredients except biscuits until butter melts.%0D%0D%0AQuarter biscuits.  %0D%0D%0AMix biscuits into heated mixture and spoon into pan.%0D%0D%0ASprinkle with raisins.%0D%0D%0ABake at 400 for 20 - 30 minutes.%0D%0D%0ALet stand 3 minutes.%0D%0D%0AInvert onto plate.",
    "equipment": "",
    "prepTime": "",
    "servingIdeas": "",
    "VARCHAR": "",
    "suggestedWine": "",
    "notes": "",
    "servings": "1"
  },
  {
    "id": 64,
    "timestamp": 2454964.21390046,
    "title": "Caramel Nut Ring",
    "source": "Louis",
    "time": "",
    "directions": "Combine sugar, butter, water, cinnamon, vanilla and corn syrup.%0D%0D%0AHeat until butter melts.%0D%0D%0AMix thoroughly and add nuts and raisins.%0D%0D%0ASeparate biscuits and cut into quarters.%0D%0D%0AMix biscuit pieces into sugar mixture.%0D%0D%0ASpoon into greased bundt pan.%0D%0D%0ABake at 400 for 20 - 30 minutes or until golden brown.%0D%0D%0ALet stand 3 minutes.%0D%0D%0AInvert onto serving plate.%0D%0D%0A",
    "equipment": "",
    "prepTime": "",
    "servingIdeas": "",
    "VARCHAR": "",
    "suggestedWine": "",
    "notes": "",
    "servings": "1"
  },
  {
    "id": 65,
    "timestamp": 2454964.21454861,
    "title": "Cherry-Pineapple Cake/Cobbler",
    "source": "Mrs. Lacey",
    "time": "",
    "directions": "Mix cherries and pineapple.%0D%0D%0ASprinkle with cake mix.%0D%0D%0ADrizzle with butter.%0D%0D%0ABake at 350 for 1 hour.%0D%0D%0A%0D%0D%0A%0D%0D%0A",
    "equipment": "",
    "prepTime": "",
    "servingIdeas": "",
    "VARCHAR": "",
    "suggestedWine": "",
    "notes": "",
    "servings": "1"
  },
  {
    "id": 66,
    "timestamp": 2454964.21657407,
    "title": "Chocolate Tortoni",
    "source": "Bonnie",
    "time": "",
    "directions": "Melt unsweetened and semisweet chocolates in separate pans.%0D%0D%0ABeat egg whites with cream of tartar until foamy.  Gradually add sugar until soft peaks form.%0D%0D%0ABeat 2 cups of cream until stiff.  Add 2 tsp. sugar, vanilla, and unsweetened chocolate.  Blend well.%0D%0D%0AFold egg whites, almonds and semisweet chocolate into whipped cream mixture.%0D%0D%0ASpoon mixture into paper cupcake liners.  %0D%0D%0AFreeze 4 hours or overnight. ",
    "equipment": "",
    "prepTime": "",
    "servingIdeas": "",
    "VARCHAR": "",
    "suggestedWine": "",
    "notes": "",
    "servings": "24"
  },
  {
    "id": 67,
    "timestamp": 2454964.21767361,
    "title": "Creamy eggs",
    "source": "Bonnie",
    "time": "",
    "directions": "Cook mushrooms in butter.%0D%0D%0AAdd bacon, lemon juice and a dash of pepper.%0D%0D%0ACombine flour and milk and stir into mushrooms.%0D%0D%0ABring to boiling and cook until thickened.%0D%0D%0ASpread a thin layer of sauce over bottom of 9x13%22 pan.%0D%0D%0ASlice eggs and arrange over sauce.%0D%0D%0ASprinkle with cheese.%0D%0D%0A(Can be assembled up to this point 1 day ahead and refrigerated.)%0D%0D%0ABake at 375 for 15 - 20 minutes.",
    "equipment": "",
    "prepTime": "",
    "servingIdeas": "",
    "VARCHAR": "",
    "suggestedWine": "",
    "notes": "",
    "servings": "1"
  },
  {
    "id": 68,
    "timestamp": 2454964.2183912,
    "title": "Crumb Cake",
    "source": "Elaine",
    "time": "",
    "directions": "Prepare cake mix  following directions on box.%0D%0D%0ASpread on large cookie sheet sprayed with Pam.%0D%0D%0ABake at 350 for 15 minutes.%0D%0D%0AMix remaining ingredients until crumbly.%0D%0D%0ASprinkle on cake.%0D%0D%0ABake at 350 for 15 minutes more.%0D%0D%0A",
    "equipment": "",
    "prepTime": "",
    "servingIdeas": "",
    "VARCHAR": "",
    "suggestedWine": "",
    "notes": "",
    "servings": "1"
  },
  {
    "id": 69,
    "timestamp": 2454964.21895833,
    "title": "Death by Chocolate",
    "source": "Bonnie",
    "time": "",
    "directions": "Prepare pudding in two separate bowls.%0D%0D%0AMix one bowl of pudding with one tub of Cool Whip.%0D%0D%0AIn large bowl layer :  1/2 cake, 1/2 chocolate pudding, 1/2 Cool Whip, 1/2 pudding/Cool Whip mixture, 1/2 Heath bars.%0D%0D%0A%0D%0D%0ARepeat with second layer.%0D%0D%0A%0D%0D%0ARefrigerate for 8 hours or overnight.",
    "equipment": "",
    "prepTime": "",
    "servingIdeas": "",
    "VARCHAR": "",
    "suggestedWine": "",
    "notes": "",
    "servings": "1"
  },
  {
    "id": 70,
    "timestamp": 2454964.22982639,
    "title": "Eclair Pie",
    "source": "Bonnie",
    "time": "",
    "directions": "Mix milk with pudding.  Fold in Cool Whip.%0D%0D%0AIn 9x13%22 pan put a layer of graham crackers and top with half of pudding.%0D%0D%0ACover with another layer of graham crackers and remaining pudding.%0D%0D%0ATop with graham crackers.%0D%0D%0ACover graham crackers with chocolate icing.%0D%0D%0A%0D%0D%0AIcing :   Combine all ingredients.%0D%0D%0A3 tbs. cocoa%0D%0D%0A3 tbs. milk%0D%0D%0A3 tbs. melted butter%0D%0D%0A1 1/2 cups 10x sugar%0D%0D%0A%0D%0D%0ABetter if refrigerated overnight.",
    "equipment": "",
    "prepTime": "",
    "servingIdeas": "",
    "VARCHAR": "",
    "suggestedWine": "",
    "notes": "",
    "servings": "1"
  },
  {
    "id": 71,
    "timestamp": 2454964.23055556,
    "title": "Ham and Egg Casserole",
    "source": "Bonnie",
    "time": "",
    "directions": "Butter 1 1/2 quart baking dish.%0D%0D%0ALayer potatoes, eggs and ham.%0D%0D%0ASeason with salt and pepper.%0D%0D%0ABake at 350 for 10 minutes.%0D%0D%0ABeat egg.%0D%0D%0ABlend in sour cream.%0D%0D%0APour over potatoes, egg and ham.%0D%0D%0AReturn to oven and bake 5 minutes more.",
    "equipment": "",
    "prepTime": "",
    "servingIdeas": "",
    "VARCHAR": "",
    "suggestedWine": "",
    "notes": "",
    "servings": "1"
  },
  {
    "id": 72,
    "timestamp": 2454964.23196759,
    "title": "Holiday Sweet Potatoes",
    "source": "Bonnie",
    "time": "",
    "directions": "Cover and simmer sweet potatoes for 30 - 40 minutes.%0D%0D%0ADrain, peel and slice 1/2%22 thick.%0D%0D%0AHeat 1/2 cup pineapple juice, orange and lemon slices, sugar, raisins, butter, cinnamon, nutmeg and allspice to boiling.%0D%0D%0AReduce heat to low%3B cover and simmer until mixture thickens (10 min)%0D%0D%0ADiscard orange and lemon slices.%0D%0D%0AArrange potatoes in 9x13%22 pan.  Tuck pineapple, oranges, bananas and pecans among potatoes.%0D%0D%0APour sauce over potatoes.  Top with marshmallows.%0D%0D%0ABake at 350 for 20 - 25 minutes.",
    "equipment": "",
    "prepTime": "",
    "servingIdeas": "",
    "VARCHAR": "",
    "suggestedWine": "",
    "notes": "",
    "servings": "10"
  },
  {
    "id": 73,
    "timestamp": 2454964.2327662,
    "title": "Karen's Macaroni Salad",
    "source": "Karen",
    "time": "",
    "directions": "Cook macaroni and mix with Miracle Whip.%0D%0D%0ACool in refrigerator.%0D%0D%0A%0D%0D%0AHeat egg, vinegar and sugar until thick.%0D%0D%0ACool in refrigerator.%0D%0D%0A%0D%0D%0AChop vegetables.%0D%0D%0A%0D%0D%0AWhen macaroni and egg mixture are cool, mix together. %0D%0D%0AAdd celery seed, salt and pepper to taste.%0D%0D%0A%0D%0D%0AJust before serving add vegetables and more Miracle Whip.",
    "equipment": "",
    "prepTime": "",
    "servingIdeas": "",
    "VARCHAR": "",
    "suggestedWine": "",
    "notes": "",
    "servings": "1"
  },
  {
    "id": 74,
    "timestamp": 2454964.23364583,
    "title": "Mexican Salad",
    "source": "",
    "time": "",
    "directions": "Fry beef and drain.%0D%0D%0ADice tomatoes and peppers.%0D%0D%0AMix beef, vegetables, Catalina, and taco sauce.%0D%0D%0AAdd crumbled Doritos and cheese when ready to serve.%0D%0D%0A%0D%0D%0AAdd more Catalina if salad is dry.%0D%0D%0A",
    "equipment": "",
    "prepTime": "",
    "servingIdeas": "",
    "VARCHAR": "",
    "suggestedWine": "",
    "notes": "",
    "servings": "1"
  },
  {
    "id": 75,
    "timestamp": 2454964.3634838,
    "title": "Pineapple Pudding 1",
    "source": "Bonnie",
    "time": "",
    "directions": "Mix all ingredients and put in buttered casserole.%0D%0D%0ABake at 350 for 1 hour.",
    "equipment": "",
    "prepTime": "",
    "servingIdeas": "",
    "VARCHAR": "",
    "suggestedWine": "",
    "notes": "",
    "servings": "1"
  },
  {
    "id": 76,
    "timestamp": 2454966.20197917,
    "title": "Pineapple Pudding 2",
    "source": "Bonnie",
    "time": "",
    "directions": "Mix together bread and pineapple.%0D%0D%0ABeat eggs until light and fluffy.%0D%0D%0ABeat in butter and sugar.%0D%0D%0APour over bread.%0D%0D%0APour into buttered baking dish.%0D%0D%0ABake at 350 for 40 minutes.%0D%0D%0A",
    "equipment": "",
    "prepTime": "",
    "servingIdeas": "",
    "VARCHAR": "",
    "suggestedWine": "",
    "notes": "",
    "servings": "6"
  },
  {
    "id": 77,
    "timestamp": 2454966.20277778,
    "title": "Quiche",
    "source": "Bonnie",
    "time": "",
    "directions": "Sprinkle cheese on bottom of pie crust.%0D%0D%0ACombine the remaining ingredients and pour into shell.%0D%0D%0ABake at 425 for 15 minutes.%0D%0D%0AReduce heat to 350 and bake 45 minutes more.",
    "equipment": "",
    "prepTime": "",
    "servingIdeas": "",
    "VARCHAR": "",
    "suggestedWine": "",
    "notes": "",
    "servings": "1"
  },
  {
    "id": 78,
    "timestamp": 2454966.20372685,
    "title": "Scalloped Sweet Potatoes",
    "source": "Bonnie",
    "time": "",
    "directions": "Cover potatoes with water.  Boil and reduce to simmer - 15 minutes or until tender but firm.  Drain.%0D%0D%0ASlice potatoes 1/2%22 thick.%0D%0D%0APlace in double layer and dot with butter.%0D%0D%0AUsing wire whisk, beat cream,1/4 cup brown sugar and  1/2 tsp. cinnamon.  Pour over potatoes.%0D%0D%0ACover with foil.%0D%0D%0ABake at 325 for 15 minutes.%0D%0D%0ASprinkle with remaining brown sugar, cinnamon and walnuts.%0D%0D%0ABake, uncovered, 8 - 10 minutes more.",
    "equipment": "",
    "prepTime": "",
    "servingIdeas": "",
    "VARCHAR": "",
    "suggestedWine": "",
    "notes": "",
    "servings": "8"
  },
  {
    "id": 79,
    "timestamp": 2454966.20854167,
    "title": "Sweet Potato Casserole with Praline Topping",
    "source": "Bonnie",
    "time": "",
    "directions": "Beat eggs and stir in potatoes.%0D%0D%0AAdd remaining ingredients for potatoes and mix.%0D%0D%0ASpread in greased dish.%0D%0D%0A%0D%0D%0AMix brown sugar, flour and pecans.%0D%0D%0AAdd butter and blend.%0D%0D%0ASprinkle over potatoes.%0D%0D%0ABake at 350 for 60 - 70 minutes.%0D%0D%0A",
    "equipment": "",
    "prepTime": "",
    "servingIdeas": "",
    "VARCHAR": "",
    "suggestedWine": "",
    "notes": "",
    "servings": "1"
  },
  {
    "id": 80,
    "timestamp": 2454966.21207176,
    "title": "Sweet Potatoes and Apple Casserole",
    "source": "Bonnie",
    "time": "",
    "directions": "Butter baking dish.%0D%0D%0ALayer half of sweet potatoes, half of butter, half of apples and repeat.%0D%0D%0ACover with aluminum foil.%0D%0D%0ABake at 350 for 40 minutes.",
    "equipment": "",
    "prepTime": "",
    "servingIdeas": "",
    "VARCHAR": "",
    "suggestedWine": "",
    "notes": "",
    "servings": "1"
  },
  {
    "id": 81,
    "timestamp": 2454966.21302083,
    "title": "Tortellini in Tomato-Cream Sauce",
    "source": "Bonnie",
    "time": "",
    "directions": "Cook celery, carrots and onion in oil for 5 minutes.%0D%0D%0AAdd tomatoes with liquid, salt, pepper and sugar.%0D%0D%0AHeat to boiling.%0D%0D%0AReduce to medium and cook, uncovered, for 10 minutes.%0D%0D%0APrepare tortellini.%0D%0D%0AOver medium heat add heavy cream and basil to tomato mixture.%0D%0D%0AHeat through.%0D%0D%0AAdd tortellini.",
    "equipment": "",
    "prepTime": "",
    "servingIdeas": "",
    "VARCHAR": "",
    "suggestedWine": "",
    "notes": "",
    "servings": "2"
  },
  {
    "id": 82,
    "timestamp": 2454966.21414352,
    "title": "Walnut-Almond Granola",
    "source": "Bonnie",
    "time": "",
    "directions": "Mix honey, oil, vanilla, almond extract and cinnamon in saucepan.  Stir over medium heat just until warm.  Mix bran flakes, oats, almonds, walnuts and coconut in large bowl.  Pour honey mixture over cereal and toss to coat.  Divide granola between 2 baking sheets, spreading evenly.  Bake at 300 degrees until golden brown, stirring every 5 minutes, for about 25 minutes.  Sprinkle raisins over granola and cool completely.",
    "equipment": "",
    "prepTime": "",
    "servingIdeas": "",
    "VARCHAR": "",
    "suggestedWine": "",
    "notes": "",
    "servings": "1"
  },
  {
    "id": 83,
    "timestamp": 2454966.22424769,
    "title": "Watergate Salad",
    "source": "Bonnie",
    "time": "",
    "directions": "Mix pineapple juice with pudding.%0D%0D%0AStir in Cool Whip.%0D%0D%0AAdd pineapple, marshmallows and nuts.%0D%0D%0AMix well and refrigerate.",
    "equipment": "",
    "prepTime": "",
    "servingIdeas": "",
    "VARCHAR": "",
    "suggestedWine": "",
    "notes": "",
    "servings": "1"
  },
  {
    "id": 84,
    "timestamp": 2454966.2253588,
    "title": "Almond Pound Cake",
    "source": "Bonnie",
    "time": "",
    "directions": "Grease and flour a 10 inch Bundt pan.  %0D%0D%0ACombine flour, baking powder and salt.%0D%0D%0AIn large mixing bowl beat butter and cream cheese with an electric mixer on medium to high about 30 seconds.%0D%0D%0AGradually add sugar beating on medium speed about 5 minutes until very light and fluffy.  %0D%0D%0AAdd almond extract.%0D%0D%0AAdd eggs, 1 at a time beating on low after each one.%0D%0D%0AGradually add flour mixture, beating on low speed just until combined.%0D%0D%0ASpoon into Bundt pan.%0D%0D%0ABake at 325 degrees for 75 minutes.%0D%0D%0ACool for 15 minutes.%0D%0D%0ARemove from pan and cool completely for 2 hours.%0D%0D%0ATop with glaze.%0D%0D%0A%0D%0D%0AAlmond Glaze :%0D%0D%0AIn small mixing bowl beat together all glaze ingredients and spoon over cake.%0D%0D%0A",
    "equipment": "",
    "prepTime": "",
    "servingIdeas": "",
    "VARCHAR": "",
    "suggestedWine": "",
    "notes": "",
    "servings": "1"
  },
  {
    "id": 85,
    "timestamp": 2454966.22638889,
    "title": "Blueberry Pudding Cake",
    "source": "Bonnie",
    "time": "",
    "directions": "Put berries in 8x8%22 pan.%0D%0D%0AMix flour, baking powder, salt, butter, 3/4 cup sugar and milk. Spread over berries.%0D%0D%0AMix 1 cup sugar and cornstarch.%0D%0D%0ASprinkle over batter.%0D%0D%0APour 1 cup boiling water over mixture.%0D%0D%0ABake at 350 for 45 minutes.",
    "equipment": "",
    "prepTime": "",
    "servingIdeas": "",
    "VARCHAR": "",
    "suggestedWine": "",
    "notes": "",
    "servings": "1"
  },
  {
    "id": 86,
    "timestamp": 2454966.22877315,
    "title": "Cheesecake Dreams",
    "source": "Bonnie",
    "time": "",
    "directions": "Grease 8%22 pan.%0D%0D%0AMix br. sugar, flour and walnuts.  Stir in butter.  Pat mixture into pan.%0D%0D%0ABake at 350 for 12 - 15 minutes.%0D%0D%0AIn small bowl with electric mixer at medium speed beat cream cheese, and sugar.  Beat in remaining ingredients.  Pour over crust.%0D%0D%0ABake at 350 for 25 minutes.",
    "equipment": "",
    "prepTime": "",
    "servingIdeas": "",
    "VARCHAR": "",
    "suggestedWine": "",
    "notes": "",
    "servings": "1"
  },
  {
    "id": 87,
    "timestamp": 2454966.22952546,
    "title": "Cranberry Pie",
    "source": "Kathy",
    "time": "",
    "directions": "Mix cranberries, nuts and sugar and put in greased 10%22 pie plate.%0D%0D%0AMix remaining ingredients and spoon over berries.%0D%0D%0ABake at 350 degrees for 45-50 minutes.",
    "equipment": "",
    "prepTime": "",
    "servingIdeas": "",
    "VARCHAR": "",
    "suggestedWine": "",
    "notes": "",
    "servings": "1"
  },
  {
    "id": 88,
    "timestamp": 2454966.23037037,
    "title": "Cream Cheese Cupcakes",
    "source": "Bonnie",
    "time": "",
    "directions": "Beat cream cheese, eggs, 1 cup sugar and vanilla and fill cupcake papers.%0D%0D%0ABake at 300 for 30 - 40 minutes.%0D%0D%0ADo not brown.%0D%0D%0A%0D%0D%0AMix together sour cream, 1/4 cup sugar and vanilla.%0D%0D%0APut 1 tbs. of mixture on each cupcake.%0D%0D%0ABake at 300 for 5 minutes more.%0D%0D%0A ",
    "equipment": "",
    "prepTime": "",
    "servingIdeas": "",
    "VARCHAR": "",
    "suggestedWine": "",
    "notes": "",
    "servings": "36"
  },
  {
    "id": 89,
    "timestamp": 2454966.23118056,
    "title": "Double Chocolate Brownies",
    "source": "Bonnie",
    "time": "",
    "directions": "Melt butter and chocolate squares.  Remove from heat.  Beat in sugar with wooden spoon.  Add eggs one at a time.  Stir in vanilla.  Stir in flour.  Add one cup of walnuts.  Spread in greased 9x13%22 pan. Combine 1/2 cup walnuts and chocolate chips.  Sprinkle over top of mixture pressing down lightly.%0D%0D%0ABake at 350 for 35 min.%0D%0D%0A%0D%0D%0A4 sq. unsw. choc.%3D3/4 cup cocoa + 4 tbs. butter",
    "equipment": "",
    "prepTime": "",
    "servingIdeas": "",
    "VARCHAR": "",
    "suggestedWine": "",
    "notes": "",
    "servings": "1"
  },
  {
    "id": 90,
    "timestamp": 2454966.23209491,
    "title": "Hot Fudge Pudding Cake",
    "source": "Bonnie",
    "time": "",
    "directions": "In ungreased 9x9%22 pan mix flour, sugar, 2 tbs. cocoa, baking powder and salt.%0D%0D%0AMix in milk, oil and vanilla.%0D%0D%0AStir in nuts.%0D%0D%0ASpread smoothly and sprinkle with brown sugar and 4 tbs cocoa.%0D%0D%0APour water on top.%0D%0D%0ABake at 350 for 40 minutes.%0D%0D%0A",
    "equipment": "",
    "prepTime": "",
    "servingIdeas": "",
    "VARCHAR": "",
    "suggestedWine": "",
    "notes": "",
    "servings": "8"
  },
  {
    "id": 91,
    "timestamp": 2454966.23282407,
    "title": "Lemon Squares",
    "source": "Bonnie",
    "time": "",
    "directions": "Blend flour, butter and 10x sugar. %0D%0D%0APress into 8x8%22 pan.%0D%0D%0ABake at 350 for 20 minutes until slightly browned.%0D%0D%0ABeat remaining ingredients.%0D%0D%0APour over crust.%0D%0D%0ABake 20 - 25 minutes more.",
    "equipment": "",
    "prepTime": "",
    "servingIdeas": "",
    "VARCHAR": "",
    "suggestedWine": "",
    "notes": "",
    "servings": "1"
  },
  {
    "id": 92,
    "timestamp": 2454966.23342593,
    "title": "Magic Cookie Bars",
    "source": "",
    "time": "",
    "directions": "In 13x9%22 pan, melt butter.  Remove from heat.%0D%0D%0ASprinkle crumbs over butter.%0D%0D%0APour sweetened condensed milk evenly over crumbs.%0D%0D%0ATop with chocolate chips, coconut and nuts. Press down gently.%0D%0D%0ABake at 350 for 25 minutes or until golden brown. %0D%0D%0ACool and cut into bars.%0D%0D%0A",
    "equipment": "",
    "prepTime": "",
    "servingIdeas": "",
    "VARCHAR": "",
    "suggestedWine": "",
    "notes": "",
    "servings": "1"
  },
  {
    "id": 93,
    "timestamp": 2454966.23388889,
    "title": "Pineapple Angel Food Cake",
    "source": "Bonnie",
    "time": "",
    "directions": "Mix together only the two ingredients.%0D%0D%0APour into a 9x13%22 ungreased pan.%0D%0D%0ABake at 350 degrees for 30-35 minutes.%0D%0D%0A(Top came out dark.- Try lowering temperature.)%0D%0D%0A%0D%0D%0A12 Servings%0D%0D%0A3 pts.%0D%0D%0A",
    "equipment": "",
    "prepTime": "",
    "servingIdeas": "",
    "VARCHAR": "",
    "suggestedWine": "",
    "notes": "",
    "servings": "1"
  },
  {
    "id": 94,
    "timestamp": 2454966.23505787,
    "title": "Southern Comfort Apple Cake",
    "source": "Bonnie",
    "time": "",
    "directions": "In a small bowl, stir together apples and Southern Comfort.  Cover and chill for at least 6 hours and no more than 24.  Stir together cinnamon, nutmeg, baking soda, and flour and reserve.%0D%0D%0ACream together sugar and oil.  Add eggs, one at a time.  With mixer on lowest speed, add the dry ingredients and mix just until combined.  Using a spatula, gently fold in the apples, Southern Comfort, walnuts, and toffee.  %0D%0D%0APour mixture into an 8x8%22 greased pan.  %0D%0D%0ABake at 350 degrees for 45 - 50 minutes.  ",
    "equipment": "",
    "prepTime": "",
    "servingIdeas": "",
    "VARCHAR": "",
    "suggestedWine": "",
    "notes": "",
    "servings": "8"
  },
  {
    "id": 95,
    "timestamp": 2454966.23582176,
    "title": "Caramel Popcorn",
    "source": "Bonnie",
    "time": "",
    "directions": "Melt butter.  Add brown sugar, corn syrup and salt.%0D%0D%0ABring to a boil stirring constantly.%0D%0D%0AContinue to boil for 5 minutes without stirring.%0D%0D%0ARemove from heat and stir in baking soda and vanilla.%0D%0D%0APour over popcorn and mix well.%0D%0D%0APlace in glass dish.%0D%0D%0ABake at 200 for 1 hour.",
    "equipment": "",
    "prepTime": "",
    "servingIdeas": "",
    "VARCHAR": "",
    "suggestedWine": "",
    "notes": "",
    "servings": "1"
  },
  {
    "id": 96,
    "timestamp": 2454966.23635417,
    "title": "Haystacks",
    "source": "Bonnie",
    "time": "",
    "directions": "Melt chips and peanut butter.%0D%0D%0AStir in noodles and marshmallows.%0D%0D%0ADrop by spoon onto wax paper.%0D%0D%0ARefrigerate until hardened - 10 min.",
    "equipment": "",
    "prepTime": "",
    "servingIdeas": "",
    "VARCHAR": "",
    "suggestedWine": "",
    "notes": "",
    "servings": "24"
  },
  {
    "id": 97,
    "timestamp": 2454966.23694444,
    "title": "Microwave Cashew Brittle",
    "source": "Bonnie",
    "time": "",
    "directions": "In 1 1/2 qt. bowl, mix sugar and corn syrup.%0D%0D%0AMicrowave on high for 4 minutes.%0D%0D%0AStir in nuts.%0D%0D%0AMicrowave on high for 4 minutes.%0D%0D%0AAdd butter and vanilla and stir.%0D%0D%0AMicrowave on high 1 minute.%0D%0D%0AAdd baking soda and stir gently until light and foamy.%0D%0D%0APour onto lightly greased cookie sheets.%0D%0D%0ACool 1 hour and break into pieces.%0D%0D%0AMakes 1 lb.",
    "equipment": "",
    "prepTime": "",
    "servingIdeas": "",
    "VARCHAR": "",
    "suggestedWine": "",
    "notes": "",
    "servings": "1"
  },
  {
    "id": 98,
    "timestamp": 2454966.23753472,
    "title": "Saltine Crunch Bars",
    "source": "Bonnie",
    "time": "",
    "directions": "Line a 15/10 inch cookie sheet with aluminum foil.%0D%0D%0APlace saltines in a single layer on foil.%0D%0D%0AHeat butter and brown sugar over medium-high heat.Bring to boil and boil 3 minutes without stirring.%0D%0D%0APour over saltines.%0D%0D%0ABake for 7 minutes at 400 degrees.%0D%0D%0AImmediately sprinkle with chocolate chips.%0D%0D%0ALet stand 5 minutes.%0D%0D%0ASpread chocolate over saltines and sprinkle with walnuts.%0D%0D%0ACool and cut into pieces.",
    "equipment": "",
    "prepTime": "",
    "servingIdeas": "",
    "VARCHAR": "",
    "suggestedWine": "",
    "notes": "",
    "servings": "1"
  },
  {
    "id": 99,
    "timestamp": 2454966.23826389,
    "title": "Toffee",
    "source": "Bonnie",
    "time": "",
    "directions": "Line cookie sheet with foil.  Arrange almonds in single layer 12 x17%22.%0D%0D%0AHeat butter, sugar, vanilla and salt over high heat.  Stir with wooden spoon until butter is melted.  Cook and stir 5 - 7 minutes or until the color of unblanched almonds.  Immediately pour - w/o scraping pan - over the almonds.  Let cool completely.  %0D%0D%0AMelt chocolate in microwave.  %0D%0D%0ABreak toffee in pieces and dip in chocolate - spread in thin, even layer - scrape off excess.%0D%0D%0ACoat with ground walnuts.%0D%0D%0APlace on foil to set.%0D%0D%0AStore in tightly covered container in refrigerator.  ",
    "equipment": "",
    "prepTime": "",
    "servingIdeas": "",
    "VARCHAR": "",
    "suggestedWine": "",
    "notes": "",
    "servings": "1"
  },
  {
    "id": 100,
    "timestamp": 2454966.24210648,
    "title": "Amaretto Chunk Cookies",
    "source": "Bonnie",
    "time": "",
    "directions": "Combine flour, baking soda, baking powder and salt.%0D%0D%0AAt medium speed, beat butter and sugars until creamy.%0D%0D%0ABeat in eggs, amaretto and extract.%0D%0D%0AReduce speed to low%3B  beat in flour mixture just until blended.%0D%0D%0AStir in chips, coconut and almonds.%0D%0D%0ADrop dough by heaping tablespoons, 2 inches apart, on ungreased cookie sheet.%0D%0D%0ABake cookies for 10 to 12 minutes at 375 degrees.%0D%0D%0A%0D%0D%0AMakes 4 dozen cookies",
    "equipment": "",
    "prepTime": "",
    "servingIdeas": "",
    "VARCHAR": "",
    "suggestedWine": "",
    "notes": "",
    "servings": "1"
  },
  {
    "id": 101,
    "timestamp": 2454966.24328704,
    "title": "Chocolate-Almond Biscotti",
    "source": "Bonnie",
    "time": "",
    "directions": "Toast almonds over medium heat.  Cool and chop.%0D%0D%0ABeat sugar, cocoa, butter, baking powder, almond extract, vanilla, baking soda, salt, eggs, and 1 cup flour with mixer at medium speed until well blended.  %0D%0D%0AWith spoon stir in almonds and 1/4 cup of flour.%0D%0D%0ADivide dough in half.  Shape into 8%22 x 2%22 loaves and place on greased cookie sheet 3%22 apart.%0D%0D%0ABake at 350 for 35 - 40 minutes.%0D%0D%0ARemove from oven and cool for 10 minutes.%0D%0D%0ACut into 1/2%22 slices.%0D%0D%0AReturn to oven and bake 5 minutes longer.%0D%0D%0AStore in airtight container.%0D%0D%0AMakes 3 dozen cookies.  ",
    "equipment": "",
    "prepTime": "",
    "servingIdeas": "",
    "VARCHAR": "",
    "suggestedWine": "",
    "notes": "",
    "servings": "1"
  },
  {
    "id": 102,
    "timestamp": 2454966.24443287,
    "title": "Chocolate-Covered Cherry Cookies",
    "source": "Bonnie",
    "time": "",
    "directions": "Mix flour, cocoa, salt, baking powder and baking soda.  %0D%0D%0AIn separate bowl beat butter and sugar.  Add egg and vanilla.%0D%0D%0AAdd butter mixture to dry ingredients.%0D%0D%0AShape into 1%22 balls and place on ungreased cookie sheet.%0D%0D%0APress down center with thumb.  Place cherry in center.%0D%0D%0AIn saucepan combine chocolate and sw. condensed milk.%0D%0D%0AHeat until chocolate melts.  Stir in 4 tsp. of cherry juice.%0D%0D%0APlace 1 tsp. frosting on cookie.  (Frosting may be thinned with cherry juice.%0D%0D%0ABake at 350 for 10 minutes.",
    "equipment": "",
    "prepTime": "",
    "servingIdeas": "",
    "VARCHAR": "",
    "suggestedWine": "",
    "notes": "",
    "servings": "48"
  },
  {
    "id": 103,
    "timestamp": 2454966.2456713,
    "title": "Chunky Chocolate Jam Bars",
    "source": "Bonnie",
    "time": "",
    "directions": "Preheat oven to 350 degrees.  Line a 9x13%22 pan with aluminum foil.  Grease foil.%0D%0D%0A%0D%0D%0AMix flour, brown sugar and baking powder in a large bowl.%0D%0D%0ACut in butter until mixture is crumbly.%0D%0D%0AAdd oats, coconut and pecans and mix well.%0D%0D%0A%0D%0D%0ADivide mixture in half.  Press half of the crumb mixture onto bottom of baking pan.  Sprinkle with chopped chocolate.  Spoon jam over top.%0D%0D%0ASprinkle with remaining crumb mixture.%0D%0D%0A%0D%0D%0ABake for 25 - 30 minutes or until lightly browned.  Cool and then lift from pan and cut into squares.",
    "equipment": "",
    "prepTime": "",
    "servingIdeas": "",
    "VARCHAR": "",
    "suggestedWine": "",
    "notes": "",
    "servings": "1"
  },
  {
    "id": 104,
    "timestamp": 2454966.2466088,
    "title": "Cocoa Sandies",
    "source": "BH G Holiday Cooking",
    "time": "",
    "directions": "Beat butter, sugar, and vanilla in large bowl about 3 minutes or till creamy.%0D%0D%0AAdd cocoa and blend well.%0D%0D%0AAdd flour and salt, beating till smooth.%0D%0D%0ARoll dough on a lightly floured suface to 1/2 inch thickness.%0D%0D%0ACut out cookies.  Place on an ungreased cookie sheet.  %0D%0D%0ABake for 15 - 20 minutes at 350.",
    "equipment": "",
    "prepTime": "",
    "servingIdeas": "",
    "VARCHAR": "",
    "suggestedWine": "",
    "notes": "",
    "servings": "24"
  },
  {
    "id": 105,
    "timestamp": 2454966.24724537,
    "title": "Coconut Macaroons",
    "source": "Bonnie",
    "time": "",
    "directions": "Mix all ingredients together.%0D%0D%0ADrop heaping teaspoons onto greased cookie sheet 2 inches apart.%0D%0D%0AGently press mounds together to hold shape.%0D%0D%0APress cherry into center of cookie.%0D%0D%0ABake at 350 degrees for 10-12 minutes.",
    "equipment": "",
    "prepTime": "",
    "servingIdeas": "",
    "VARCHAR": "",
    "suggestedWine": "",
    "notes": "",
    "servings": "1"
  },
  {
    "id": 106,
    "timestamp": 2454966.24810185,
    "title": "Cranberry-Pecan Delights",
    "source": "Bonnie",
    "time": "",
    "directions": "Combine flour, sugar, salt and baking soda.%0D%0D%0ABeat oil, butter and orange juice on medium speed for 2 minutes.%0D%0D%0ABeat in egg.%0D%0D%0AAt lowest speed, beat in flour mixture, just until blended.%0D%0D%0AAdd cranberries and pecans.%0D%0D%0ABeat for 1 minute.%0D%0D%0ADrop by spoonfuls onto baking sheet.%0D%0D%0ABake at 400 degrees for 8 to 10 minutes.%0D%0D%0A%0D%0D%0AMakes 3 dozen cookies.%0D%0D%0A%0D%0D%0A",
    "equipment": "",
    "prepTime": "",
    "servingIdeas": "",
    "VARCHAR": "",
    "suggestedWine": "",
    "notes": "",
    "servings": "36"
  },
  {
    "id": 107,
    "timestamp": 2454966.24909722,
    "title": "Double Chocolate Oatmeal Cookies",
    "source": "Bonnie",
    "time": "",
    "directions": "Melt 1 cup chocolate.%0D%0D%0ABeat butter and sugar.%0D%0D%0ABlend in eggs, vanilla and 1 cup melted chocolate.%0D%0D%0AAdd all dry ingredients except 10x sugar.%0D%0D%0AAdd 1 cup chocolate chips.%0D%0D%0AShape in 1%22 balls.%0D%0D%0ARoll in 10x sugar and coat heavily.%0D%0D%0APlace on ungreased cookie sheet.%0D%0D%0ABake at 350 for 10 - 12 minutes.",
    "equipment": "",
    "prepTime": "",
    "servingIdeas": "",
    "VARCHAR": "",
    "suggestedWine": "",
    "notes": "",
    "servings": "1"
  },
  {
    "id": 108,
    "timestamp": 2454966.25016204,
    "title": "Ginger Cookies",
    "source": "Bonnie",
    "time": "",
    "directions": "Combine first 6 ingredients.%0D%0D%0AIn separate bowl beat butter on low for 30 seconds.%0D%0D%0AGradually add 1 cup sugar and beat until fluffy.%0D%0D%0AAdd egg and molasses and beat well.%0D%0D%0AAdd dry ingredients.%0D%0D%0AShape into 1 1/2%22 balls.%0D%0D%0ARoll in sugar.%0D%0D%0APlace on ungreased cookie sheet 2 1/2%22 apart.%0D%0D%0ABake at 350 for 10 minutes.",
    "equipment": "",
    "prepTime": "",
    "servingIdeas": "",
    "VARCHAR": "",
    "suggestedWine": "",
    "notes": "",
    "servings": "1"
  },
  {
    "id": 109,
    "timestamp": 2454966.2512037,
    "title": "Gramercy Chocolate Brownie Cookie",
    "source": "",
    "time": "",
    "directions": "In a small bowl, whisk flour, baking powder and salt.%0D%0D%0A%0D%0D%0AIn the bowl of an electric mixer, briefly whip the eggs to break them up.  Add the sugar and vanilla and beat on high speed for 15 minutes, until thick.%0D%0D%0A%0D%0D%0APlace the butter in the top of a double boiler and melt with bittersweet chocolate and unsweetened chocolate.  Heat until melted.  Remove from heat and stir until smooth.%0D%0D%0A%0D%0D%0AGently fold the chocolate mixture into the egg mixture until partially combined (there should still be some streaks).  Add the flour mixture to the batter and carefully fold it in.  Fold in the chocolate chips.  If the batter is very runny, let it rest until it thickens slightly, about 5 minutes.%0D%0D%0A%0D%0D%0ADrop the batter onto greased cookie sheets.  Bake until puffy and cracked, 8 to 9 minutes at 375 degrees.%0D%0D%0A%0D%0D%0AVariations :%0D%0D%0ASubstitute 1/2 cup chopped nuts for an equal amount of chocolate chips.",
    "equipment": "",
    "prepTime": "",
    "servingIdeas": "",
    "VARCHAR": "",
    "suggestedWine": "",
    "notes": "",
    "servings": "1"
  },
  {
    "id": 110,
    "timestamp": 2454966.25216435,
    "title": "Italian Cookies",
    "source": "Bonnie",
    "time": "",
    "directions": "Cream butter and sugar.  Add vanilla and eggs.%0D%0D%0AAdd ricotta cheese and mix by hand.%0D%0D%0AStir in dry ingredients.%0D%0D%0ADrop by teaspoon onto ungreased cookie sheet.%0D%0D%0ABake at 350 for 10 - 12 minutes.%0D%0D%0AIcing :  1 cup butter%0D%0D%0A          1 1/2 cups 10x sugar%0D%0D%0A          1/4 cup evaporated milk%0D%0D%0A          Pinch of salt%0D%0D%0ACream butter and sugar.  Add milk and salt.  Beat on high speed for 5 minutes.  Any flavoring may be added.  (anisette)",
    "equipment": "",
    "prepTime": "",
    "servingIdeas": "",
    "VARCHAR": "",
    "suggestedWine": "",
    "notes": "",
    "servings": "1"
  },
  {
    "id": 111,
    "timestamp": 2454966.25314815,
    "title": "Magic Peanut Butter Middles",
    "source": "Bonnie",
    "time": "",
    "directions": "Mix together flour, cocoa and baking soda.%0D%0D%0AIn separate bowl cream sugar, brown sugar, butter and peanut butter.  Add vanilla and egg.%0D%0D%0AStir in flour mixture.%0D%0D%0AFor the filling, mix together 3/4 cup peanut butter and 3/4 cup 10x sugar.  Shape into 30 balls.%0D%0D%0AShape 1 tbs.chocolate dough around peanut butter ball.%0D%0D%0APlace on ungreased cookie sheet.%0D%0D%0AFlatten with glass dipped in sugar.%0D%0D%0ABake at 375 for 7 minutes.",
    "equipment": "",
    "prepTime": "",
    "servingIdeas": "",
    "VARCHAR": "",
    "suggestedWine": "",
    "notes": "",
    "servings": "30"
  },
  {
    "id": 112,
    "timestamp": 2454966.25418981,
    "title": "Oatmeal Cranberry White Chocolate Chunk Cookies",
    "source": "Bonnie",
    "time": "",
    "directions": "Beat butter and sugar until light and fluffy.%0D%0D%0AAdd eggs, mixing well.%0D%0D%0ACombine oats, flour, baking soda and salt in a separate bowl.%0D%0D%0AAdd to butter mixture in several additions, mixing well after each addition.%0D%0D%0AStir in cranberries and white chocolate chips.%0D%0D%0ADrop by teaspoon onto ungreased baking sheet.%0D%0D%0ABake at 375 degrees for 10 to 12 minutes.%0D%0D%0A%0D%0D%0AMakes 2 1/2 dozens.",
    "equipment": "",
    "prepTime": "",
    "servingIdeas": "",
    "VARCHAR": "",
    "suggestedWine": "",
    "notes": "",
    "servings": "30"
  },
  {
    "id": 113,
    "timestamp": 2454966.2553125,
    "title": "Pfeffernusse",
    "source": "Family Circle Christmas Helps",
    "time": "",
    "directions": "Combine flour, baking powder, pepper, salt, ginger, cinnamon, cloves, and nutmeg. %0D%0D%0AIn another bowl, beat butter, molasses and sugar until fluffy.%0D%0D%0ABeat in egg until blended.%0D%0D%0ARefrigerate dough until chilled.  Shape into 1%22 balls and place 2%22 apart on greased baking sheet.%0D%0D%0ABake at 350 degrees for 12 - 15 minutes.%0D%0D%0AWhile still warm, roll cookies in confectioner's sugar.%0D%0D%0ACool and roll in sugar again.",
    "equipment": "",
    "prepTime": "",
    "servingIdeas": "",
    "VARCHAR": "",
    "suggestedWine": "",
    "notes": "",
    "servings": "40"
  },
  {
    "id": 114,
    "timestamp": 2454966.25628472,
    "title": "Pizzelles",
    "source": "the pizzelle maker",
    "time": "",
    "directions": "Beat eggs, adding sugar gradually.  Beat until smooth.  Add cooled margarine and vanilla or anise.  Sift flour and baking powder.  Blend into egg mixture until smooth.  Dough will be sticky enough to be dropped by spoon.  Bake in Pizzelle Baker.",
    "equipment": "",
    "prepTime": "",
    "servingIdeas": "",
    "VARCHAR": "",
    "suggestedWine": "",
    "notes": "",
    "servings": ""
  },
  {
    "id": 115,
    "timestamp": 2454966.2575,
    "title": "Potato Chip Cookies",
    "source": "BH G Holiday Cooking",
    "time": "",
    "directions": "Combine butter and 1 cup confectioner's sugar in large bowl.%0D%0D%0ABeat on medium for 3 minutes.%0D%0D%0AAdd egg yolk and vanilla and beat till well mixed.%0D%0D%0AGradually beat in flour.%0D%0D%0AStir in potato chips and pecans with a wooden spoon.%0D%0D%0ADrop onto greased cookie sheet. %0D%0D%0ABake  at 350 for 10 minutes.%0D%0D%0AWhile cookies are still warm, sift with confectioner's sugar.",
    "equipment": "",
    "prepTime": "",
    "servingIdeas": "",
    "VARCHAR": "",
    "suggestedWine": "",
    "notes": "",
    "servings": "72"
  },
  {
    "id": 116,
    "timestamp": 2454966.25898148,
    "title": "Pumpkin Cookies",
    "source": "Bonnie",
    "time": "",
    "directions": "Cream butter and sugar.%0D%0D%0ABeat in egg, pumpkin and vanilla.%0D%0D%0AMix dry ingredients and add to butter mixture.%0D%0D%0AStir in almonds and chocolate chips.%0D%0D%0ADrop by teaspoon onto greased cookie sheet.%0D%0D%0ABake at 350 for 15 minutes.",
    "equipment": "",
    "prepTime": "",
    "servingIdeas": "",
    "VARCHAR": "",
    "suggestedWine": "",
    "notes": "",
    "servings": "1"
  },
  {
    "id": 117,
    "timestamp": 2454966.25961806,
    "title": "Snickerdoodles",
    "source": "Bonnie",
    "time": "",
    "directions": "Cream butter, sugar and eggs.%0D%0D%0AStir in remaining ingredients.%0D%0D%0ARefrigerate 30 minutes.%0D%0D%0AShape in 1%22 balls.%0D%0D%0AMix 2 tsp. cinnamon and 2 tbs. sugar.%0D%0D%0ARoll balls in sugar mixture.%0D%0D%0ABake at 375 for 12 - 15 minutes.",
    "equipment": "",
    "prepTime": "",
    "servingIdeas": "",
    "VARCHAR": "",
    "suggestedWine": "",
    "notes": "",
    "servings": "1"
  },
  {
    "id": 118,
    "timestamp": 2454966.26053241,
    "title": "TollHouse Cookies",
    "source": "",
    "time": "",
    "directions": "Cream butter and sugar.%0D%0D%0AMix in brown sugar and eggs.%0D%0D%0AMix in all other dry ingredients and vanilla.%0D%0D%0AStir in chips and nuts.%0D%0D%0ABake at 375 for 8 - 10 minutes.%0D%0D%0A",
    "equipment": "",
    "prepTime": "",
    "servingIdeas": "",
    "VARCHAR": "",
    "suggestedWine": "",
    "notes": "",
    "servings": "1"
  },
  {
    "id": 119,
    "timestamp": 2454967.34271991,
    "title": "Banana Pudding with Cool Whip",
    "source": "Bonnie",
    "time": "",
    "directions": "Combine milk and pudding.%0D%0D%0AAdd sweetened condensed milk and mix.%0D%0D%0ABlend in Cool Whip.%0D%0D%0AIn large bowl pour a thin layer of pudding mixture.%0D%0D%0ATop with a layer of vanilla wafers and a layer of bananas.%0D%0D%0ARepeat until pudding is used.%0D%0D%0AChill for 24 hours.",
    "equipment": "",
    "prepTime": "0 :15",
    "servingIdeas": "",
    "VARCHAR": "",
    "suggestedWine": "",
    "notes": "",
    "servings": "12"
  },
  {
    "id": 120,
    "timestamp": 2454967.34356481,
    "title": "Black Forest Pie",
    "source": "Bonnie",
    "time": "",
    "directions": "Combine sugar, cocoa and flour.  Add butter and milk.  Cook until mixture begins to boil.  Remove from heat.  Add small amount of mixture to eggs and return to pan.  %0D%0D%0AFold HALF of pie filling into mixture.%0D%0D%0APour into pie shell.%0D%0D%0ABake at 350 for 35 - 45 minutes.%0D%0D%0ACool and chill  for 1 hour.%0D%0D%0ASpread with 2 cups of Cool Whip.%0D%0D%0ATop with the remaining pie filling.",
    "equipment": "",
    "prepTime": "",
    "servingIdeas": "",
    "VARCHAR": "",
    "suggestedWine": "",
    "notes": "",
    "servings": ""
  },
  {
    "id": 121,
    "timestamp": 2454967.34429398,
    "title": "Blueberry Delight",
    "source": "500 Fat Free Recipes",
    "time": "",
    "directions": "Preheat broiler.%0D%0D%0APlace blueberries in a glass pie pan.%0D%0D%0ACombine sour cream and yogurt.%0D%0D%0ASpoon mixture over berries.%0D%0D%0ASprinkle with brown sugar.%0D%0D%0APlace pie pan under broiler for 2 minutes or until sugar has melted.%0D%0D%0ADivide into 4 portions.%0D%0D%0A%0D%0D%0ACalories per serving :  84%0D%0D%0AFat :  3g",
    "equipment": "",
    "prepTime": "",
    "servingIdeas": "",
    "VARCHAR": "",
    "suggestedWine": "",
    "notes": "",
    "servings": "4"
  },
  {
    "id": 122,
    "timestamp": 2454967.34512731,
    "title": "Candied Nuts",
    "source": "Winnie",
    "time": "",
    "directions": "Beat 2 egg whites with 2 teaspoons of water (WITH A WOODEN SPOON).  Using the wooden spoon mix egg white with nuts and coat all nuts.%0D%0D%0A%0D%0D%0AIn a separate bowl mix :%0D%0D%0A3/4 - 1 cup sugar%0D%0D%0A1-2 tsp. pumpkin pie spice%0D%0D%0A2/3 tsp. salt%0D%0D%0A%0D%0D%0ABlend well and pour over nuts.%0D%0D%0AMix with wooden spoon.%0D%0D%0ALine cookie sheet with aluminum foil and spray.%0D%0D%0ASpread nuts on cookie sheet. %0D%0D%0ABake for 20 - 25 minutes at 350 degrees.%0D%0D%0A%0D%0D%0AImmediately place nuts on wax paper and toss with raisins.%0D%0D%0A",
    "equipment": "",
    "prepTime": "",
    "servingIdeas": "",
    "VARCHAR": "",
    "suggestedWine": "",
    "notes": "",
    "servings": "1"
  },
  {
    "id": 123,
    "timestamp": 2454967.34578704,
    "title": "Cannoli Pie",
    "source": "Bonnie",
    "time": "",
    "directions": "Combine all ingredients and mix slowly.%0D%0D%0ASpoon into 2 pie shells.%0D%0D%0ARefrigerate.%0D%0D%0A%0D%0D%0A",
    "equipment": "",
    "prepTime": "",
    "servingIdeas": "",
    "VARCHAR": "",
    "suggestedWine": "",
    "notes": "",
    "servings": "1"
  },
  {
    "id": 124,
    "timestamp": 2454967.34652778,
    "title": "Cheesecake Chip Dip",
    "source": "Bonnie",
    "time": "",
    "directions": "Mix cream cheese, sugar, vanilla and milk until smooth. %0D%0D%0AFold in Cool Whip.%0D%0D%0AChill.%0D%0D%0AServe with Cinnamon Sugar Pita Chips",
    "equipment": "",
    "prepTime": "",
    "servingIdeas": "",
    "VARCHAR": "",
    "suggestedWine": "",
    "notes": "",
    "servings": "1"
  },
  {
    "id": 125,
    "timestamp": 2454967.3471875,
    "title": "Chocolate Fondue",
    "source": "Bonnie",
    "time": "",
    "directions": "Heat yolks and cream until thick. (15 minutes)%0D%0D%0AAdd chocolate and stir until melted.  DO NOT BOIL%21%0D%0D%0ARemove from heat and add amaretto and vanilla.%0D%0D%0A%0D%0D%0AServe with pineapple, maraschino cherries, banana, apples, mandarin oranges, pound cake, strawberries.%0D%0D%0A",
    "equipment": "",
    "prepTime": "",
    "servingIdeas": "",
    "VARCHAR": "",
    "suggestedWine": "",
    "notes": "",
    "servings": "1"
  },
  {
    "id": 126,
    "timestamp": 2454967.34802083,
    "title": "Fudge Chocolate Torte",
    "source": "Bonnie",
    "time": "",
    "directions": "Mix melted butter, cocoa and 3/4 sugar.  Remove from heat and cool for 5 minutes.  Add almonds and flour.  Beat in yolks.  Add water.  Beat egg whites until foamy.  Add 1/4 cup sugar.  Fold chocolate into egg whites.  Pour into greased and floured 9%22 layer cake pan.  Bake at 350 for 30 minutes.  Cool 10 minutes and remove  from pan.  Cool completely and glaze. %0D%0D%0AGlaze :  Melt 2 butter.  Add 2 tbs. cocoa and 2 tbs. water.  Do not boil.  Remove from heat.  Add 1/2 tsp. vanilla.  Add 1 cup 10x sugar. ",
    "equipment": "",
    "prepTime": "",
    "servingIdeas": "",
    "VARCHAR": "",
    "suggestedWine": "",
    "notes": "",
    "servings": "1"
  },
  {
    "id": 127,
    "timestamp": 2454967.3487963,
    "title": "Fudge Muffins",
    "source": "Bonnie",
    "time": "",
    "directions": "Melt butter with chocolate.%0D%0D%0ACombine sugar, flour and salt.%0D%0D%0AStir in chocolate mixture.%0D%0D%0AAdd eggs and vanilla and whisk just until moist - do not overmix.%0D%0D%0AFold in nuts.%0D%0D%0ASpoon into paper muffin cups 2/3 full.%0D%0D%0ABake at 300 for 40 minutes.%0D%0D%0A",
    "equipment": "",
    "prepTime": "",
    "servingIdeas": "",
    "VARCHAR": "",
    "suggestedWine": "",
    "notes": "",
    "servings": "36"
  },
  {
    "id": 128,
    "timestamp": 2454967.34972222,
    "title": "Peach Cobbler",
    "source": "Bonnie",
    "time": "",
    "directions": "Sprinkle peaches with lemon juice.%0D%0D%0ASpread in 8%22 pan.%0D%0D%0AMix flour, sugar and salt.%0D%0D%0AAdd egg and toss until crumbly.%0D%0D%0ASprinkle over peaches.%0D%0D%0ADrizzle with butter and sprinkle with cinnamon.%0D%0D%0ABake at 375 for 45 minutes.",
    "equipment": "",
    "prepTime": "",
    "servingIdeas": "",
    "VARCHAR": "",
    "suggestedWine": "",
    "notes": "",
    "servings": "6"
  },
  {
    "id": 129,
    "timestamp": 2454967.35050926,
    "title": "Sugarless Cheesecake",
    "source": "Suzanne Somers",
    "time": "",
    "directions": "Beat cream cheese and sweetener until very smooth, about 3 min.%0D%0D%0AAdd the eggs, one at a time.%0D%0D%0AAdd vanilla and salt.%0D%0D%0ABeat in the sour cream until just blended.%0D%0D%0AGrease an 8%22 springboard pan and line bottom with waxed paper.%0D%0D%0AWrap outside of pan with a double layer of aluminum foil.%0D%0D%0APour batter into pan.%0D%0D%0ASet pan in a large roasting pan and surround with 1%22 of hot water.%0D%0D%0ABake for 45 min. at 350 degrees.%0D%0D%0ATurn off the oven without opening door and leave for 1 hour.%0D%0D%0ARemove to rack and cool to room temperature.",
    "equipment": "",
    "prepTime": "",
    "servingIdeas": "",
    "VARCHAR": "",
    "suggestedWine": "",
    "notes": "",
    "servings": "1"
  },
  {
    "id": 130,
    "timestamp": 2454967.35109954,
    "title": "Blueberry Lemonade",
    "source": "Bonnie",
    "time": "",
    "directions": "Make a syrup with 1 cup sugar and water.  Boil over medium heat and continue simmering for 1 minute.  Remove from heat and let cool.%0D%0D%0A%0D%0D%0APuree blueberries and 2 tbs. of sugar in food processor.%0D%0D%0A%0D%0D%0AIn a pitcher, combine syrup, lemon juice and blueberry puree.%0D%0D%0A%0D%0D%0ATo serve, pour 1/4 to 1/3 of base into glass and fill with water.%0D%0D%0A%0D%0D%0AMakes base for 12 servings.",
    "equipment": "",
    "prepTime": "",
    "servingIdeas": "",
    "VARCHAR": "",
    "suggestedWine": "",
    "notes": "",
    "servings": "12"
  },
  {
    "id": 131,
    "timestamp": 2454967.3516088,
    "title": "Creamy Limoncello",
    "source": "Lou",
    "time": "",
    "directions": "Zest the lemons and place in a container with the alcohol.%0D%0D%0ALet sit for 2 weeks.%0D%0D%0AIn a large pot, heat sugar and cream until sugar dissolves, about 5 minutes.%0D%0D%0ALet mixture cool completely.%0D%0D%0AStrain the lemon/alcohol mixture and add to the pot.%0D%0D%0APour mixture into bottles.",
    "equipment": "",
    "prepTime": "",
    "servingIdeas": "",
    "VARCHAR": "",
    "suggestedWine": "",
    "notes": "",
    "servings": "1"
  },
  {
    "id": 132,
    "timestamp": 2454967.35206019,
    "title": "Limoncello",
    "source": "Lou",
    "time": "",
    "directions": "Peel lemons.%0D%0D%0AUse zest only.%0D%0D%0ASoak peels in alcohol for 14 days.%0D%0D%0AAfter 14 days, heat 2 liters of water and dissolve 2 cups of sugar and stir until water is clear.%0D%0D%0ALet cool.%0D%0D%0AStrain alcohol and discard peels.%0D%0D%0AMix alcohol with cooled sugar water.%0D%0D%0AKeep in freezer.%0D%0D%0ADrink in frosted glasses.%0D%0D%0AEnjoy.",
    "equipment": "",
    "prepTime": "",
    "servingIdeas": "",
    "VARCHAR": "",
    "suggestedWine": "",
    "notes": "",
    "servings": "1"
  },
  {
    "id": 133,
    "timestamp": 2454967.35260417,
    "title": "Marge's Limoncello",
    "source": "Marge",
    "time": "",
    "directions": "Wash lemons in hot water.%0D%0D%0ARemove the peel with a vegetable peeler, removing all white pith on the back of the peel by scraping with a knife and put the peels in a 4 quart Mason jar.%0D%0D%0AAdd 1 bottle of vodka and stir.%0D%0D%0ACover jar, date it, and put it in a dark cabinet at room temperature.%0D%0D%0AAfter 40 days, take out the vodka-lemon mixture.%0D%0D%0AIn a saucepan set over high heat, stir the sugar and water together and boil for 5 minutes.%0D%0D%0ALet the sugar syrup cool completely in the pan, about 10 minutes.%0D%0D%0AAdd the sugar syrup to the vodka-lemon mixtrue along with the second bottle of vodka.%0D%0D%0AStir weel to combine.%0D%0D%0AReplace the cover on the jar and note the finish date.%0D%0D%0AReturn it to the dark cabinet and store for 40 more days.%0D%0D%0AAt Day 80, remove the limoncello from the cabinet.%0D%0D%0AStrain the mixture and discard the lemon peel.%0D%0D%0APour into clean, unused bottles with caps or decorative corked bottles.%0D%0D%0AStore the bottles in the pantry, but put one bottle at a time in the freezer until ready for use.%0D%0D%0AMakes approximately 3 quarts.",
    "equipment": "",
    "prepTime": "",
    "servingIdeas": "",
    "VARCHAR": "",
    "suggestedWine": "",
    "notes": "",
    "servings": "1"
  },
  {
    "id": 134,
    "timestamp": 2454967.35368056,
    "title": "Sangria",
    "source": "Bonnie",
    "time": "",
    "directions": "Boil water, sugar, cinnamon stick, one slice of lemon and one slice of lime.  Bring to a boil and reduce heat to a simmer until mixture becomes thick.  (Do not boil more than 7 minutes.)  Add a splash of cognac or brandy and cool mixture.%0D%0D%0A %0D%0D%0ASlice one orange and cut the other in wedges.  Add slices of citrus fruits to bottom of pitcher.  Use all the slices of oranges but only 2 slices of lemon and 2 of lime.  Add apple and orange wedges.  %0D%0D%0A%0D%0D%0APour cooled syrup over fruit.  Save cinnamon stick.  Add orange juice, cognac and brandy.  Stir, cover with saran wrap and refrigerate for 30 minutes.%0D%0D%0A%0D%0D%0AAdd wine to the pitcher.  Add banana.  Stick cinnamon stick in an apple slice and add to pitcher.  Cover with saran wrap and refrigerate for 1 hour.  Remove cinnamon stick.  %0D%0D%0A%0D%0D%0AChill for 5 more hours.%0D%0D%0A%0D%0D%0AAdd coconut rum and serve.",
    "equipment": "",
    "prepTime": "",
    "servingIdeas": "",
    "VARCHAR": "",
    "suggestedWine": "",
    "notes": "",
    "servings": "1"
  },
  {
    "id": 135,
    "timestamp": 2454967.35581018,
    "title": "Apple-Pear Tortilla Roll-Ups",
    "source": "Bonnie",
    "time": "",
    "directions": "Combine apple, pear, cheese, applesauce, raisins, lemon juice, and cinnamon.%0D%0D%0ASpread the bottom section of each tortilla with one quarter of the apple-pear mixture.  Fold the bottom flap over the filling, the two side flaps inward, and then roll up each tortilla.%0D%0D%0ACalories per serving :  113%0D%0D%0AFat :  2g",
    "equipment": "",
    "prepTime": "",
    "servingIdeas": "",
    "VARCHAR": "",
    "suggestedWine": "",
    "notes": "",
    "servings": "2"
  },
  {
    "id": 136,
    "timestamp": 2454967.35710648,
    "title": "Applesauce",
    "source": "Bonnie",
    "time": "",
    "directions": "Cook apples, water and lemon juice until soft.%0D%0D%0ABeat with mixer.%0D%0D%0AAdd sugar and cinnamon.",
    "equipment": "",
    "prepTime": "",
    "servingIdeas": "",
    "VARCHAR": "",
    "suggestedWine": "",
    "notes": "",
    "servings": "1"
  },
  {
    "id": 137,
    "timestamp": 2454967.36005787,
    "title": "Rotelle-Fruit Salad",
    "source": "500 Fat Free Recipes",
    "time": "",
    "directions": "Toss cooked rotelle with all the fruit.%0D%0D%0ACombine yogurt and juice concentrate.%0D%0D%0AToss yogurt dressing with rotelle and fruit.%0D%0D%0AChill for 2 hours before serving.%0D%0D%0ACalories per serving :  183%0D%0D%0AFat :  9g",
    "equipment": "",
    "prepTime": "",
    "servingIdeas": "",
    "VARCHAR": "",
    "suggestedWine": "",
    "notes": "",
    "servings": "4"
  },
  {
    "id": 138,
    "timestamp": 2454967.36133102,
    "title": "Chicken, Shrimp and Fruit Salad",
    "source": "BH G Holiday Cooking",
    "time": "",
    "directions": "For the salad, in a large bowl combine chicken, grapes, cantaloupe, water chestnuts, and shrimp.  Cover and chill for several hours.%0D%0D%0A%0D%0D%0AFor dressing, in a small bowl mash the banana.  Stir in the mayo and lemon juice.  Cover%3B  chill several hours.%0D%0D%0A%0D%0D%0AArrange lettuce leaves on plates.  Serve salad atop lettuce.  Drizzle with dressing.  ",
    "equipment": "",
    "prepTime": "",
    "servingIdeas": "",
    "VARCHAR": "",
    "suggestedWine": "",
    "notes": "",
    "servings": "6"
  },
  {
    "id": 139,
    "timestamp": 2454967.36510417,
    "title": "Amaretto Marinade",
    "source": "Di Saronno Recipes",
    "time": "",
    "directions": "Combine all ingredients.",
    "equipment": "",
    "prepTime": "",
    "servingIdeas": "",
    "VARCHAR": "",
    "suggestedWine": "",
    "notes": "",
    "servings": "4"
  },
  {
    "id": 140,
    "timestamp": 2454967.36609954,
    "title": "Honey-Grilled Vegetables",
    "source": "Gloucester County Times",
    "time": "",
    "directions": "Cover potatoes with water and simmer 5 minutes%3B  drain.%0D%0D%0ACombine honey, wine, garlic, salt, and pepper.%0D%0D%0APlace vegetables on grill and cook for 20 - 25 minutes, turning and brushing with honey mixture every 7 -8 minutes.%0D%0D%0A%0D%0D%0AOr :%0D%0D%0A%0D%0D%0AMarinate vegetables in honey mixture.",
    "equipment": "",
    "prepTime": "",
    "servingIdeas": "",
    "VARCHAR": "",
    "suggestedWine": "",
    "notes": "",
    "servings": "4"
  },
  {
    "id": 142,
    "timestamp": 2454967.37300926,
    "title": "Jack Daniels Sauce",
    "source": "Bonnie",
    "time": "",
    "directions": "Cut off 1/2%22 top of garlic.  Cut bottom flat.  Remove papery skin.  Drizzle with oil, cover with foil and bake at 325 degrees for 1 hour.%0D%0D%0ALet cool.%0D%0D%0A%0D%0D%0ACombine water, pineapple juice, teriyaki sauce, soy sauce and brown sugar over medium heat.  Stir until mixture boils.  Reduce and simmer%0D%0D%0ASqueeze garlic and add 2 tsp. to pan.  Add remaining ingredients and stir.  Let simmer 40-50 minutes or until reduced by half.  %0D%0D%0A(Mixture should be thick and syrupy.)%0D%0D%0A",
    "equipment": "",
    "prepTime": "",
    "servingIdeas": "",
    "VARCHAR": "",
    "suggestedWine": "",
    "notes": "",
    "servings": "1"
  },
  {
    "id": 143,
    "timestamp": 2454967.37618056,
    "title": "London Broil Marinade",
    "source": "Elaine",
    "time": "",
    "directions": "Mix all ingredients except the london broil.%0D%0D%0ASlice london broil thinly.%0D%0D%0AAdd to marinade and marinate overnight.%0D%0D%0ASoak skewers overnight.%0D%0D%0AThread meat on skewers and grill.",
    "equipment": "",
    "prepTime": "",
    "servingIdeas": "",
    "VARCHAR": "",
    "suggestedWine": "",
    "notes": "",
    "servings": "1"
  },
  {
    "id": 144,
    "timestamp": 2454967.37797454,
    "title": "Mediterranean Sauce for Fish",
    "source": "Bonnie",
    "time": "",
    "directions": "Saute onions and garlic in oil.%0D%0D%0AAdd tomatoes, capers, olives and artichokes and stir.%0D%0D%0AAdd stock and season with salt, pepper and cayenne.%0D%0D%0ACook, stirring occasionally, for 15 minutes.%0D%0D%0A",
    "equipment": "",
    "prepTime": "",
    "servingIdeas": "",
    "VARCHAR": "",
    "suggestedWine": "",
    "notes": "",
    "servings": "1"
  },
  {
    "id": 145,
    "timestamp": 2454967.37864583,
    "title": "California Veal Cutlets",
    "source": "Bonnie",
    "time": "",
    "directions": "Pound each veal cutlet to 1/8%22 thickness.%0D%0D%0AHeat butter over medium-high heat and cook veal cutlets until browned on both sides, about 3 - 4 minutes.%0D%0D%0AArrange veal in pan in single layer and sprinkle with salt.%0D%0D%0ATop veal with tomato slice, avocado slice, and shredded cheese.%0D%0D%0AReduce heat to low%3B  cover pan and heat until cheese melts.%0D%0D%0AServe with lemony vinaigrette.%0D%0D%0A  Lemony Vinaigrette :Heat 3 tbs. lemon juice%0D%0D%0A                                      3 tbs. olive oil%0D%0D%0A                                      1 tbs. water%0D%0D%0A                                      1 1/2 tsp. sugar%0D%0D%0A                                      1/8 tsp. salt",
    "equipment": "",
    "prepTime": "",
    "servingIdeas": "",
    "VARCHAR": "",
    "suggestedWine": "",
    "notes": "",
    "servings": "4"
  },
  {
    "id": 146,
    "timestamp": 2454967.37913194,
    "title": "Chicken with Dried Beef",
    "source": "Bonnie",
    "time": "",
    "directions": "Mix sour cream and soup.%0D%0D%0ABreak up dried beef and spread in pan.%0D%0D%0AWrap bacon around chicken.  Place on top of dried beef.%0D%0D%0APour soup over chicken.%0D%0D%0A%0D%0D%0ABake at 300 for 2 - 3 hours.%0D%0D%0A%0D%0D%0A",
    "equipment": "",
    "prepTime": "0 :15",
    "servingIdeas": "",
    "VARCHAR": "",
    "suggestedWine": "",
    "notes": "",
    "servings": "6"
  },
  {
    "id": 147,
    "timestamp": 2454967.37997685,
    "title": "Flank Steak Deluxe",
    "source": "Bonnie",
    "time": "",
    "directions": "Cut flank steak into 4 portions.  Sprinkle both sides of each piece with flour, using a total of 1 tbs.%3B  rub to coat.  Set aside.%0D%0D%0AMelt 3 tbs. butter over medium heat.  Add onion and cook 5-7 minutes.  Transfer onion to a large bowl and reserve.%0D%0D%0AAdding a little more butter if needed, quickly brown meat over medium-high heat.  Tranfer meat to bowl with onion.  Whisk remaining 2 tbs. flour into butter.  Gradually whisk in 2 cups water, vinegar, dry mustard, paprika, thyme, cayenne, salt, and pepper.  Increase heat to high and cook, whisking until mixture bubbles, 2-4 minutes.%0D%0D%0AReturn meat and onions to sauce, cover, reduce heat and simmer until meat is tender, 30-45 minutes.  %0D%0D%0AServe immediately.",
    "equipment": "",
    "prepTime": "",
    "servingIdeas": "",
    "VARCHAR": "",
    "suggestedWine": "",
    "notes": "",
    "servings": "1"
  },
  {
    "id": 148,
    "timestamp": 2454967.38149306,
    "title": "Flank Steak Stuffed with Corn and Herbs",
    "source": "Bonnie",
    "time": "",
    "directions": "Heat 2 tbs. olive oil.  Add red pepper, scallions, garlic, and 1/2 cup onion.  Stir over medium heat 2-3 minutes.  Scrape mixture into large bowl.  Add bread crumbs, corn, basil, salt, pepper, and egg to onion mixture.  Mix together%3B  set aside.%0D%0D%0APlace flank steak on a wooden board and pound with mallet until it is 1/3 to 1/2 inch thick.  Spread stuffing evenly down middle, leaving 1%22 border along long sides.%0D%0D%0ACut five 7%22 lengths of twine.  Fold long sides  together, overlapping them to over stuffing.  Tie with string.  Rub with flour and season with salt and pepper.%0D%0D%0AHeat remaining 2 tbs. olive oil.  Add meat and brown on all sides over medium-high heat, about 7 minutes.  Carefully remove to platter.  Add remaining 1 cup onions and stir over medium heat until softened, 2-4 minutes.  Stir in tomatoes, wine, and broth%3B  bring to boil.  Add meat, seam side up.%0D%0D%0ACover and bake for 1 hour at 350 degrees.  Baste, cover again and reduce temperature to 325 degrees.  Braise until meat is tender, about 1 hour more.%0D%0D%0ATransfer meat to carving board.  Carefully remove string.  Carve into 1/2%22 slices.  Spoon sauce over meat and serve.",
    "equipment": "",
    "prepTime": "",
    "servingIdeas": "",
    "VARCHAR": "",
    "suggestedWine": "",
    "notes": "",
    "servings": "4"
  },
  {
    "id": 149,
    "timestamp": 2454967.38252315,
    "title": "Flank Steak with Horseradish Sauce",
    "source": "Bonnie",
    "time": "",
    "directions": "Score the surface of the steak with shallow diagonal cuts at 1%22 intervals, making diamond shapes.  Repeat on other side.%0D%0D%0AIn a large plastic bag, combine the next five ingredients.%0D%0D%0AAdd steak and seal bag.  Refrigerate overnight.%0D%0D%0ACombine sauce ingredients%3B cover and refrigerate.%0D%0D%0AGrill steak.  Thinly slice across the grain.",
    "equipment": "",
    "prepTime": "",
    "servingIdeas": "",
    "VARCHAR": "",
    "suggestedWine": "",
    "notes": "",
    "servings": "4"
  },
  {
    "id": 150,
    "timestamp": 2454967.38363426,
    "title": "Ham and Vegetables with Pasta",
    "source": "BH G New Dieter's Cookbook",
    "time": "",
    "directions": "Cook pasta.  Drain and set aside.%0D%0D%0AIn a medium saucepan combine zucchini, onion, and 1/4 cup of water.  Bring to boiling%3B  reduce heat.  Simmer, covered, for 4 -5 minutes or until vegetables are crisp-tender%3B  drain well.  Return to pan. %0D%0D%0AFor sauce, in a small pan combine cornstarch, basil, marjoram, a little of the evap. milk, and 1/8 tsp. of pepper.  Stir in remaining milk all at once.  Cook and stir until thickened and bubbly.  Stir sauce, ham and pasta into vegetables.  Heat through.  %0D%0D%0A%0D%0D%0APer serving :  291 calories, 3 g. fat",
    "equipment": "",
    "prepTime": "",
    "servingIdeas": "",
    "VARCHAR": "",
    "suggestedWine": "",
    "notes": "",
    "servings": "2"
  },
  {
    "id": 151,
    "timestamp": 2454967.38444444,
    "title": "Ham Rolls",
    "source": "Weight Watchers Favorites",
    "time": "",
    "directions": "Stretch pizza dough into 12 x 10%22 rectangle.%0D%0D%0AIn small bowl whisk together yogurt, mustard, and horseradish.%0D%0D%0ASpread mixture evenly over dough, leaving 1 1/2%22 border.%0D%0D%0AArrange ham over dough%3B sprinkle with cheese and onion.%0D%0D%0AStarting from wide end, roll dough jelly-roll fashion to enclose filling.%0D%0D%0APinch seam to seal.%0D%0D%0ASpray baking sheet with Pam.%0D%0D%0AArrange roll seam-side down on sheet.%0D%0D%0ABake at 425 for 15, until golden brown.%0D%0D%0ALet cool 10 minutes before cuttin into 10 slices.%0D%0D%0A%0D%0D%0APer serving :  137 Calories%0D%0D%0A%0D%0D%0AEach serving provides :  1 protein, 1 bread, 2 optional calories",
    "equipment": "",
    "prepTime": "",
    "servingIdeas": "",
    "VARCHAR": "",
    "suggestedWine": "",
    "notes": "",
    "servings": "10"
  },
  {
    "id": 152,
    "timestamp": 2454967.38541667,
    "title": "Hot Bacon and Chicken Salad",
    "source": "BH G Stir Fry Recipes",
    "time": "",
    "directions": "For dressing :  Stir together Italian dressing and dry mustard.%0D%0D%0A%0D%0D%0AAdd bacon to wok and stir-fry until crisp.%0D%0D%0ARemove from wok and drain.%0D%0D%0ADrain fat from wok.%0D%0D%0A%0D%0D%0AAdd oil to wok.  Add mushrooms and green onion.  Stir-fry 1 - 2 minutes.  Remove vegetables from wok.%0D%0D%0A%0D%0D%0AAdd chicken to wok.  Stir-fry 2 - 3 minutes.  Add mushroom mixture and dressing mixture to wok.  Heat mixture through.%0D%0D%0A%0D%0D%0APlace greens and bacon in a large salad bowl.  Pour hot mixture over greens and toss to coat.  Top with egg wedges.  Serve immediately.",
    "equipment": "",
    "prepTime": "",
    "servingIdeas": "",
    "VARCHAR": "",
    "suggestedWine": "",
    "notes": "",
    "servings": "4"
  },
  {
    "id": 153,
    "timestamp": 2454967.38664352,
    "title": "Hungarian Goulash",
    "source": "Elaine",
    "time": "",
    "directions": "Melt butter.%0D%0D%0AAdd beef, onion and garlic.%0D%0D%0AAdd catsup, worcestershire sauce, sugar, salt, paprika, mustard and cayenne.%0D%0D%0AAdd 1 1/2 cups of water.%0D%0D%0ACover and simmer 2 hours.%0D%0D%0ABlend flour and 1/4 cup of water.%0D%0D%0AAdd to meat.  Boil and stir.%0D%0D%0AServe over noodles.",
    "equipment": "",
    "prepTime": "",
    "servingIdeas": "",
    "VARCHAR": "",
    "suggestedWine": "",
    "notes": "",
    "servings": "1"
  },
  {
    "id": 154,
    "timestamp": 2454967.3875463,
    "title": "Meat Loaf with Mushrooms and Sour Cream",
    "source": "Bonnie",
    "time": "",
    "directions": "Saute mushrooms in butter - 4 minutes.%0D%0D%0ASprinkle with flour and nutmeg - Cook 1 minute.%0D%0D%0ARemove from heat and add sour cream.%0D%0D%0ACombine bread crumbs, catsup, eggs, w. sauce, beef, salt and pepper and mix well.%0D%0D%0ASpoon half of the mixture into pan.%0D%0D%0ATop with half of mushroom mixture.%0D%0D%0ACover with remaining meat.%0D%0D%0ABake at 350 for 45 minutes.%0D%0D%0A%0D%0D%0AThin remaining mushroom/sour cream mixture with milk, if necessary.",
    "equipment": "",
    "prepTime": "",
    "servingIdeas": "",
    "VARCHAR": "",
    "suggestedWine": "",
    "notes": "",
    "servings": "1"
  },
  {
    "id": 155,
    "timestamp": 2454967.38913194,
    "title": "Mushroom-Stuffed Pork Chops with Escarole and Onion Sauce.",
    "source": "Bonnie",
    "time": "",
    "directions": "Heat 2 tbs. oil and add mushrooms, carrots, garlic, shallots, and soy sauce.  Cover and cook over medium-low heat 3-4 minutes.  %0D%0D%0AStir in bread crumbs, butter, parsley, tarragon, salt, and pepper.  %0D%0D%0ARemove from heat and let stuffing cool.%0D%0D%0ADivide stuffing among chops, firmly packing into pocket.  %0D%0D%0ACoat each chop with flour.%0D%0D%0ALightly coat skillet with oil and heat over medium-high heat.%0D%0D%0ABrown chops on both sides, 3-4 minutes.  Remove from pan.%0D%0D%0AAdd broth and cook over high heat until bubbling.  Stir in onion.  Return chops to pan and baste with sauce.  Sprinkle generously with salt and pepper.  Add bay leaf and clove.  %0D%0D%0ACover with foil and place in oven.%0D%0D%0ABraise for 45 minutes.%0D%0D%0AStir in escarole and cook 30 minutes more.%0D%0D%0ATransfer chops to platter.  Heat sauce over high heat and cook 1-2 minutes to reduce and thicken sauce.  Remove bay leaf.",
    "equipment": "",
    "prepTime": "",
    "servingIdeas": "",
    "VARCHAR": "",
    "suggestedWine": "",
    "notes": "",
    "servings": "4"
  },
  {
    "id": 156,
    "timestamp": 2454967.39034722,
    "title": "Peanut Sate",
    "source": "BH G New Dieter's Cookbook",
    "time": "",
    "directions": "Cut beef into 1 1/2 %22 pieces.  Place in a plastic bag in a large bowl.%0D%0D%0AFor marinade, in a food processor bowl place onion, 2 tbs. soy sauce, lime juice, sugar, cumin, and garlic.  Cover and process till smooth.  Pour marinade over meat in bag.  Close bag and refrigerate for 4 hours, turning bag occasionally.  Drain meat, reserving marinade.%0D%0D%0AFor sauce, in a pan stir broth into peanut butter.  Stir in molasses, 1 tsp. soy sauce, red pepper, and garlic.  Cook and stir until heated through.  Keep warm.%0D%0D%0AThread meat onto skewers.  Broil 3 -4%22 from heat for 7 - 9 minutes or to desired doneness, turning occasionally and brushing with marinade.  Serve sauce with meat for dipping.%0D%0D%0A%0D%0D%0ACalories per serving :  222%0D%0D%0AFat :  11g%0D%0D%0A%0D%0D%0A5 points",
    "equipment": "",
    "prepTime": "",
    "servingIdeas": "",
    "VARCHAR": "",
    "suggestedWine": "",
    "notes": "",
    "servings": "2"
  },
  {
    "id": 157,
    "timestamp": 2454967.39123843,
    "title": "Pork and Apple Stir-Fry",
    "source": "BH G Stir Fry Recipes",
    "time": "",
    "directions": "For sauce :  Stir together the apple juice, cornstarch, bouillon, and cinnamon.  Set aside.%0D%0D%0A%0D%0D%0AAdd oil to wok.  Add half the pork and stir-fry for 2-3 minutes.  Remove from wok and repeat with remaining pork.  %0D%0D%0A%0D%0D%0AReturn all pork to wok.  Push meat from center of wok.  Stir sauce and add to center of wok.  Cook and stir until thickened and bubbly.%0D%0D%0A%0D%0D%0AAdd apples, green onions, and raisins.  Toss together and cook for 1 minute more.  Serve with rice.  ",
    "equipment": "",
    "prepTime": "",
    "servingIdeas": "",
    "VARCHAR": "",
    "suggestedWine": "",
    "notes": "",
    "servings": "4"
  },
  {
    "id": 158,
    "timestamp": 2454967.39212963,
    "title": "Pork Chop Barbecue",
    "source": "Better Homes and Gardens",
    "time": "",
    "directions": "In a small saucepan combine all ingredients, except pork chops, and bring to boiling.  Add mixture and pork chops to crock pot.",
    "equipment": "",
    "prepTime": "",
    "servingIdeas": "",
    "VARCHAR": "",
    "suggestedWine": "",
    "notes": "",
    "servings": "6"
  },
  {
    "id": 159,
    "timestamp": 2454967.3927662,
    "title": "Pork Chop Casserole",
    "source": "Bonnie",
    "time": "",
    "directions": "Mix 2 tbs. flour with 1/2 tsp. salt.%0D%0D%0ACoat pork chops with flour mixture.%0D%0D%0AIn 2 tbs. oil, brown chops and remove from pan.%0D%0D%0AAdd 1 tbs. oil and cook onions and 1/2 tsp. salt.%0D%0D%0AAdd apple-cranberry juice and 1 tbs. flour.%0D%0D%0AArrange potatoes in a 9x13 pan and sprinkle with salt.%0D%0D%0ATop with onions and juice.%0D%0D%0ATuck pork chops and apples into onions.%0D%0D%0ACover with foil and bake at 350 for 1 hour.%0D%0D%0ABrush pork with apple jelly and bake, covered, 30 minutes more.",
    "equipment": "",
    "prepTime": "",
    "servingIdeas": "",
    "VARCHAR": "",
    "suggestedWine": "",
    "notes": "",
    "servings": "4"
  },
  {
    "id": 160,
    "timestamp": 2454967.39357639,
    "title": "Pork Chops and Mushrooms in Mustard Cream Sauce",
    "source": "Bonnie",
    "time": "",
    "directions": "Sprinkle pork chops with flour.%0D%0D%0AHeat oil over high heat.%0D%0D%0ABrown chops on both sides, 3-4 minutes.  Transfer to a platter.%0D%0D%0AReduce heat to medium and add more oil if necessary.%0D%0D%0AAdd mushrooms, shallot, and garlic, cover and cook over medium heat until mushrooms release their liquid, 3-4 minutes.  %5C%0D%0D%0AStir in broth, salt, and pepper.%0D%0D%0AWhen liquid begins to bubble, add pork chops and baste well.%0D%0D%0ACover pan and braise in oven at 325 degrees until chops are tender, about 1 1/4 hours.  Transfer chops to platter.  %0D%0D%0AAdd sour cream and mustard to pan.  Whisk over medium heat until sauce is blended and hot%3B  do not boil.  ",
    "equipment": "",
    "prepTime": "",
    "servingIdeas": "",
    "VARCHAR": "",
    "suggestedWine": "",
    "notes": "",
    "servings": "2"
  },
  {
    "id": 161,
    "timestamp": 2454967.39472222,
    "title": "Pork Chops in Plum Sauce",
    "source": "Bonnie",
    "time": "",
    "directions": "Blend together applesauce and spices.  Add vinegar, juices, honey, horseradish, and plums.  Blend well and set aside.%0D%0D%0ASeason pork chops with salt and pepper.%0D%0D%0AHeat oil over high heat.  Brown chops well on both sides.  Transfer to baking dish just large enough to hold them in a single layer.%0D%0D%0AAdd 1 cup water to pan and deglaze over high heat.  Boil until water is reduced by half, 3-5 minutes.  Add applesauce mixture and stir until sauce comes to a boil.  Pour over chops.%0D%0D%0ATightly cover and bake at 350 degrees for 1 1/4 hours.%0D%0D%0A",
    "equipment": "",
    "prepTime": "",
    "servingIdeas": "",
    "VARCHAR": "",
    "suggestedWine": "",
    "notes": "",
    "servings": "4"
  },
  {
    "id": 162,
    "timestamp": 2454967.39627315,
    "title": "Pork Chops Stuffed with Sun-Dried Tomatoes and Ham",
    "source": "Bonnie",
    "time": "",
    "directions": "Heat oil and add sun-dried tomatoes, garlic, and shallot.  Cool 2-3 minutes.  Add prosciutto, butter, parsley, basil, and oregano.  Stir until butter melts.  Remove from heat.%0D%0D%0AAdd bread crumbs and season with salt and pepper.  %0D%0D%0ADivide stuffing among chops and pack into pocket.%0D%0D%0ACoat each chop with flour.%0D%0D%0AAdd 2 tbs. oil to pan and heat over medium-high heat.  Brown chops on both sides, about 2 minutes.%0D%0D%0AAdd 2 1/2 cups wine to pan and cook over high heat until bubbling.  Cover pan and bake in 325 degree oven for 1 1/4 hours. %0D%0D%0ARemove meat from pan.  Over high heat, whisk in tomato paste.  Simmer briskly for 2 minutes, until slightly thickened.",
    "equipment": "",
    "prepTime": "",
    "servingIdeas": "",
    "VARCHAR": "",
    "suggestedWine": "",
    "notes": "",
    "servings": "4"
  },
  {
    "id": 163,
    "timestamp": 2454967.39771991,
    "title": "Pork Loin with Sweet Potatoes and Prunes",
    "source": "Bonnie",
    "time": "",
    "directions": "Marinate pork, prunes, wine, Calvados, onion, garlic, sage, and cloves in plastic bag in refrigerator for 3-6 hours.%0D%0D%0AAdd sweet potatoes to boiling water%3B  parboil for 5 minutes.  Drain thoroughly.  Arrange in roasting pan.%0D%0D%0ARemove pork from marinade and pat dry.  Reserve marinade.  Slice evenly halfway into center so that meat opens like a book.%0D%0D%0ATransfer prunes to a mixing bowl.  Blend with parsley, sage, cayenne, and shallots.  Stuff roast with mixture.  Close roast with a few skewers and tie with string at 3%22 intervals.%0D%0D%0AHeat oil over high heat.  Sear pork on all sides, about 10 minutes total.%0D%0D%0APlace meat, slit side up, over sweet potatoes.  Pour marinade over meat.  Season generously with salt and pepper.  Add 1/2 cup broth.  Tightly cover and roast at 325 degrees for 1 1/4 hours.%0D%0D%0ASlice pork into 1/2%22 slices and serve with pan juices.",
    "equipment": "",
    "prepTime": "",
    "servingIdeas": "",
    "VARCHAR": "",
    "suggestedWine": "",
    "notes": "",
    "servings": "1"
  },
  {
    "id": 164,
    "timestamp": 2454967.39859954,
    "title": "Prosciutto and Goat Cheese Timbales with Greens",
    "source": "Karen Carola",
    "time": "",
    "directions": "Line six 2/3 cup ramekins with plastic wrap, leaving overhang.%0D%0D%0ALine each ramekin with 2 slices of prosciutto, overlapping in center and patching any holes with extra prosciutto.  Mix goat cheese, cream cheese, and 1/4 cup green onions.  Season with pepper.  %0D%0D%0ADivide equally into 6 ramekins.  Fold prosciutto to close, then fold plastic.  Press to compress.  Refrigerate overnight.%0D%0D%0A%0D%0D%0AInvert ramekins to release timbales.  Peel plastic.  Heat 1 tbs. oil over medium heat.  Add timbales bottom side down.  Cover and cook about 5 minutes.  Transfer to plate%3B  let stand to set up.%0D%0D%0A%0D%0D%0ACombine vinegar, and shallots in small bowl.  Slowly whisk in 1/2 cup oil.  Season with salt and pepper.%0D%0D%0A%0D%0D%0ACombine greens, onions, and dressing in large bowl and toss.%0D%0D%0ADivide salad on plates and top with timbale.  Drizzle remaining dressin on top.",
    "equipment": "",
    "prepTime": "",
    "servingIdeas": "",
    "VARCHAR": "",
    "suggestedWine": "",
    "notes": "",
    "servings": "6"
  },
  {
    "id": 165,
    "timestamp": 2454967.39959491,
    "title": "Roast Beef Wellington",
    "source": "Bonnie",
    "time": "",
    "directions": "Melt butter.  Add mushrooms and parsley.  Saute 3-4 minutes.  Let cool and drain.%0D%0D%0AToss cheese, beef, horseradish and mushroom mixture.%0D%0D%0ADivide into 6 portions.%0D%0D%0ASpoon 1 portion of filling in center of pastry.  Fold up corners of pastry pinching to seal.%0D%0D%0ADO NOT COMPRESS THE FILLING.%0D%0D%0APlace on greased baking sheet seam side down.%0D%0D%0ABake at 425 for 25 - 30 minutes.",
    "equipment": "",
    "prepTime": "",
    "servingIdeas": "",
    "VARCHAR": "",
    "suggestedWine": "",
    "notes": "",
    "servings": "1"
  },
  {
    "id": 166,
    "timestamp": 2454967.40043981,
    "title": "Spare Ribs Cantonese",
    "source": "Bonnie",
    "time": "",
    "directions": "Place ribs, meaty side down, in pan.  Bake at 450 for 45 minutes.%0D%0D%0ADrain and turn ribs.%0D%0D%0ALower heat to 350 and cook 45 minutes more.%0D%0D%0ADrain.%0D%0D%0AMix remaining ingredients and pour over ribs.%0D%0D%0ACook 30 minutes more, basting frequently.%0D%0D%0A ",
    "equipment": "",
    "prepTime": "",
    "servingIdeas": "",
    "VARCHAR": "",
    "suggestedWine": "",
    "notes": "",
    "servings": "4"
  },
  {
    "id": 167,
    "timestamp": 2454967.40140046,
    "title": "Stir Fry Sweet Potatoes and Pork",
    "source": "Bonnie",
    "time": "",
    "directions": "Combine brown sugar, cornstarch, soy sauce, sherry, ginger and water.%0D%0D%0ASeason pork with pepper.%0D%0D%0AHeat 2 tbs. oil.  Stir fry pork for 5 minutes.%0D%0D%0ARemove meat.%0D%0D%0AAdd sweet potatoes and pepper.  Stir fry for 2 minutes.%0D%0D%0AReturn pork.%0D%0D%0AAdd snow peas,  sauce and scallions.",
    "equipment": "",
    "prepTime": "",
    "servingIdeas": "",
    "VARCHAR": "",
    "suggestedWine": "",
    "notes": "",
    "servings": "1"
  },
  {
    "id": 168,
    "timestamp": 2454967.40255787,
    "title": "Stuffed Braciole",
    "source": "Bonnie",
    "time": "",
    "directions": "Combine grated cheese, bread crumbs, egg, 1 clove chopped garlic and olive oil.%0D%0D%0APound steak to 1/2 inch rectangle.%0D%0D%0ARub meat with olive oil and season with salt and pepper.%0D%0D%0ALayer with ham, salami, mortadella and provolone, leaving a 1/2 inch border.  Spread with bread crumb mixture.%0D%0D%0ARoll up jelly roll style to enclose the filling and tie.%0D%0D%0ACook bacon over medium heat until fat is rendered.%0D%0D%0AAdd the 2 cloves of smashed garlic and cook until softened.%0D%0D%0ARemove onions and garlic and brown meat on all sides.%0D%0D%0AAdd the wine and cook until reduced by half, about 10 minutes.%0D%0D%0AAdd tomatoes and onion-bacon mixture.%0D%0D%0ACover and simmer until cooked through, about 1 hour.%0D%0D%0ATransfer to a platter and let stand for 15 minutes.%0D%0D%0ARemove string and thinly slice.%0D%0D%0AServe with sauce and grated cheese.",
    "equipment": "",
    "prepTime": "",
    "servingIdeas": "",
    "VARCHAR": "",
    "suggestedWine": "",
    "notes": "",
    "servings": "1"
  },
  {
    "id": 169,
    "timestamp": 2454967.40328704,
    "title": "Teriyaki Pork Roast",
    "source": "Bonnie",
    "time": "",
    "directions": "Combine first 7 ingredients in crock pot.%0D%0D%0AAdd roast.%0D%0D%0ACover and cook on low for 7-8 hours or until thermometer reads 160 degrees.%0D%0D%0AThicken gravy.",
    "equipment": "",
    "prepTime": "",
    "servingIdeas": "",
    "VARCHAR": "",
    "suggestedWine": "",
    "notes": "",
    "servings": "1"
  },
  {
    "id": 170,
    "timestamp": 2454970.37790509,
    "title": "Spice Muffins",
    "source": "Bonnie",
    "time": "",
    "directions": "Mix all ingredients.%0D%0D%0ADivide into 24 muffins.%0D%0D%0ABake at 350 degrees for 20 minutes.",
    "equipment": "",
    "prepTime": "",
    "servingIdeas": "",
    "VARCHAR": "",
    "suggestedWine": "",
    "notes": "",
    "servings": "1"
  },
  {
    "id": 171,
    "timestamp": 2454970.37913194,
    "title": "Eggplant Lasagna",
    "source": "Weight Watchers Favorites",
    "time": "",
    "directions": "Pare and slice eggplant crosswise into 1/4%22 slices.  Steam eggplant on rack over simmering water until very tender%3B  set aside on paper towels to drain.%0D%0D%0AIn small bowl combine ricotta, 2 oz. mozzarella, egg white, parlsey, and garlic powder until well blended.%0D%0D%0ASpray an 8%22 square pan with cooking spray.%0D%0D%0ACut lasagna noodles in half crosswise.%0D%0D%0AArrange 1/3 of lasagna noodles in pan%3B  layer with 1/3 of ricotta mixture, eggplant slices, and crushed tomatoes.  Repeat layering twice%3B  sprinkle remaining mozzarella on top.%0D%0D%0ABake at 350 for 20 - 25 minutes until heated through and cheese is lightly browned.%0D%0D%0A%0D%0D%0APer serving :  246 calories, 9g fat%0D%0D%0A%0D%0D%0AEach serving provides :  1 protein, 3/4 vegetables, 1 bread, 5 opt. cal.",
    "equipment": "",
    "prepTime": "",
    "servingIdeas": "",
    "VARCHAR": "",
    "suggestedWine": "",
    "notes": "",
    "servings": "2"
  },
  {
    "id": 172,
    "timestamp": 2454970.40106481,
    "title": "Linguine with Scallops",
    "source": "BH G New Dieter's Cookbook",
    "time": "",
    "directions": "Heat butter and oil over medium-high heat.  Add scallops%3B  cook and stir about 2 minutes or until opaque.  Remove scallops with slotted spoon leaving juices in pan.%0D%0D%0AStir broth, vermouth or white wine, and lemon juice into pan.  Bring to boiling.  Boil for 10 - 12 minutes or until liquid is reduce to about 1 cup.  Stir in onion, parsley, dill, and pepper.  Reduce heat and simmer uncovered, for 1 minute.  Add scallops, stirring just until heated through.  Pour over linguine and toss gently.  %0D%0D%0ACalories per serving :  362%0D%0D%0AFat :  4g",
    "equipment": "",
    "prepTime": "",
    "servingIdeas": "",
    "VARCHAR": "",
    "suggestedWine": "",
    "notes": "",
    "servings": "6"
  },
  {
    "id": 173,
    "timestamp": 2454970.40178241,
    "title": "Manicotti Shells",
    "source": "Bonnie",
    "time": "",
    "directions": "Mix all ingredients in a blender of food processor.%0D%0D%0A(Mixture will be thin.)%0D%0D%0ARub 6%22 pan with olive oil and heat over medium - medium high heat.%0D%0D%0ATilt pan and pour 1/4 cup of batter into pan.%0D%0D%0ATilt pan to form a circle.%0D%0D%0AWhen batter is set, turn pasta but do not brown.%0D%0D%0ARemove and cool.%0D%0D%0AMakes 12-14 manicotti shells.",
    "equipment": "",
    "prepTime": "",
    "servingIdeas": "",
    "VARCHAR": "",
    "suggestedWine": "",
    "notes": "",
    "servings": "14"
  },
  {
    "id": 174,
    "timestamp": 2454970.40299769,
    "title": "Pasta Primavera",
    "source": "Weight Watchers",
    "time": "",
    "directions": "In a frying pan, cook the first 5 ingredients.%0D%0D%0AAdd tomatoes, salt and pepper and cook for 10 minutes.%0D%0D%0AAdd the remaining ingredients and cook until heated through.",
    "equipment": "",
    "prepTime": "",
    "servingIdeas": "",
    "VARCHAR": "",
    "suggestedWine": "",
    "notes": "",
    "servings": "1"
  },
  {
    "id": 175,
    "timestamp": 2454970.4121875,
    "title": "Scallops with Green Pesto",
    "source": "Weight Watchers Favorites",
    "time": "",
    "directions": "To prepare pesto : In blender, combine scallions, parsley, basil, cheese, garlic, and oil until just blended%3B set aside.%0D%0D%0AIn large skillet, bring 1 cup water to a boil%3B  add scallops.  Reduce heat to medium and cook, stirring occasionally, until scallops just turn opaque, about 3 minutes.%0D%0D%0AWith slotted spoon, transfer scallops to large serving bowl%3B  reserve liquid.  Add remaining ingredients, pesto, and 1/2 cup reserved liquid.  Toss well to combine.  Serve immediately.%0D%0D%0A%0D%0D%0APer serving :  294 calories%0D%0D%0A%0D%0D%0AEach serving provides :%0D%0D%0A1 1/2 fats, 1 1/2 proteins, 1 1/2 breads, 1/2 vegetable, 30 optional calories",
    "equipment": "",
    "prepTime": "",
    "servingIdeas": "",
    "VARCHAR": "",
    "suggestedWine": "",
    "notes": "",
    "servings": "6"
  },
  {
    "id": 176,
    "timestamp": 2454970.41428241,
    "title": "Spaghetti Pie",
    "source": "Weight Watchers Quick Start",
    "time": "",
    "directions": "In medium bowl combine all ingredients for crust.  Spray a 9%22 pie plate with Pam.  Spread spaghetti mixture over bottom and up sides of plate.%0D%0D%0ASpread ricotta over crust and set aside.%0D%0D%0AHeat butter and saute onion, pepper, and garlic.%0D%0D%0AAdd beef and tomato puree.  Spoon mixture over ricotta cheese.%0D%0D%0ABake at 350 for 20 - 25 minutes.  Sprinkle with mozzarella and bake 5 minutes more. %0D%0D%0A%0D%0D%0AEach serving provides :  3 proteins, 1 bread, 1 vegetable, 1 1/2 fats,                                    20 optional calories%0D%0D%0A",
    "equipment": "",
    "prepTime": "",
    "servingIdeas": "",
    "VARCHAR": "",
    "suggestedWine": "",
    "notes": "",
    "servings": "4"
  },
  {
    "id": 177,
    "timestamp": 2454970.41929398,
    "title": "Apricot Poppy Chicken",
    "source": "Bonnie",
    "time": "",
    "directions": "Season chicken with salt and pepper.%0D%0D%0AHeat oil and cook chicken until browned.%0D%0D%0AAdd shallots and cook for 3 minutes.%0D%0D%0AAdd the vinegar and cook until slightly reduced, about 1 minute.%0D%0D%0AAdd the chicken broth, apricot preserves, honey and poppy seeds and simmer over medium-low heat, stirring.%0D%0D%0AStir in 1 tablespoon of butter..%0D%0D%0AAdd salt and pepper to taste.%0D%0D%0A",
    "equipment": "",
    "prepTime": "",
    "servingIdeas": "",
    "VARCHAR": "",
    "suggestedWine": "",
    "notes": "",
    "servings": "1"
  },
  {
    "id": 178,
    "timestamp": 2454970.42351852,
    "title": "Chicken and Rice Salad",
    "source": "Bonnie",
    "time": "",
    "directions": "Mix all ingredients and serve chilled.",
    "equipment": "",
    "prepTime": "",
    "servingIdeas": "",
    "VARCHAR": "",
    "suggestedWine": "",
    "notes": "",
    "servings": "1"
  },
  {
    "id": 179,
    "timestamp": 2454970.43165509,
    "title": "Chicken and Zucchini in Mustard Sauce",
    "source": "BH G New Dieter's Cookbook",
    "time": "",
    "directions": "For sauce, in  a small bowl stir together yogurt and mustard.  Set aside.%0D%0D%0ASpray skillet with nonstick spray coating.  Heat over medium-high heat.  Stir-fry carrot and garlic 1 minute.  Add zucchini%3B  stir-fry about 3 minutes more or until vegetables are crisp-tender.  Remove from pan.%0D%0D%0AAdd oil.  Stir-fry chicken for 2 - 3 minutes or until no longer pink.  Return vegetables to pan.  Add sauce to pan%3B  toss to coat all ingredients with sauce.  Cook and stir for 1 minute or until heated through.%0D%0D%0A%0D%0D%0APer serving :  218 calories, 8g fat%0D%0D%0A%0D%0D%0A5 points",
    "equipment": "",
    "prepTime": "",
    "servingIdeas": "",
    "VARCHAR": "",
    "suggestedWine": "",
    "notes": "",
    "servings": "2"
  },
  {
    "id": 180,
    "timestamp": 2454970.43335648,
    "title": "Chicken Marsala",
    "source": "Bonnie",
    "time": "",
    "directions": "Season chicken with salt and pepper and dip in flour.%0D%0D%0AMelt 2 tbs.butter with oil.%0D%0D%0AAdd chicken when foam subsides.%0D%0D%0ABrown 3 min. on each side.%0D%0D%0ATransfer chicken to plate.%0D%0D%0AAdd wine and broth and bring to boil.%0D%0D%0AReturn chicken to pan.%0D%0D%0ACover and simmer 10 - 15 minutes basting occasionally.",
    "equipment": "",
    "prepTime": "",
    "servingIdeas": "",
    "VARCHAR": "",
    "suggestedWine": "",
    "notes": "",
    "servings": "1"
  },
  {
    "id": 181,
    "timestamp": 2454970.43511574,
    "title": "Chicken Salad in the Tropics",
    "source": "Bonnie",
    "time": "",
    "directions": "Whisk orange juice, pineapple juice, lime juice, soy sauce, olive oil, vegetable oil, honey and pepper.%0D%0D%0ADivide the salad greens on plates. Top with fruit, beans, cheese and chicken.  %0D%0D%0ASprinkle with dressing.",
    "equipment": "",
    "prepTime": "",
    "servingIdeas": "",
    "VARCHAR": "",
    "suggestedWine": "",
    "notes": "",
    "servings": "4"
  },
  {
    "id": 182,
    "timestamp": 2454970.43575231,
    "title": "Chicken with Apples",
    "source": "Bonnie",
    "time": "",
    "directions": "Season chicken with salt, pepper and garlic powder.%0D%0D%0ADredge in flour.%0D%0D%0ASaute chicken in butter.%0D%0D%0AFlambe in brandy.  Remove and keep warm.%0D%0D%0APut apples in pan.%0D%0D%0AAdd cream, dry mustard, salt and pepper and reduce to 1 cup.%0D%0D%0AAdd chicken and turn to coat.%0D%0D%0ASprinkle with almonds.",
    "equipment": "",
    "prepTime": "",
    "servingIdeas": "",
    "VARCHAR": "",
    "suggestedWine": "",
    "notes": "",
    "servings": "1"
  },
  {
    "id": 183,
    "timestamp": 2454970.43662037,
    "title": "Creamy Parmesan Chicken",
    "source": "Bonnie",
    "time": "",
    "directions": "Combine bread crumbs, parmesan cheese, oregano, pepper and garlic powder.%0D%0D%0ADip chicken in mixture.%0D%0D%0AArrange skin-side-up in glass baking dish.%0D%0D%0AMicrowave-bake at 425 for 20 minutes.%0D%0D%0ACombine soup, milk and sour cream and spoon over chicken.%0D%0D%0ASprinkle with parmesan cheese.%0D%0D%0AMicrowave-bake 5 - 10 minutes more.%0D%0D%0A",
    "equipment": "",
    "prepTime": "",
    "servingIdeas": "",
    "VARCHAR": "",
    "suggestedWine": "",
    "notes": "",
    "servings": "4"
  },
  {
    "id": 184,
    "timestamp": 2454970.43746528,
    "title": "Crunchy Baked Chicken",
    "source": "Weight Watchers",
    "time": "",
    "directions": "Combine cornflakes, sesame seeds, salt and pepper.%0D%0D%0ABrush chicken with milk and coat with cornflake mixture.%0D%0D%0ADot with margarine.%0D%0D%0ABake at 400 for 15 minutes.%0D%0D%0A",
    "equipment": "",
    "prepTime": "",
    "servingIdeas": "",
    "VARCHAR": "",
    "suggestedWine": "",
    "notes": "",
    "servings": "1"
  },
  {
    "id": 185,
    "timestamp": 2454970.43893519,
    "title": "Sesame Chicken",
    "source": "Bonnie",
    "time": "",
    "directions": "Beat egg.  Add chicken strips.  Stir in cornstarch.%0D%0D%0AFry chicken in oil.%0D%0D%0AFor the sauce, heat oil over medium heat.  Stir in remaining ingredients.  Boil sauce until just beginning to caramelize (1-2 min.)%0D%0D%0AAdd chicken and toss to coat.  Sprinkle with 2 tbs. of sesame seeds and green onions.",
    "equipment": "",
    "prepTime": "",
    "servingIdeas": "",
    "VARCHAR": "",
    "suggestedWine": "",
    "notes": "",
    "servings": "4"
  },
  {
    "id": 186,
    "timestamp": 2454970.44021991,
    "title": "Spicy Stir-Fry Peanut Chicken",
    "source": "Bonnie",
    "time": "",
    "directions": "Cut chicken into 1 inch chunks and toss with cornstarch.%0D%0D%0AStir fry chicken in 1 1/2 tbs. of oil until lightly browned.(3 min.) Remove and reserve.%0D%0D%0AAdd the remaining oil and stir-fry onion about 45 seconds.  Add the red bell pepper and cook 30 seconds more.  Add the crushed red pepper and cook 5 seconds more.%0D%0D%0AAdd the lemon juice, soy sauce, peanut butter and sugar.  Add the chicken broth gradually, stirring constantly until the sauce is smooth. Return the chicken and heat through.%0D%0D%0AStir in the peanuts, scallions, and garlic.%0D%0D%0AServe with rice.",
    "equipment": "",
    "prepTime": "",
    "servingIdeas": "",
    "VARCHAR": "",
    "suggestedWine": "",
    "notes": "",
    "servings": "4"
  },
  {
    "id": 187,
    "timestamp": 2454970.46459491,
    "title": "Stuffed Chicken Rolls",
    "source": "Weight Watchers Quick Start",
    "time": "",
    "directions": "Heat butter and cook onion and garlic.  %0D%0D%0AAdd mushrooms, spinach, cheese, bread crumbs, salt, and pepper.%0D%0D%0ASet aside and cool.%0D%0D%0ASpread mixture on chicken, leaving 1%22 border on all sides.%0D%0D%0ARoll chicken lengthwise and secure with skewers.%0D%0D%0APut in pan.%0D%0D%0AAdd wine and chicken broth.%0D%0D%0ACover pan with foil and bake for 40 minutes at 350 degrees.%0D%0D%0ARemove foil and continue baking for 10 - 15 minutes more.%0D%0D%0AIn a small saucepan combine water and cornstarch and thicken gravy.%0D%0D%0APour over chicken and serve.%0D%0D%0A%0D%0D%0AEach serving provides :  3 proteins, 1 1/4 veg., 1/2 fat, %0D%0D%0A221 calories",
    "equipment": "",
    "prepTime": "",
    "servingIdeas": "",
    "VARCHAR": "",
    "suggestedWine": "",
    "notes": "",
    "servings": "2"
  },
  {
    "id": 188,
    "timestamp": 2454970.47244213,
    "title": "Tarragon Chicken",
    "source": "Bonnie",
    "time": "",
    "directions": "Brown chicken.%0D%0D%0AAdd onion, garlic, tomatoes, vinegar, tarragon, mustard, salt and pepper to frying pan. %0D%0D%0ACook slowly.%0D%0D%0AAdd heavy cream and flour just before serving.",
    "equipment": "",
    "prepTime": "",
    "servingIdeas": "",
    "VARCHAR": "",
    "suggestedWine": "",
    "notes": "",
    "servings": "1"
  },
  {
    "id": 189,
    "timestamp": 2454970.4730787,
    "title": "Debby's Sweet and Sour Dressing",
    "source": "Debby",
    "time": "",
    "directions": "Mix all ingredients.",
    "equipment": "",
    "prepTime": "",
    "servingIdeas": "",
    "VARCHAR": "",
    "suggestedWine": "",
    "notes": "",
    "servings": "1"
  },
  {
    "id": 190,
    "timestamp": 2454970.47366898,
    "title": "Honey-Mustard Dressing",
    "source": "500 Fat Free Recipes",
    "time": "",
    "directions": "Combine all ingredients in a jar and shake until blended.%0D%0D%0AChill for 30 minutes before serving.%0D%0D%0A%0D%0D%0ACalories per serving :  69%0D%0D%0AFat :  2g",
    "equipment": "",
    "prepTime": "",
    "servingIdeas": "",
    "VARCHAR": "",
    "suggestedWine": "",
    "notes": "",
    "servings": "4"
  },
  {
    "id": 191,
    "timestamp": 2454970.47418981,
    "title": "Lemon Vinaigrette",
    "source": "Bonnie",
    "time": "",
    "directions": "Whisk together lemon juice and zest, vinegar, and mustard.%0D%0D%0ASlowly whisk in the olive oil.%0D%0D%0ASeason with salt and pepper.",
    "equipment": "",
    "prepTime": "",
    "servingIdeas": "",
    "VARCHAR": "",
    "suggestedWine": "",
    "notes": "",
    "servings": "4"
  },
  {
    "id": 192,
    "timestamp": 2454970.47642361,
    "title": "Olive Oil Dressing",
    "source": "Bonnie",
    "time": "",
    "directions": "Whisk together vinegar, mustard, and lemon juice.%0D%0D%0AAdd the olive oil in a slow, steady stream while whisking.%0D%0D%0AAdd parsley, salt, and pepper.",
    "equipment": "",
    "prepTime": "",
    "servingIdeas": "",
    "VARCHAR": "",
    "suggestedWine": "",
    "notes": "",
    "servings": "4"
  },
  {
    "id": 193,
    "timestamp": 2454971.29376157,
    "title": "Poppy Seed Dressing",
    "source": "Debbie",
    "time": "",
    "directions": "Mix all ingredients in food processor for 10 minutes",
    "equipment": "",
    "prepTime": "",
    "servingIdeas": "",
    "VARCHAR": "",
    "suggestedWine": "",
    "notes": "",
    "servings": "1"
  },
  {
    "id": 194,
    "timestamp": 2454971.29435185,
    "title": "Sweet and Sour Dressing",
    "source": "Debbie",
    "time": "",
    "directions": "Combine all ingredients.",
    "equipment": "",
    "prepTime": "",
    "servingIdeas": "",
    "VARCHAR": "",
    "suggestedWine": "",
    "notes": "",
    "servings": "1"
  },
  {
    "id": 195,
    "timestamp": 2454971.29738426,
    "title": "The Trellis Vinaigrette",
    "source": "Bonnie",
    "time": "",
    "directions": "Whisk together vinegar, lemon juice, mustard, salt, and pepper.%0D%0D%0AAdd the oil in a slow, steady stream while whisking. %0D%0D%0ACover with plastic wrap and set aside at room temperature, up to several hours, or refrigerate for several days.%0D%0D%0AIf refrigerated, return the vinaigrette to room temperature and whisk thoroughly before serving.",
    "equipment": "",
    "prepTime": "",
    "servingIdeas": "",
    "VARCHAR": "",
    "suggestedWine": "",
    "notes": "",
    "servings": "1"
  },
  {
    "id": 196,
    "timestamp": 2454971.30064815,
    "title": "Broccoli and Cauliflower Salad",
    "source": "Weight Watchers Favorites",
    "time": "",
    "directions": "In small bowl combine yogurt, mayo, vinegar and celery seed.%0D%0D%0AMix well and set aside.%0D%0D%0AIn large bowl combine remaining ingredients.%0D%0D%0AAdd yogurt mixture and toss well.%0D%0D%0A %0D%0D%0AMakes 2 servings.%0D%0D%0AEach serving (about 1 3/4 cups) provides :%0D%0D%0A1/2 milk, 1 1/2 fats, 1/4 protein, 3 1/4 vegetables 1/2 fruit, 15 optional calories.%0D%0D%0A%0D%0D%0APer serving :  197 calories",
    "equipment": "",
    "prepTime": "",
    "servingIdeas": "",
    "VARCHAR": "",
    "suggestedWine": "",
    "notes": "",
    "servings": "2"
  },
  {
    "id": 197,
    "timestamp": 2454971.30138889,
    "title": "Broccoli-Raisin Salad",
    "source": "Debbie",
    "time": "",
    "directions": "Combine broccoli, onion and raisins.%0D%0D%0ATo make the dressing combine mayonnaise, sugar and vinegar and mix well.%0D%0D%0AAdd dressing to salad mixture and toss well.%0D%0D%0AAdd bacon just before serving.%0D%0D%0AServe chilled.",
    "equipment": "",
    "prepTime": "",
    "servingIdeas": "",
    "VARCHAR": "",
    "suggestedWine": "",
    "notes": "",
    "servings": "1"
  },
  {
    "id": 198,
    "timestamp": 2454971.30221065,
    "title": "Honey Chicken Stir-Fry Salad",
    "source": "Bonnie",
    "time": "",
    "directions": "Whisk together honey and vinegar.%0D%0D%0AHeat a large frying pan.  When hot, add bacon and stir fry, about 3-4 minutes.  Drain bacon.%0D%0D%0AWipe fat from pan.  Return pan to heat for 5 minutes, until very hot.%0D%0D%0APlace chicken in pan, season with salt and pepper, and stir fry until browned, about 2-3 minutes.%0D%0D%0AAdd scallions and stir fry for 30 seconds.%0D%0D%0AAdd the honey and vinegar mixture and cook for 45 seconds.%0D%0D%0ARemove from heat and mix in bacon.  %0D%0D%0APlace chicken on top of salad.%0D%0D%0A%0D%0D%0AUse with Mustard Vinaigrette.",
    "equipment": "",
    "prepTime": "",
    "servingIdeas": "",
    "VARCHAR": "",
    "suggestedWine": "",
    "notes": "",
    "servings": "4"
  },
  {
    "id": 199,
    "timestamp": 2454971.30268519,
    "title": "Honey Mustard Roasted Walnuts",
    "source": "Bonnie",
    "time": "",
    "directions": "Heat butter over medium heat.  When butter is melted and hot, remove from heat, add honey and mustard, and whisk to combine.%0D%0D%0AAdd the walnuts to the hot honey mustard mixture.%0D%0D%0AEvenly coat the walnuts.%0D%0D%0ASpread walnuts on a baking sheet.%0D%0D%0ASeparate and spread the walnuts.%0D%0D%0ABake at 325 degrees for 12 minutes.%0D%0D%0ARemove from oven and season with salt.  %0D%0D%0ACool the walnuts to room temperature.%0D%0D%0AKeep at room temperature in a tightly sealed plastic container.",
    "equipment": "",
    "prepTime": "",
    "servingIdeas": "",
    "VARCHAR": "",
    "suggestedWine": "",
    "notes": "",
    "servings": "1"
  },
  {
    "id": 200,
    "timestamp": 2454971.30385417,
    "title": "Mandarin Salad",
    "source": "Debbie",
    "time": "",
    "directions": "Cook almonds and 1tbs. + 1 tsp. sugar over low heat.%0D%0D%0AStir constantly until sugar is dissolved.%0D%0D%0ASpread on ungreased cookie sheet.%0D%0D%0ACombine remaining ingredients.",
    "equipment": "",
    "prepTime": "",
    "servingIdeas": "",
    "VARCHAR": "",
    "suggestedWine": "",
    "notes": "",
    "servings": "1"
  },
  {
    "id": 201,
    "timestamp": 2454971.35696759,
    "title": "No Guilt Potato Salad",
    "source": "Weight Watchers Favorites",
    "time": "",
    "directions": "Combine all ingredients, stirring to coat vegetables with dressing.%0D%0D%0A%0D%0D%0AMakes 4 servings.%0D%0D%0APer serving :  121 calories%0D%0D%0A%0D%0D%0AEach serving provides :%0D%0D%0A1/4 vegetable, 1 bread, 25 optional calories",
    "equipment": "",
    "prepTime": "",
    "servingIdeas": "",
    "VARCHAR": "",
    "suggestedWine": "",
    "notes": "",
    "servings": "2"
  },
  {
    "id": 202,
    "timestamp": 2454971.35827546,
    "title": "Spinach Salad",
    "source": "BH G New Dieter's Cookbook",
    "time": "",
    "directions": "For salad, in a large bowl place spinach, orange sections, onion, and water chestnuts.  Sprinkle with pepper.%0D%0D%0AFor dressing, in jar combine orange juice, oil, honey, sesame seed, and mustard.  Cover and shake.  Pour dressing over salad.  Toss lightly.  Serve immediately.%0D%0D%0A%0D%0D%0ACalories per serving :  141%0D%0D%0AFat :  4g",
    "equipment": "",
    "prepTime": "",
    "servingIdeas": "",
    "VARCHAR": "",
    "suggestedWine": "",
    "notes": "",
    "servings": "4"
  },
  {
    "id": 203,
    "timestamp": 2454971.35953704,
    "title": "Spud, Spinach and Bean Salad",
    "source": "Weight Watchers Quick Meals",
    "time": "",
    "directions": "For dressing :  Combine 4 oz. beans, broth, oil, vinegar, garlic, salt, mustard, and pepper in food processor and puree.%0D%0D%0A%0D%0D%0AIn large salad bowl combine spinach, potatoes, onion, and the remaining beans.%0D%0D%0A%0D%0D%0AJust before serving, pour dressing over salad and toss.%0D%0D%0A%0D%0D%0A%0D%0D%0AEach serving (2 Cups) :  3/4 fat, 3 1/4 veg., 1 protein,1/2 bread%0D%0D%0A%0D%0D%0A181 calories, 4 gr. fat",
    "equipment": "",
    "prepTime": "",
    "servingIdeas": "",
    "VARCHAR": "",
    "suggestedWine": "",
    "notes": "",
    "servings": "2"
  },
  {
    "id": 204,
    "timestamp": 2454971.36050926,
    "title": "Taco Salad",
    "source": "Bonnie",
    "time": "",
    "directions": "Mix meat, vegetables and cheese.%0D%0D%0AToss with Catalina dressing.%0D%0D%0AWhen ready to serve, mix in Doritos.%0D%0D%0ATaco sauce may be added.",
    "equipment": "",
    "prepTime": "",
    "servingIdeas": "",
    "VARCHAR": "",
    "suggestedWine": "",
    "notes": "",
    "servings": "1"
  },
  {
    "id": 205,
    "timestamp": 2454971.38269676,
    "title": "Tex-Mex Salad",
    "source": "Weight Watchers Favorites",
    "time": "",
    "directions": "In large bowl combine all ingredients except corn chips.%0D%0D%0AToss well to coat with dressing.%0D%0D%0ASurround with corn chips just before serving.%0D%0D%0A%0D%0D%0APer serving :  315 calories%0D%0D%0A%0D%0D%0AEach serving provides :%0D%0D%0A1/2 fat, 1/2 protein, 3 1/2 vegetables, 2 1/4, breads, 40 optional calories",
    "equipment": "",
    "prepTime": "",
    "servingIdeas": "",
    "VARCHAR": "",
    "suggestedWine": "",
    "notes": "",
    "servings": "2"
  },
  {
    "id": 206,
    "timestamp": 2454971.38362268,
    "title": "Orange Sauce",
    "source": "Bonnie",
    "time": "",
    "directions": "Heat sugar, cornstarch, orange juice and salt to boiling.%0D%0D%0ARemove from heat and add butter and vanilla.%0D%0D%0A",
    "equipment": "",
    "prepTime": "",
    "servingIdeas": "",
    "VARCHAR": "",
    "suggestedWine": "",
    "notes": "",
    "servings": "1"
  },
  {
    "id": 207,
    "timestamp": 2454971.38466435,
    "title": "Seafood Sauce",
    "source": "Bonnie",
    "time": "",
    "directions": "Boil wine, vinegar, shallots, thyme, bay leaf and pepper until liquid is evaporated.  %0D%0D%0AAdd cream and boil until reduced by half.%0D%0D%0AReduce heat to low and whisk in butter, 1 tbs. at a time.%0D%0D%0ADo not allow sauce to simmer%3B  it should not get hot enought to separate. %0D%0D%0AAdd lemon juice, salt and pepper.%0D%0D%0AKeep warm by setting bowl in a larger pan of very warm water.",
    "equipment": "",
    "prepTime": "",
    "servingIdeas": "",
    "VARCHAR": "",
    "suggestedWine": "",
    "notes": "",
    "servings": "4"
  },
  {
    "id": 208,
    "timestamp": 2454971.38581019,
    "title": "Arthur's Crabs",
    "source": "Arthur Knudsen",
    "time": "",
    "directions": "Clean crabs.%0D%0D%0ALayer crabs with all the ingredients.  Top with beer and olive oil.%0D%0D%0ACook for 45 minutes.%0D%0D%0AHalfway through, remove crabs and pour broth into bowl.  Return crabs to pot and pour broth over crabs.%0D%0D%0A",
    "equipment": "",
    "prepTime": "",
    "servingIdeas": "",
    "VARCHAR": "",
    "suggestedWine": "",
    "notes": "",
    "servings": "1"
  },
  {
    "id": 209,
    "timestamp": 2454971.38674769,
    "title": "Baked Crab and Broccoli",
    "source": "BH G New Dieter's Cookbook",
    "time": "",
    "directions": "Cook broccoli and drain.%0D%0D%0AArrange broccoli and crabmeat in bottom of pan.%0D%0D%0AFor sauce, cook mushrooms and garlic in butter for about 4 minutes. Stir in flour and 1/8 tsp. of pepper.  Stir in milk.  Cook and stir till thickened and bubbly%3B  remove from heat.  Stir in cheese until melted.  Spoon sauce over top of broccoli and crab.  Cover with foil and chill for 2 - 24 hours.%0D%0D%0ABake, covered, at 400 for 20 25 minutes or until bubbly.  %0D%0D%0AWhen ready to serve, sprinkle with parmesan cheese.%0D%0D%0A%0D%0D%0ACalories per serving :  184%0D%0D%0AFat :  8g",
    "equipment": "",
    "prepTime": "",
    "servingIdeas": "",
    "VARCHAR": "",
    "suggestedWine": "",
    "notes": "",
    "servings": "2"
  },
  {
    "id": 210,
    "timestamp": 2454971.38824074,
    "title": "Baked Seafood au Gratin",
    "source": "Bonnie",
    "time": "",
    "directions": "Lightly grease 9x13%22 baking dish.%0D%0D%0ASaute onion and pepper in 1/2 cup of butter.  Cook until tender.%0D%0D%0AStir in 1/2 cup of flour and cook over medium heat for 10 minutes.%0D%0D%0AAdd the crabmeat and stir well.  Press this mixture into bottom of baking dish and set aside.%0D%0D%0A%0D%0D%0AIn a large Dutch oven, bring the water to a boil.  Add shrimp, scallops, and flounder.  Simmer for 3 minutes.  Drain and reserve 1 cup of the cooking liquid and set the seafood aside.%0D%0D%0A%0D%0D%0APreheat oven to 350 degrees.%0D%0D%0A%0D%0D%0AMelt remaining 1/2 cup butter over low heat.  Stir in the remaining 1/2 cup flour.  Cook and stir constantly for 1 minute.  Gradually add the milk plus the 1 cup reserved cooking liquid.  Raise heat to medium and cook, stirring constantly, until the mixture is thickened and bubbly.  Stir in the shredded Cheddar cheese, vinegar, and Worcestershire sauce, salt, pepper, and hot sauce.  Add the cooked seafood and stir gently.%0D%0D%0A%0D%0D%0ASpoon the seafood mixture over the crabmeat crust and sprinkle with the Parmesan cheese.  Bake for 30 minutes or until lightly browned.  Serve immediately",
    "equipment": "",
    "prepTime": "",
    "servingIdeas": "",
    "VARCHAR": "",
    "suggestedWine": "",
    "notes": "",
    "servings": "8"
  },
  {
    "id": 211,
    "timestamp": 2454971.38899306,
    "title": "Pina Colada Shrimp",
    "source": "Bonnie",
    "time": "",
    "directions": "Mix flour, salt and ginger.%0D%0D%0ACoat shrimp with flour mixture.%0D%0D%0ADip in pina colada mix.%0D%0D%0APat coconut onto shrimp.%0D%0D%0AFry immediately.%0D%0D%0AServe with warm marmelade or sweet and sour sauce.",
    "equipment": "",
    "prepTime": "",
    "servingIdeas": "",
    "VARCHAR": "",
    "suggestedWine": "",
    "notes": "",
    "servings": "1"
  },
  {
    "id": 212,
    "timestamp": 2454971.39241898,
    "title": "Seafood Salad Sandwiches",
    "source": "BH G New Dieter's Cookbook",
    "time": "",
    "directions": "Combine the shrimp or crabmeat, tomato, cheese, celery, almonds, and green onions.  Stir in sour cream.  Cover and chill for several hours.%0D%0D%0A%0D%0D%0A",
    "equipment": "",
    "prepTime": "",
    "servingIdeas": "",
    "VARCHAR": "",
    "suggestedWine": "",
    "notes": "",
    "servings": "6"
  },
  {
    "id": 213,
    "timestamp": 2454971.39305556,
    "title": "Shrimp Dip",
    "source": "Bonnie",
    "time": "",
    "directions": "Heat sugar, water, vinegar and egg until sugar melts.%0D%0D%0AAdd cream cheese and onion to heated mixture.%0D%0D%0AAdd shrimp and mix well.%0D%0D%0AChill.",
    "equipment": "",
    "prepTime": "",
    "servingIdeas": "",
    "VARCHAR": "",
    "suggestedWine": "",
    "notes": "",
    "servings": "1"
  },
  {
    "id": 214,
    "timestamp": 2454971.39358796,
    "title": "Shrimp with Gorgonzola Sauce",
    "source": "Bonnie",
    "time": "",
    "directions": "Coat shrimp with flour.%0D%0D%0AMelt butter over high heat.%0D%0D%0AAdd shrimp and saute 1 minute.%0D%0D%0AAdd brandy and cook 30 seconds.%0D%0D%0AAdd milk, cheese and marjoram and boil, about 3 minutes.%0D%0D%0A",
    "equipment": "",
    "prepTime": "",
    "servingIdeas": "",
    "VARCHAR": "",
    "suggestedWine": "",
    "notes": "",
    "servings": "1"
  },
  {
    "id": 215,
    "timestamp": 2454971.3943287,
    "title": "South Seas Shrimp and Mango",
    "source": "Debbie",
    "time": "",
    "directions": "Combine mustard, olive oil, orange juice and hot sauce.%0D%0D%0APour over shrimp and marinate 20 minutes.%0D%0D%0AGrill or bake.",
    "equipment": "",
    "prepTime": "",
    "servingIdeas": "",
    "VARCHAR": "",
    "suggestedWine": "",
    "notes": "",
    "servings": "1"
  },
  {
    "id": 216,
    "timestamp": 2454971.3953588,
    "title": "Spicy Baked Shrimp",
    "source": "Bonnie",
    "time": "",
    "directions": "Combine all ingredients except shrimp in 9x13%22 pan.%0D%0D%0AAdd shrimp and toss.%0D%0D%0ARefrigerate 1 hour.%0D%0D%0ABake at 450 for 10 minutes, stirring occasionally.%0D%0D%0A",
    "equipment": "",
    "prepTime": "",
    "servingIdeas": "",
    "VARCHAR": "",
    "suggestedWine": "",
    "notes": "",
    "servings": "1"
  },
  {
    "id": 217,
    "timestamp": 2454971.39675926,
    "title": "Szechwan Shrimp",
    "source": "BH G New Dieter's Cookbook",
    "time": "",
    "directions": "Stir together water, catsup, soy sauce, dry sherry, cornstarch, honey, red pepper, and ground ginger.  Set aside.%0D%0D%0AIn a large skillet stir-fry onion, and garlic for 30 seconds.%0D%0D%0AAdd shrimp.  Stir-fry 2 - 3 minutes.  Push shrimp to sides of skillet.  Stir catsup mixture into center of skillet.  Cook and stir until thickened and bubbly.  Cook and stir 2 minutes more.  Stir sauce and shrimp together.%0D%0D%0AServe with hot rice.%0D%0D%0A%0D%0D%0ACalories per serving :  155%0D%0D%0AFat :  5g%0D%0D%0A%0D%0D%0A5 points",
    "equipment": "",
    "prepTime": "",
    "servingIdeas": "",
    "VARCHAR": "",
    "suggestedWine": "",
    "notes": "",
    "servings": "2"
  },
  {
    "id": 218,
    "timestamp": 2454971.39863426,
    "title": "Blue Cheese Mushrooms",
    "source": "Bonnie",
    "time": "",
    "directions": "Mix oil, vinegar and cheese, using back of spoon to smooth the cheese.  Add the scallions, salt and pepper, and mix well.%0D%0D%0AToss mushrooms into the cheese mixture and mix lightly.%0D%0D%0AServe at once.%0D%0D%0A145 calories",
    "equipment": "",
    "prepTime": "",
    "servingIdeas": "",
    "VARCHAR": "",
    "suggestedWine": "",
    "notes": "",
    "servings": "2"
  },
  {
    "id": 219,
    "timestamp": 2454971.39952546,
    "title": "Broccoli Casserole",
    "source": "Bonnie",
    "time": "",
    "directions": "Melt butter.%0D%0D%0AAdd onions.%0D%0D%0AAdd flour and water.%0D%0D%0AThicken and remove from heat.%0D%0D%0AStir in cheese.%0D%0D%0AAdd broccoli.%0D%0D%0AFold in eggs.%0D%0D%0APour into 11/2 qt. casserole.%0D%0D%0ABake at 350 for 30 min.%0D%0D%0A",
    "equipment": "",
    "prepTime": "0 :45",
    "servingIdeas": "",
    "VARCHAR": "",
    "suggestedWine": "",
    "notes": "",
    "servings": "8"
  },
  {
    "id": 220,
    "timestamp": 2454971.40070602,
    "title": "Brussel Sprouts and Carrots with Honey Mustard Sauce",
    "source": "Natalie Dupree",
    "time": "",
    "directions": "Score ends with X.%0D%0D%0ACook carrots and brussel sprouts.%0D%0D%0ACombine butter, mustard, honey and mustard seed.%0D%0D%0ASeason with salt and pepper.%0D%0D%0AToss the dressing with vegetables and let it sit 15 minutes.%0D%0D%0AServe at room temperature, cold, or reheat and serve hot.",
    "equipment": "",
    "prepTime": "",
    "servingIdeas": "",
    "VARCHAR": "",
    "suggestedWine": "",
    "notes": "",
    "servings": "4"
  },
  {
    "id": 221,
    "timestamp": 2454971.40481481,
    "title": "Butternut Squash Fries",
    "source": "Bonnie",
    "time": "",
    "directions": "Mix salt, cumin and chili powder.%0D%0D%0ACut squash into 1/2 inch sticks.%0D%0D%0AToss squash with olive oil and half of salt mixture.%0D%0D%0ASpread in a single layer on a foil-lined baking sheet.%0D%0D%0ABake at 425 degrees for 35 minutes.%0D%0D%0AStir together sour cream and maple syrup.%0D%0D%0ASprinkle fries with additional salt, if needed.%0D%0D%0AServe with maple cream.%0D%0D%0A",
    "equipment": "",
    "prepTime": "",
    "servingIdeas": "",
    "VARCHAR": "",
    "suggestedWine": "",
    "notes": "",
    "servings": "1"
  },
  {
    "id": 222,
    "timestamp": 2454971.40530093,
    "title": "Carrots in Cheese Sauce",
    "source": "Bonnie",
    "time": "",
    "directions": "Boil carrots until tender.  Drain.%0D%0D%0ASaute onion in butter.  Add salt, pepper and 1/4 cup of flour.%0D%0D%0AAdd milk and cheese%0D%0D%0A%0D%0D%0ABake at 325 for 20 minutes.",
    "equipment": "",
    "prepTime": "0 :20",
    "servingIdeas": "",
    "VARCHAR": "",
    "suggestedWine": "",
    "notes": "",
    "servings": "6"
  },
  {
    "id": 223,
    "timestamp": 2454971.40583333,
    "title": "Cauliflower Mashed with Cheese",
    "source": "Bonnie",
    "time": "",
    "directions": "Cook cauliflower. (It should be very soft.)%0D%0D%0APuree in food processor with butter and cream.%0D%0D%0AMix together sour cream and cream cheese.%0D%0D%0AAdd cauliflower to cream cheese mixture and mix well.%0D%0D%0ABake at 350 degrees until brown on top.",
    "equipment": "",
    "prepTime": "",
    "servingIdeas": "",
    "VARCHAR": "",
    "suggestedWine": "",
    "notes": "",
    "servings": "1"
  },
  {
    "id": 224,
    "timestamp": 2454971.40659722,
    "title": "Corn Salad",
    "source": "Bonnie",
    "time": "",
    "directions": "Combine sour cream, worcestershire sauce and salt.%0D%0D%0ACut corn off the cob.%0D%0D%0AAdd corn, peppers and onion to mixture.%0D%0D%0ACover and refrigerate at least 2 hours.",
    "equipment": "",
    "prepTime": "",
    "servingIdeas": "",
    "VARCHAR": "",
    "suggestedWine": "",
    "notes": "",
    "servings": "1"
  },
  {
    "id": 225,
    "timestamp": 2454971.40737269,
    "title": "Cucumber Salad",
    "source": "Bonnie",
    "time": "",
    "directions": "Mix sugar, salt, celery seed, mustard seed and cider vinegar.%0D%0D%0APour over cucumber mixture.%0D%0D%0ARefrigerate 24 hours.",
    "equipment": "",
    "prepTime": "",
    "servingIdeas": "",
    "VARCHAR": "",
    "suggestedWine": "",
    "notes": "",
    "servings": "1"
  },
  {
    "id": 226,
    "timestamp": 2454971.40804398,
    "title": "Cucumbers in Sour Cream",
    "source": "Bonnie",
    "time": "",
    "directions": "Combine sour cream, vinegar, salt and pepper.%0D%0D%0APour over cucumbers and onions.%0D%0D%0A",
    "equipment": "",
    "prepTime": "",
    "servingIdeas": "",
    "VARCHAR": "",
    "suggestedWine": "",
    "notes": "",
    "servings": "1"
  },
  {
    "id": 227,
    "timestamp": 2454971.40935185,
    "title": "Eggplant Florentine",
    "source": "Weight Watchers Favorites",
    "time": "",
    "directions": "Cut eggplant into 8 diagonal slices.%0D%0D%0ADredge eggplant in breadcrumbs, coating both sides%3B  place on baking sheet.  Bake at 350 for 15 minutes%3B  turn and bake 15 minutes more, until tender.  Cool slightly.%0D%0D%0ASpray frying pan with nonstick spray.  Add onion and garlic.  Cook over medium-high heat until onion is translucent, about 3 minutes.  Add tomatoes, basil, oregano, and red and black pepper.  Bring to a boil%3B  reduce heat to low, cover, and simmer 15 minutes.  Set aside.%0D%0D%0AIn medium bowl, combine remaining ingredients until thoroughly blended.  Spread 2 heaping tbs. of spinach mixture on each slice of eggplant and roll to enclose.%0D%0D%0APour half the sauce into 13x9%22 pan.  Arrange eggplant rolls seam side down%3B  pour remaining sauce over top.  Cover with aluminum foil and bake until heated through, about 25 minutes.%0D%0D%0A%0D%0D%0APer serving :  394 calories, 15g fat%0D%0D%0A%0D%0D%0AEach serving provides :  2 1/2 proteins, 3 3/4 vegetables, 1 bread",
    "equipment": "",
    "prepTime": "",
    "servingIdeas": "",
    "VARCHAR": "",
    "suggestedWine": "",
    "notes": "",
    "servings": "2"
  },
  {
    "id": 228,
    "timestamp": 2454972.40921296,
    "title": "Eggplant Rollatini",
    "source": "Bonnie",
    "time": "",
    "directions": "Trim and peel eggplant.  Cut lengthwise into eight 1/4%22 slices.%0D%0D%0AHeat 2 tbs. oil over medium high heat.  Cook eggplant about 2 minutes on each side or until browned.  Drain on paper towels.%0D%0D%0A%0D%0D%0APour small amount of sauce into baking dish.%0D%0D%0A%0D%0D%0ACut mozzarella into eight 3x1/2%22x1/2%22 spears.  Divide prosciutto among eggplant slices.  Place a spear of cheese at one end of eggplant slice.  Roll up each slice around the cheese.%0D%0D%0AArrange eggplant slices, seam side down, on top of sauce.  Sprinkle with pepper.  Bake, uncovered, at 450 degrees for 8 - 10 minutes.%0D%0D%0A%0D%0D%0ATo serve, spoon additional sauce over eggplant and sprinkle with Parmesan cheese.",
    "equipment": "",
    "prepTime": "",
    "servingIdeas": "",
    "VARCHAR": "",
    "suggestedWine": "",
    "notes": "",
    "servings": "4"
  },
  {
    "id": 229,
    "timestamp": 2454972.4096875,
    "title": "Glazed Carrots",
    "source": "Bonnie",
    "time": "",
    "directions": "Cook carrots until tender.  Drain.%0D%0D%0AMelt butter.%0D%0D%0AStir in sugar.%0D%0D%0AAdd carrots.%0D%0D%0ACook uncovered for 15 minutes.",
    "equipment": "",
    "prepTime": "",
    "servingIdeas": "",
    "VARCHAR": "",
    "suggestedWine": "",
    "notes": "",
    "servings": "1"
  },
  {
    "id": 230,
    "timestamp": 2454972.4106713,
    "title": "Glazed Onions with Raisins and Almonds",
    "source": "Bon Appetit",
    "time": "",
    "directions": "Combine onions, sherry, raisins, honey, water, butter and thyme in large skillet.  %0D%0D%0ABring to boil over medium-high heat.  %0D%0D%0AReduce heat to very low%3B  cover and simmer until liquid evaporates and onions begin to caramelize, stirring often, about 45 minutes.  %0D%0D%0ASeason with salt and pepper.  %0D%0D%0ARemove from heat.  %0D%0D%0AStir almonds and red wine vinegar into onions.  %0D%0D%0AAdd a few teaspoons of water if mixture is too dry.  Serve warm.",
    "equipment": "",
    "prepTime": "",
    "servingIdeas": "",
    "VARCHAR": "",
    "suggestedWine": "",
    "notes": "",
    "servings": "8"
  },
  {
    "id": 231,
    "timestamp": 2454972.41123843,
    "title": "Green Bean Casserole",
    "source": "Bonnie",
    "time": "",
    "directions": "Stir soup, milk, soy sauce, pepper, beans and 1 1/3 cups onions in casserole.%0D%0D%0ABake at 350 degrees for 25 minutes.%0D%0D%0AStir.%0D%0D%0ATop with remaining onions and bake for 5 minutes more.",
    "equipment": "",
    "prepTime": "",
    "servingIdeas": "",
    "VARCHAR": "",
    "suggestedWine": "",
    "notes": "",
    "servings": "12"
  },
  {
    "id": 232,
    "timestamp": 2454972.41217593,
    "title": "Green Bean Casserole with goat cheese",
    "source": "Bonnie",
    "time": "",
    "directions": "Trim green beans and cook in small amount of boiling water for 3 minutes.%0D%0D%0AMix 2 tablespoons olive oil and 2 tablespoons soy sauce or balsamic vinegar.  Add green beans, mushrooms and red pepper and toss to coat.  %0D%0D%0ARoast vegetables at 375 degrees for 15 minutes.%0D%0D%0ASaute onions in olive oil.  While cooking, sprinkle with brown sugar.  Cook until onions are tender.%0D%0D%0APut bread crumbs in plastic bag.  Add hot onions and shake bag to coat onions.%0D%0D%0AMix cream cheese and goat cheese.  %0D%0D%0APlace vegetables in casserole dish and add cheese.  (Scatter small chunks of cheese around casserole dish.)  Top casserole with onions.%0D%0D%0ABake at 400 degrees for 10 minutes, just until heated through.",
    "equipment": "",
    "prepTime": "",
    "servingIdeas": "",
    "VARCHAR": "",
    "suggestedWine": "",
    "notes": "",
    "servings": "10"
  },
  {
    "id": 233,
    "timestamp": 2454972.41278935,
    "title": "Green Beans with Blue Cheese",
    "source": "Bonnie",
    "time": "",
    "directions": "Combine beans, peppers, salt and pepper in casserole.%0D%0D%0AHeat cream cheese and milk until melted.%0D%0D%0AAdd blue cheese.%0D%0D%0AThicken with corn starch. %0D%0D%0APour over vegetables.%0D%0D%0ABake at 350 degrees for 20 minutes.",
    "equipment": "",
    "prepTime": "",
    "servingIdeas": "",
    "VARCHAR": "",
    "suggestedWine": "",
    "notes": "",
    "servings": "1"
  },
  {
    "id": 234,
    "timestamp": 2454972.41372685,
    "title": "Marinated Vegetables",
    "source": "Bonnie",
    "time": "",
    "directions": "Whisk together mustard, oil, vinegar, sugar, oregano, salt, pepper and sun-dried tomatoes.%0D%0D%0A%0D%0D%0ASteam broccoli, cauliflower, green bean, and carrots until softened.%0D%0D%0A%0D%0D%0AMix marinade with steamed vegetables.  Add tomato and onion.%0D%0D%0A%0D%0D%0AChill at least 2 hours.",
    "equipment": "",
    "prepTime": "",
    "servingIdeas": "",
    "VARCHAR": "",
    "suggestedWine": "",
    "notes": "",
    "servings": "1"
  },
  {
    "id": 235,
    "timestamp": 2454972.41508102,
    "title": "Mushrooms and Chestnuts in Cream",
    "source": "Bon Appetit",
    "time": "",
    "directions": "Cut X in flat side of each chestnut.  Cook chestnuts in boiling water 10 minutes.  %0D%0D%0ARemove from heat.  Using slotted spoon remove chestnuts from water 1 at a time.  %0D%0D%0APeel while still hot, removing tough outer shell and paper-thin brown skin.  Cut chestnuts in half.%0D%0D%0A%0D%0D%0AMelt 1 1/2 tbs. butter with olive oil.  %0D%0D%0AAdd mushrooms and cook until golden brown, about 8 minutes.  %0D%0D%0AAdd sherry%3B  stir until evaporated.  %0D%0D%0ARemove from heat and set aside.%0D%0D%0A%0D%0D%0AMelt remaining 1 1/2 tbs. butter.  Add onion and cook until tender.%0D%0D%0AAdd chestnuts, chicken broth and thyme and bring to simmer.  %0D%0D%0ACover and simmer until chestnuts are tender, about 10 minutes.  %0D%0D%0ATransfer to pan with mushrooms.  %0D%0D%0A%0D%0D%0AAdd cream to chestnut mixture%3B  simmer until slightly thickened, about 3 minutes.  %0D%0D%0ATransfer to bowl and sprinkle with parsley.",
    "equipment": "",
    "prepTime": "",
    "servingIdeas": "",
    "VARCHAR": "",
    "suggestedWine": "",
    "notes": "",
    "servings": "8"
  },
  {
    "id": 236,
    "timestamp": 2454972.41613426,
    "title": "Oven-Fried Zucchini",
    "source": "Weight Watchers Favorites",
    "time": "",
    "directions": "On a shallow plate, combine all ingredients except zucchini and oil.%0D%0D%0ASlice off ends of zucchini.  Cut each zucchini lengthwise into quarters%3B  cut each quarter in half.  (into long, thin strips)%0D%0D%0AIn large plastic bag, combine oil, zucchini, and 2 tsp. of water.  Close bag and shake well to moisten zucchini.  Dredge zucchini in bread crumb mixture.%0D%0D%0AArrange in single layer on baking sheet.%0D%0D%0ABake at 475 for 10 -12 minutes until golden.%0D%0D%0A%0D%0D%0APer serving :  115 calories%0D%0D%0A%0D%0D%0A3 points%0D%0D%0A%0D%0D%0AEach serving provides :  1 fat, 2 vegetables, 1/2 bread 15 optional calories%0D%0D%0A",
    "equipment": "",
    "prepTime": "",
    "servingIdeas": "",
    "VARCHAR": "",
    "suggestedWine": "",
    "notes": "",
    "servings": "2"
  },
  {
    "id": 237,
    "timestamp": 2454972.41930556,
    "title": "Potatoes Diablo",
    "source": "Weight Watchers Favorites",
    "time": "",
    "directions": "In large skillet, heat oil%3B  add potatoes in a single layer.  Cook over medium-high heat until undersides are browned, about 4 minutes.  Turn potatoes and cook until browned, about 4 minutes more.%0D%0D%0ATransfer potatoes to baking pan.  Pour salsa evenly over top%3B  sprinkle with cheese.%0D%0D%0ABroil until cheese is melted, about 2 minutes.%0D%0D%0AArrange olives over top and serve immediately.%0D%0D%0A%0D%0D%0APer serving :  275 calories%0D%0D%0A%0D%0D%0AEach serving provides :  1 1/2 fats, 1 protein, 1 bread%0D%0D%0A%0D%0D%0A6 points",
    "equipment": "",
    "prepTime": "",
    "servingIdeas": "",
    "VARCHAR": "",
    "suggestedWine": "",
    "notes": "",
    "servings": "2"
  },
  {
    "id": 238,
    "timestamp": 2454972.4203588,
    "title": "Potatoes Provencale",
    "source": "Bon Appetit",
    "time": "",
    "directions": "Cook onion in olive oil until softened.  Add garlic, sugar, tomatoes, bouillon cube, salt, and pepper.  %0D%0D%0ACook mixture, covered, stirring occasionally, 20 minutes.  Stir in olives.  %0D%0D%0A%0D%0D%0AArrange one third of potatoes in baking dish.  %0D%0D%0ASpread half of tomato mixture over potatoes and top with half of the cheese.  %0D%0D%0AContinue layering, ending with potatoes.  %0D%0D%0A%0D%0D%0ABake, covered, at 375 degrees for 40 minutes.  %0D%0D%0ARemove foil and bake 20 - 30 minutes more.",
    "equipment": "",
    "prepTime": "",
    "servingIdeas": "",
    "VARCHAR": "",
    "suggestedWine": "",
    "notes": "",
    "servings": "4"
  },
  {
    "id": 239,
    "timestamp": 2454972.42096065,
    "title": "Roasted Potatoes with Horseradish and Mustard",
    "source": "Bonnie",
    "time": "",
    "directions": "Toss potatoes with 2 tbs. oil in large bowl.%0D%0D%0ASeason with salt and pepper.%0D%0D%0ASpread potatoes on cookie sheet in single layer.%0D%0D%0ARoast until almost tender, about 30 minutes, at 400 degrees.%0D%0D%0ACombine horseradish, mustard and butter.%0D%0D%0ASpoon half of mixture over potatoes%3B  toss well.%0D%0D%0ARoast potatoes 15 minutes.%0D%0D%0AToss potatoes with remaining mixture.%0D%0D%0ARoast until very tender, tossing occasionally, about 10 minutes.",
    "equipment": "",
    "prepTime": "",
    "servingIdeas": "",
    "VARCHAR": "",
    "suggestedWine": "",
    "notes": "",
    "servings": "8"
  },
  {
    "id": 240,
    "timestamp": 2454972.42232639,
    "title": "Southwestern Bean Bake",
    "source": "Bonnie",
    "time": "",
    "directions": "Cook bacon and drain.%0D%0D%0ACook onion until soft.%0D%0D%0AStir in ketchup, brown sugar, vinegar, mustard and cayenne.%0D%0D%0AAdd all the beans and bacon and cook.",
    "equipment": "",
    "prepTime": "",
    "servingIdeas": "",
    "VARCHAR": "",
    "suggestedWine": "",
    "notes": "",
    "servings": "6"
  },
  {
    "id": 241,
    "timestamp": 2454972.42337963,
    "title": "Spinach with Raisins and Pine Nuts",
    "source": "Bonnie",
    "time": "",
    "directions": "Cover the raisins with boiling water.  Allow to stand for 15 minutes.%0D%0D%0ADrain the raisins and squeeze out as much water as possible.%0D%0D%0APlace the oil in a large pan over medium heat.%0D%0D%0AAdd the garlic and heat for 2 - 3 minutes.%0D%0D%0AAdd the raisins and pine nuts and cook for 1 more minute, stirring frequently because the pine nuts will burn easily.%0D%0D%0AAdd the spinach while tossing and cook for about 3 minutes.%0D%0D%0ASeason with salt.%0D%0D%0A",
    "equipment": "",
    "prepTime": "",
    "servingIdeas": "",
    "VARCHAR": "",
    "suggestedWine": "",
    "notes": "",
    "servings": "6"
  },
  {
    "id": 242,
    "timestamp": 2454972.42625,
    "title": "Spinach with Tomatoes and Provolone",
    "source": "",
    "time": "",
    "directions": "Heat oil.%0D%0D%0AAdd onion and garlic%3B  stir 2 minutes.%0D%0D%0AAdd tomatoes%3B  simmer until onion is translucent and liquid evaporates, about 8 minutes.%0D%0D%0AAdd spinach.%0D%0D%0AToss until wilted, about 3 minutes.%0D%0D%0AMix in cheese.  Cook until cheese just begins to melt, about 2 minutes.%0D%0D%0ASeason with salt and pepper.",
    "equipment": "",
    "prepTime": "",
    "servingIdeas": "",
    "VARCHAR": "",
    "suggestedWine": "",
    "notes": "",
    "servings": "4"
  },
  {
    "id": 243,
    "timestamp": 2454972.42784722,
    "title": "Spinach-Rice Casserole",
    "source": "Bonnie",
    "time": "",
    "directions": "Cook rice with water.%0D%0D%0ACook spinach and drain.  Squeeze out excess water.%0D%0D%0AWhisk eggs and milk.%0D%0D%0AIn greased casserole, add rice, egg mixture, salt, butter, spinach, and cheese.  %0D%0D%0ABake at 350 degrees for 45 minutes to one hour.",
    "equipment": "",
    "prepTime": "",
    "servingIdeas": "",
    "VARCHAR": "",
    "suggestedWine": "",
    "notes": "",
    "servings": "10"
  },
  {
    "id": 244,
    "timestamp": 2454972.45920139,
    "title": "Spinach-Stuffed Onions",
    "source": "BH and G New Dieter's Cookbook",
    "time": "",
    "directions": "Bring 3 cups lightly salted water to boiling in a large pan. %0D%0D%0APeel onions, halve crosswise, and add to pan.%0D%0D%0AReturn to boiling and reduce heat.  Simmer, covered, about 20 minutes or till onions are just tender.  %0D%0D%0ADrain and cool.%0D%0D%0ARemove centers of onions, leaving 1/4 - 1/2 inch thick shells.%0D%0D%0AFinely chop 1/4 cup of onion centers%3B  set aside.%0D%0D%0ACook spinach and drain.  Stir in cream cheese till melted.             Add chopped onion, pimiento, bacon, and nutmeg.  Fill onion shells with spinach mixture.  Arrange onions in a baking dish.  Sprinkle with bread crumbs and melted butter.%0D%0D%0ABake, covered, at 350 degrees for 15 minutes%3B  uncover and bake %0D%0D%0A5 - 10 minutes more or till heated through.",
    "equipment": "",
    "prepTime": "",
    "servingIdeas": "",
    "VARCHAR": "",
    "suggestedWine": "",
    "notes": "",
    "servings": "4"
  },
  {
    "id": 245,
    "timestamp": 2454972.46028935,
    "title": "Stilton Potatoes",
    "source": "Bonnie",
    "time": "",
    "directions": "Arrange 1/3 of potato slices in bottom of pan.%0D%0D%0ASprinkle with half the cheese, salt and pepper.%0D%0D%0ARepeat layer, ending with potatoes on top.%0D%0D%0ASprinkle with salt and pepper.%0D%0D%0APour small amount of broth into pan.%0D%0D%0ADot with butter.%0D%0D%0ABake at 350 degrees for 1 hour and 40 minutes, until potatoes are golden brown.",
    "equipment": "",
    "prepTime": "",
    "servingIdeas": "",
    "VARCHAR": "",
    "suggestedWine": "",
    "notes": "",
    "servings": "6"
  },
  {
    "id": 246,
    "timestamp": 2454972.46158565,
    "title": "Sweet Potato Casserole with Praline Topping",
    "source": "Bonnie",
    "time": "",
    "directions": "Beat eggs and stir in potatoes.%0D%0D%0AAdd remaining ingredients for potatoes and mix.%0D%0D%0ASpread in greased dish.%0D%0D%0A%0D%0D%0AMix brown sugar, flour and pecans.%0D%0D%0AAdd butter and blend.%0D%0D%0ASprinkle over potatoes.%0D%0D%0ABake at 350 for 60 - 70 minutes.%0D%0D%0A",
    "equipment": "",
    "prepTime": "",
    "servingIdeas": "",
    "VARCHAR": "",
    "suggestedWine": "",
    "notes": "",
    "servings": "1"
  },
  {
    "id": 247,
    "timestamp": 2454972.4622338,
    "title": "Sweet Potato Puff",
    "source": "Bonnie",
    "time": "",
    "directions": "Peel and cut sweet potatoes into 1%22 pieces. %0D%0D%0ABoil for 10 - 15 minutes.%0D%0D%0ADrain and mash until smooth.%0D%0D%0AAdd orange juice, egg and cinnamon and mix well.%0D%0D%0ABake at 375 degrees for 30 minutes.%0D%0D%0A%0D%0D%0AMakes 10 servings%0D%0D%0A2 pts.",
    "equipment": "",
    "prepTime": "",
    "servingIdeas": "",
    "VARCHAR": "",
    "suggestedWine": "",
    "notes": "",
    "servings": "10"
  },
  {
    "id": 248,
    "timestamp": 2454972.46328704,
    "title": "Sweet Potato Puff with Cornflake Topping",
    "source": "Bonnie",
    "time": "",
    "directions": "Place sweet potatoes in bowl and mix for 30 seconds.%0D%0D%0AAdd milk, sugar, eggs, butter, nutmeg and cinnamon.%0D%0D%0ATurn to speed 4 and beat for 1 minute. %0D%0D%0ASpread mixture in greased 9 inch pie plate.%0D%0D%0ABake at 400 degrees for 20 minutes or until set.%0D%0D%0A%0D%0D%0APlace all Crunchy Praline Topping ingredients in mixer bowl.  %0D%0D%0ATurn to Stir Speed and mix about 15 seconds.%0D%0D%0ASpread on hot puff.%0D%0D%0ABake 10 minutes longer.%0D%0D%0A%0D%0D%0A6 servings.",
    "equipment": "",
    "prepTime": "",
    "servingIdeas": "",
    "VARCHAR": "",
    "suggestedWine": "",
    "notes": "",
    "servings": "6"
  },
  {
    "id": 249,
    "timestamp": 2454972.46420139,
    "title": "Vegetable Scramble",
    "source": "Weight Watchers",
    "time": "",
    "directions": "Cook egg substitute with vegetables.%0D%0D%0AServe with English muffin.",
    "equipment": "",
    "prepTime": "",
    "servingIdeas": "",
    "VARCHAR": "",
    "suggestedWine": "",
    "notes": "",
    "servings": "1"
  },
  {
    "id": 250,
    "timestamp": 2454972.4659375,
    "title": "Vegetarian Chili",
    "source": "Better Homes and Gardens",
    "time": "",
    "directions": "Heat oil.  Add pepper, onion and garlic.  Cover and cook over medium heat about 10 minutes.  Stir in undrained tomatoes, drained beans, raisins, vinegar, chili powder, parsley, sugar, basil, oregano, allspice, salt, and pepper.  Bring to boiling%3B  reduce heat.  Simmer, covered, for 1 1/2 hours.  Stir in beer.  Return to boiling%3B  simmer, uncovered, for 30 minutes more or to desired consistency.  Stir in cashews.  Sprinkle cheese on each serving.  Makes about 12 cups.%0D%0D%0A%0D%0D%0APer serving :%0D%0D%0A333 cal., 11 g total fat (2 g sat. fat)",
    "equipment": "",
    "prepTime": "",
    "servingIdeas": "",
    "VARCHAR": "",
    "suggestedWine": "",
    "notes": "",
    "servings": "8"
  },
  {
    "id": 251,
    "timestamp": 2454972.46688657,
    "title": "Butternut Squash, Apple and Onion Medley",
    "source": "Bonnie",
    "time": "",
    "directions": "Toss squash, apples and onions with olive oil to coat.%0D%0D%0ASeason with salt and pepper.%0D%0D%0APlace in shallow baking pan.%0D%0D%0ARoast at 425 degrees for 15-20 minutes.%0D%0D%0AIn a small saucepan, simmer sugar, vinegar and soy sauce until reduced by one-third.%0D%0D%0AAdd butter a piece at a time and whisk.%0D%0D%0AToss vegetables with dressing and serve.",
    "equipment": "",
    "prepTime": "",
    "servingIdeas": "",
    "VARCHAR": "",
    "suggestedWine": "",
    "notes": "",
    "servings": "8"
  },
  {
    "id": 252,
    "timestamp": 2454972.46759259,
    "title": "Apple Broccoli Salad",
    "source": "Bonnie",
    "time": "",
    "directions": "Prepare apples.%0D%0D%0AStir into orange juice to keep from turning brown.%0D%0D%0ACombine with broccoli slaw and raisins.%0D%0D%0AAdd yogurt and mix well.%0D%0D%0AChill.%0D%0D%0A%0D%0D%0AMakes 6 servings%0D%0D%0A1 point%0D%0D%0A",
    "equipment": "",
    "prepTime": "",
    "servingIdeas": "",
    "VARCHAR": "",
    "suggestedWine": "",
    "notes": "",
    "servings": "6"
  },
  {
    "id": 253,
    "timestamp": 2454972.468125,
    "title": "Blizzard",
    "source": "Bonnie",
    "time": "",
    "directions": "Mix all ingredients in food processor.%0D%0D%0A%0D%0D%0A3 Pts.",
    "equipment": "",
    "prepTime": "",
    "servingIdeas": "",
    "VARCHAR": "",
    "suggestedWine": "",
    "notes": "",
    "servings": "1"
  },
  {
    "id": 254,
    "timestamp": 2454972.46885417,
    "title": "Breakfast Couscous",
    "source": "Bonnie",
    "time": "",
    "directions": "Bring water and milk to a boil.%0D%0D%0A%0D%0D%0AAdd remaining ingredients and mix.%0D%0D%0A%0D%0D%0ARemove from heat.%0D%0D%0A%0D%0D%0ACover and let stand for 10 minutes.%0D%0D%0A%0D%0D%0A3 servings - 4 pts.",
    "equipment": "",
    "prepTime": "",
    "servingIdeas": "",
    "VARCHAR": "",
    "suggestedWine": "",
    "notes": "",
    "servings": "3"
  },
  {
    "id": 255,
    "timestamp": 2454972.46958333,
    "title": "Chocolate Pudding",
    "source": "Bonnie",
    "time": "",
    "directions": "Bring milk, cocoa, sugar, cornstarch and salt to a  boil over medium-low heat, stirring constantly for 2 minutes.%0D%0D%0ABeat yolk.%0D%0D%0AWhisk in 1 tbs. of chocolate mixture.%0D%0D%0AWhisk the yolk back into the remaining chocolate mixture.%0D%0D%0ACook over medium-low heat, stirring constantly, for 2 minutes.%0D%0D%0ADo not let mixture boil.%0D%0D%0ARemove from heat and add vanilla.%0D%0D%0A%0D%0D%0AServes 4%0D%0D%0A3 points",
    "equipment": "",
    "prepTime": "",
    "servingIdeas": "",
    "VARCHAR": "",
    "suggestedWine": "",
    "notes": "",
    "servings": "4"
  },
  {
    "id": 256,
    "timestamp": 2454972.47016204,
    "title": "Cinnamon Dip",
    "source": "Bonnie",
    "time": "",
    "directions": "Beat all ingredients until smooth.%0D%0D%0ACover and refrigerate.",
    "equipment": "",
    "prepTime": "",
    "servingIdeas": "",
    "VARCHAR": "",
    "suggestedWine": "",
    "notes": "",
    "servings": "1"
  },
  {
    "id": 257,
    "timestamp": 2454972.47087963,
    "title": "Jello Whip",
    "source": "Weight Watchers",
    "time": "",
    "directions": "Mix jello and boiling water.%0D%0D%0AAdd ice cubes and mix until ice is melted and jello is soft set.%0D%0D%0AFold in Cool Whip.",
    "equipment": "",
    "prepTime": "",
    "servingIdeas": "",
    "VARCHAR": "",
    "suggestedWine": "",
    "notes": "",
    "servings": "1"
  },
  {
    "id": 258,
    "timestamp": 2454972.47185185,
    "title": "Layered Strawberry Cheesecake Bowl",
    "source": "Bonnie",
    "time": "",
    "directions": "Combine berries and Splenda and refrigerate until ready to use.%0D%0D%0ABeat cream cheese with mixer until creamy.%0D%0D%0AGradually add milk.%0D%0D%0AAdd pudding and mix well.%0D%0D%0ABlend in Cool Whip.%0D%0D%0ASpoon half of the pudding mix into bowl.%0D%0D%0ATop with cake and berries and remaining cheese mixture.%0D%0D%0ARefrigerate 24 hours.%0D%0D%0AMelt chocolate and drizzle over dessert.%0D%0D%0ATop with additional Cool Whip.",
    "equipment": "",
    "prepTime": "",
    "servingIdeas": "",
    "VARCHAR": "",
    "suggestedWine": "",
    "notes": "",
    "servings": "1"
  },
  {
    "id": 259,
    "timestamp": 2454972.47253472,
    "title": "Pumpkin Spread",
    "source": "Bonnie",
    "time": "",
    "directions": "Mix all ingredients and let stand 5 minutes.%0D%0D%0A%0D%0D%0AOne serving (2 tbs.) - 1 point",
    "equipment": "",
    "prepTime": "",
    "servingIdeas": "",
    "VARCHAR": "",
    "suggestedWine": "",
    "notes": "",
    "servings": "1"
  },
  {
    "id": 260,
    "timestamp": 2454972.47364583,
    "title": "Venetian Peperonata",
    "source": "Bonnie",
    "time": "",
    "directions": "Cut eggplant in 3/4 inch rounds.  Sprinkle with salt.  Let stand for 45 minutes.  Pat dry.  Cut into 3/4 inch cubes.%0D%0D%0AHeat oil with garlic over medium heat until brown.  Discard garlic.%0D%0D%0AAdd onion%3B saute until golden.%0D%0D%0AAdd eggplant, peppers and wine%3B cover and simmer for until peppers are tender (about 30 minutes)%0D%0D%0AAdd tomatoes%3B increase heat to medium-high and saute uncovered until almost all liquid evaporates.  %0D%0D%0ASeason with salt and pepper.%0D%0D%0ASpoon over bread.  Sprinkle with gorgonzola.",
    "equipment": "",
    "prepTime": "",
    "servingIdeas": "",
    "VARCHAR": "",
    "suggestedWine": "",
    "notes": "",
    "servings": "1"
  },
  {
    "id": 261,
    "timestamp": 2454972.47412037,
    "title": "Yello",
    "source": "Weight Watchers",
    "time": "",
    "directions": "Mix boiling water and jello.%0D%0D%0AAdd ice cubes and stir until ice is melted and jello is soft set.%0D%0D%0AWhisk in the yogurt and mix well.%0D%0D%0A%0D%0D%0AMakes 6 servings, 1/2 pt. each",
    "equipment": "",
    "prepTime": "",
    "servingIdeas": "",
    "VARCHAR": "",
    "suggestedWine": "",
    "notes": "",
    "servings": "1"
  },
  {
    "id": 262,
    "timestamp": 2454972.47512731,
    "title": "Apple Half-Moons",
    "source": "Bonnie",
    "time": "",
    "directions": "Honey-Mustard Mayonnaise :%0D%0D%0ACombine all ingredients.  Can be refrigerated for 1 to 2 days.%0D%0D%0A%0D%0D%0ACut apples into wedges.%0D%0D%0APlace in a bowl and toss with lemon juice.%0D%0D%0ACut ham into strips.%0D%0D%0ASpread mayonnaise on one side of ham strip.%0D%0D%0ATop with chives and apple and wrap ham around middle of apple.%0D%0D%0AThe apples can be assembled 2 to 3 hours ahead.%0D%0D%0ACover and refrigerate.",
    "equipment": "",
    "prepTime": "",
    "servingIdeas": "",
    "VARCHAR": "",
    "suggestedWine": "",
    "notes": "",
    "servings": "1"
  },
  {
    "id": 263,
    "timestamp": 2454972.4759838,
    "title": "California Guacamole",
    "source": "Bonnie",
    "time": "",
    "directions": "Coarsely mash avocados. (DO NOT PUREE)%0D%0D%0AFold in remaining ingredients.%0D%0D%0AGuacamole is best made as close to serving as possible.%0D%0D%0AFor short-term storage, seal in an airtight container with a piece of plastic wrap against the surface of the guacamole.",
    "equipment": "",
    "prepTime": "",
    "servingIdeas": "",
    "VARCHAR": "",
    "suggestedWine": "",
    "notes": "",
    "servings": "6"
  },
  {
    "id": 264,
    "timestamp": 2454972.47685185,
    "title": "Italian Appetizer Bites",
    "source": "Bonnie",
    "time": "",
    "directions": "Unroll one package of crescent rolls and line bottom of pan.  Pinch seams together.%0D%0D%0ACover rolls with half of salami, ham, cheese and roasted peppers.  Beat together six eggs and parmesan and pour over appetizer.%0D%0D%0ARepeat layering and top with crescent rolls.%0D%0D%0ABeat remaining egg and brush over top. %0D%0D%0ACover dish with aluminum foil. %0D%0D%0ABake at 350 degrees for 30 minutes.%0D%0D%0AUncover and bake 30 more minutes.%0D%0D%0ACool for one hour and cut into squares.",
    "equipment": "",
    "prepTime": "",
    "servingIdeas": "",
    "VARCHAR": "",
    "suggestedWine": "",
    "notes": "",
    "servings": "1"
  },
  {
    "id": 265,
    "timestamp": 2454972.47930556,
    "title": "Buckeye bars",
    "source": "Bonnie",
    "time": "",
    "directions": "Line an 8%22 square pan with foil, extending foil over sides.%0D%0D%0ABeat butter and peanut butter with mixer until blended.%0D%0D%0AAdd wafer crumbs and mix well.%0D%0D%0AGradually add sugar, mixing well after each addition.%0D%0D%0APress onto the bottom of prepared pan.%0D%0D%0AMicrowave frozen Cool Whip and chocolate on high 1 minute.%0D%0D%0AStir and microwave 15-30 seconds more or until chocolate is melted.%0D%0D%0AStir until blended.%0D%0D%0APour over peanut butter layer and spread to cover.%0D%0D%0ARefrigerate 2 hours.%0D%0D%0AUse foil handles to lift dessert from pan before cutting.%0D%0D%0A",
    "equipment": "",
    "prepTime": "",
    "servingIdeas": "",
    "VARCHAR": "",
    "suggestedWine": "",
    "notes": "",
    "servings": "1"
  },
  {
    "id": 266,
    "timestamp": 2454972.48042824,
    "title": "Oat and Toffee Grahams",
    "source": "Bonnie",
    "time": "",
    "directions": "Line a 15x10 inch baking pan with foil.%0D%0D%0AArrange graham crackers in a single layer in pan.%0D%0D%0AIn a large bowl, combine oats, sugars and flour.%0D%0D%0AStir in melted butter, egg and vanilla until well mixed.%0D%0D%0ASpoon over graham crackers and spread evenly to cover crackers.%0D%0D%0ABake at 350 degrees for 20-25 minutes, until mixture bubbles and is lightly browned on top.%0D%0D%0ARemove from oven and sprinkle with chocolate chips.%0D%0D%0AReturn to oven for 1 minute.%0D%0D%0ASpread melted chocolate over top to cover.%0D%0D%0ASprinkle with almonds.%0D%0D%0ACool and cut into 32 bars.",
    "equipment": "",
    "prepTime": "",
    "servingIdeas": "",
    "VARCHAR": "",
    "suggestedWine": "",
    "notes": "",
    "servings": "32"
  },
  {
    "id": 267,
    "timestamp": 2454972.48195602,
    "title": "Kathy's Sloppy Joe",
    "source": "Kathy",
    "time": "",
    "directions": "Brown meat in olive oil on all sides.%0D%0D%0ASaute onions, peppers and garlic in olive oil.%0D%0D%0AAdd remaining ingredients and heat.%0D%0D%0APut mixture in crock pot with meat.%0D%0D%0ACook for 6 - 8 hours.",
    "equipment": "",
    "prepTime": "",
    "servingIdeas": "",
    "VARCHAR": "",
    "suggestedWine": "",
    "notes": "",
    "servings": "1"
  },
  {
    "id": 268,
    "timestamp": 2454972.48268519,
    "title": "Sausage Stuffing",
    "source": "Marge Farrell",
    "time": "",
    "directions": "Cook onion and mushrooms in butter.%0D%0D%0ARemove from stove and put in large bowl.%0D%0D%0ACook and crumble sausage.%0D%0D%0AAdd to bowl with onion mixture.%0D%0D%0ACook Stove Top Stuffing, following directions on box.%0D%0D%0AMix stuffing with onion and sausage mixture.%0D%0D%0ASeason with salt and pepper.%0D%0D%0ABake at 350 degrees until heated.",
    "equipment": "",
    "prepTime": "",
    "servingIdeas": "",
    "VARCHAR": "",
    "suggestedWine": "",
    "notes": "",
    "servings": "1"
  },
  {
    "id": 269,
    "timestamp": 2454972.48335648,
    "title": "Holiday Pecans",
    "source": "Bonnie",
    "time": "",
    "directions": "Line a baking sheet with foil.%0D%0D%0A%0D%0D%0AStir together sugar, salt and cinnamon.%0D%0D%0A%0D%0D%0AIn a separate bowl, whisk egg white and 1 tbs. water until foamy.%0D%0D%0AAdd pecans to bowl and stir until coated.%0D%0D%0APour in sugar mixture and melted butter.%0D%0D%0AStir well until combined.%0D%0D%0A%0D%0D%0ASpread pecan mixture in an even layer on baking sheet.%0D%0D%0ABake at 300 degrees for 25 minutes, stirring occasionally.%0D%0D%0ACool completely.%0D%0D%0A",
    "equipment": "",
    "prepTime": "",
    "servingIdeas": "",
    "VARCHAR": "",
    "suggestedWine": "",
    "notes": "",
    "servings": "1"
  },
  {
    "id": 270,
    "timestamp": 2454972.48457176,
    "title": "Thai Chicken Wings",
    "source": "Bonnie",
    "time": "",
    "directions": "Line a cookie sheet with aluminum foil.%0D%0D%0AToss wings with olive oil, salt and pepper. %0D%0D%0APlace on cookie sheet in a single layer.%0D%0D%0ABake at 400 degrees for 50 minutes, until crispy and golden.%0D%0D%0AUsing tongs, toss with Thai honey and spice sauce.%0D%0D%0A%0D%0D%0ATHAI HONEY and SPICE SAUCE :%0D%0D%0APlace all ingredients in bowl and stir well.",
    "equipment": "",
    "prepTime": "",
    "servingIdeas": "",
    "VARCHAR": "",
    "suggestedWine": "",
    "notes": "",
    "servings": "1"
  },
  {
    "id": 272,
    "timestamp": 2454972.48626157,
    "title": "Shrimp with corn and bacon",
    "source": "Bonnie",
    "time": "",
    "directions": "Cook onion with oil for 4-6 minutes.%0D%0D%0AAdd wine and cook until reduced by half, 2-3 minutes%0D%0D%0AAdd cream and bring to a boil.%0D%0D%0AStir in shrimp and corn.%0D%0D%0ASimmer 4-6 minutes.%0D%0D%0AStir in bacon and serve over rice.",
    "equipment": "",
    "prepTime": "",
    "servingIdeas": "",
    "VARCHAR": "",
    "suggestedWine": "",
    "notes": "",
    "servings": "4"
  },
  {
    "id": 274,
    "timestamp": 2455634.11155093,
    "title": "Artichoke, Potato and Mushroom Casserole",
    "source": "",
    "time": "",
    "directions": "Arrange half of potatoes in baking dish, covering bottom completely.%0D%0D%0ATop with half of artichoke hearts and half of mushrooms.%0D%0D%0ACrumble half of goat cheese over vegetables.%0D%0D%0ASprinkle with salt, pepper, half of garlic and half of parmesan cheese.%0D%0D%0ADrizzle with olive oil.%0D%0D%0ACover with remaining artichoke hearts, goat cheese, garlic, parmesan.%0D%0D%0ATop with remaining potatoes.%0D%0D%0APour wine over vegetables.%0D%0D%0ADrizzle with olive oil.%0D%0D%0ACover dish with aluminum foil.%0D%0D%0ABake at 425 degrees for 40 minutes.%0D%0D%0AUncover and bake 5 more minutes until potatoes are browned.%0D%0D%0A",
    "equipment": "",
    "prepTime": "",
    "servingIdeas": "",
    "VARCHAR": "",
    "suggestedWine": "",
    "notes": "",
    "servings": "4"
  },
  {
    "id": 275,
    "timestamp": 2455640.10653935,
    "title": "Ricotta Orange Pound Cake",
    "source": "Bonnie",
    "time": "",
    "directions": "Combine flour, baking powder and salt.%0D%0D%0AStir to blend.%0D%0D%0ACream butter, ricotta and sugar until light and fluffy, about 3 minutes.%0D%0D%0AWith the machine running, add eggs 1 at a time.%0D%0D%0AAdd vanilla, orange zest and amaretto until combined.%0D%0D%0AAdd the dry ingredients and mix just until incorporated.%0D%0D%0APour the batter into a greased loaf pan.%0D%0D%0ABake at 350 degrees for 45 to 50 minutes, until cake pulls away from sides of pan.%0D%0D%0ALet cool and dust with confectioners sugar.%0D%0D%0A",
    "equipment": "",
    "prepTime": "",
    "servingIdeas": "",
    "VARCHAR": "",
    "suggestedWine": "",
    "notes": "",
    "servings": ""
  },
  {
    "id": 276,
    "timestamp": 2455640.12746528,
    "title": "Sausage and Rice Timbale",
    "source": "Bonnie",
    "time": "",
    "directions": "Cook rice, drain and let cool.%0D%0D%0AfMelt 2 tbs. butter and cook onion.%0D%0D%0AAdd sausage and cook.  %0D%0D%0AAdd garlic and tomato paste.%0D%0D%0AStir in chicken broth.%0D%0D%0ACook 15-20 minutes.%0D%0D%0AGrease an 8%22 square baking pan and sprinkle with breadcrumbs.%0D%0D%0AMix cooled rice with eggs and parmesan cheese.%0D%0D%0ASpread half of rice mixture over bottom of baking pan.%0D%0D%0ATop with provolone and meat mixture.%0D%0D%0ACover with remaining rice mixture.%0D%0D%0ABake at 450 degrees for 30 minutes.%0D%0D%0A%0D%0D%0A%0D%0D%0A",
    "equipment": "",
    "prepTime": "",
    "servingIdeas": "",
    "VARCHAR": "",
    "suggestedWine": "",
    "notes": "",
    "servings": "6"
  },
  {
    "id": 277,
    "timestamp": 2455643.30722222,
    "title": "Cranberry Shortbread Cookies",
    "source": "Bonnie",
    "time": "",
    "directions": "Cream butter and sugar until light and fluffy.%0D%0D%0AMix in flour, a little at a time.%0D%0D%0AStir in craisins.%0D%0D%0ARoll batter into balls.%0D%0D%0APlace on ungreased cookie sheet and flatten with a glass dipped in sugar.%0D%0D%0ABake at 325 degrees for 14-15 minutes.%0D%0D%0A",
    "equipment": "",
    "prepTime": "",
    "servingIdeas": "",
    "VARCHAR": "",
    "suggestedWine": "",
    "notes": "",
    "servings": "4 dozen"
  },
  {
    "id": 279,
    "timestamp": 2455835.04623843,
    "title": "Snickerdoodle Biscotti",
    "source": "",
    "time": "",
    "directions": "Mix flour, 1 cup sugar, baking powder and salt.%0D%0D%0AMix oil, vanilla and 3 eggs together and add to flour mixture.%0D%0D%0ATurn dough onto floured surface and knead 7 or 8 times.%0D%0D%0ADivide in half.%0D%0D%0AShape each portion into a roll 8 inches long and place six inches apart on cookie sheet lined w/parchment.%0D%0D%0AFlatten each roll to 1 inch thickness.%0D%0D%0ABrush tops of rolls with egg whte.%0D%0D%0ACombine 2 tbs. sugar and cinnamon and sprinkle over rolls.%0D%0D%0ABake at 350 degrees for 30 minutes.%0D%0D%0ACool 15 minutes.%0D%0D%0ACut into 1/2 inch slices and lay cut sides down on cookie sheet.%0D%0D%0AReduce oven temperature to 325 degrees.%0D%0D%0ABake 10 minutes more - 5 on each side.",
    "equipment": "",
    "prepTime": "",
    "servingIdeas": "",
    "VARCHAR": "",
    "suggestedWine": "",
    "notes": "",
    "servings": ""
  },
  {
    "id": 280,
    "timestamp": 2455835.05309028,
    "title": "Holiday Biscotti",
    "source": "",
    "time": "",
    "directions": "Mix flour and baking powder.%0D%0D%0AUsing electric mixer, beat sugar, butter and salt to blend.%0D%0D%0ABeat in eggs, one at a time.%0D%0D%0AAdd flour mixture and beat just until blended.%0D%0D%0AStir in pistachios and cranberries.%0D%0D%0AForm dough into 13x3%22 log.%0D%0D%0APlace on cookie sheet lined w/parchment.%0D%0D%0ABake at 350 degrees for 40 minutes.%0D%0D%0ACool for 30 minutes.%0D%0D%0ACut into 1/2%22 slices and lay cut side down on cookie sheet.%0D%0D%0ABake 15 minutes more.%0D%0D%0ACool complete and spread with melted chocolate.%0D%0D%0A",
    "equipment": "",
    "prepTime": "",
    "servingIdeas": "",
    "VARCHAR": "",
    "suggestedWine": "",
    "notes": "",
    "servings": ""
  },
  {
    "id": 281,
    "timestamp": 2455879.25736111,
    "title": "Sweet Potato Corn Chowder",
    "source": "",
    "time": "",
    "directions": "Fry bacon.  Drain and crumble.%0D%0D%0ADrain off bacon grease, keeping 1 tablespoon.%0D%0D%0AAdd onions to bacon grease.%0D%0D%0AAdd potatoes to the pot with enough chicken broth to cover potatoes.%0D%0D%0ABring to simmer until potatoes are tender.%0D%0D%0APuree with immersion blender.%0D%0D%0AAdd brown sugar, corn, scallions, cream, salt and pepper.%0D%0D%0A%0D%0D%0A",
    "equipment": "",
    "prepTime": "",
    "servingIdeas": "",
    "VARCHAR": "",
    "suggestedWine": "",
    "notes": "",
    "servings": ""
  },
  {
    "id": 282,
    "timestamp": 2455879.264375,
    "title": "Mexican Soup",
    "source": "",
    "time": "",
    "directions": "Heat oil and cook onion for 3 minutes.%0D%0D%0AAdd sweet potatoes, chicken broth and cumin.%0D%0D%0ACover and reduce heat.%0D%0D%0ASimmer until potatoes are tender.%0D%0D%0AProcess soup with immersion blender.%0D%0D%0AStir in corn, salsa, diced tomatoes and black beans.%0D%0D%0ASimmer to blend flavors.%0D%0D%0AServe with sour cream.%0D%0D%0A%0D%0D%0A",
    "equipment": "",
    "prepTime": "",
    "servingIdeas": "",
    "VARCHAR": "",
    "suggestedWine": "",
    "notes": "",
    "servings": ""
  },
  {
    "id": 283,
    "timestamp": 2455917.0075463,
    "title": "Nilla Turtle Cookie Balls",
    "source": "Bonnie",
    "time": "",
    "directions": "Mix cream cheese, Nilla wafers and caramel topping in food processor.%0D%0D%0AShape into 42 (1 inch) balls.%0D%0D%0AFreeze for 10 minutes.%0D%0D%0AMelt chocolate.%0D%0D%0ADip balls in chocolate and place on waxed paper.%0D%0D%0ARefrigerate.",
    "equipment": "",
    "prepTime": "",
    "servingIdeas": "",
    "VARCHAR": "",
    "suggestedWine": "",
    "notes": "",
    "servings": ""
  },
  {
    "id": 284,
    "timestamp": 2456376.22237269,
    "title": "Sausage Balls",
    "source": "",
    "time": "",
    "directions": "Combine all ingredients for sausage balls and mix until crumbly.%0D%0D%0AForm into 1%22 balls, squeezing until mixture holds together.%0D%0D%0APlace balls on baking sheet.%0D%0D%0ABake at 375 degrees for 18 to 20 minutes.%0D%0D%0AMix ingredients for dip and serve with sausage balls.%0D%0D%0A%0D%0D%0AMakes 60 sausage balls.%0D%0D%0A",
    "equipment": "",
    "prepTime": "",
    "servingIdeas": "",
    "VARCHAR": "",
    "suggestedWine": "",
    "notes": "",
    "servings": "60 balls"
  },
  {
    "id": 285,
    "timestamp": 2456376.23429398,
    "title": "Snickerdoodle Muffins",
    "source": "",
    "time": "",
    "directions": "Cinnamon crumble :%0D%0D%0AMix sugar, flour and cinnamon.%0D%0D%0AMash in 1/2 stick of softened butter.%0D%0D%0A%0D%0D%0AMix flour, baking powder, cinnamon, salt and baking soda.%0D%0D%0AIn separate bowl whisk eggs, sugar, butter, sour cream and vanilla.%0D%0D%0AFold into dry ingredients.%0D%0D%0AStir 1/2 cup of cinnamon crumble into batter.%0D%0D%0ADivide among muffin cups and top with remaining cinnamon crumble.%0D%0D%0ABake at 350 degrees for 25 to 28 minutes.%0D%0D%0A",
    "equipment": "",
    "prepTime": "",
    "servingIdeas": "",
    "VARCHAR": "",
    "suggestedWine": "",
    "notes": "",
    "servings": "12 muffins"
  },
  {
    "id": 286,
    "timestamp": 2456376.2397338,
    "title": "Fig and Blue Cheese Tarts",
    "source": "",
    "time": "",
    "directions": "Mix cream cheese and blue cheese until well blended.%0D%0D%0AStir marmalade and vinegar over low heat and add figs.%0D%0D%0ACook over low heat for 5 to 7 minutes.%0D%0D%0ARemove crescent rolls from package but do not unroll.%0D%0D%0ACut roll into 16 slices.%0D%0D%0APress center of each slice to make indentation.%0D%0D%0APlace blue cheese mixture in indentation and top with fig mixture.%0D%0D%0ABake at 350 degrees for 15 to 19 minutes.%0D%0D%0A",
    "equipment": "",
    "prepTime": "",
    "servingIdeas": "",
    "VARCHAR": "",
    "suggestedWine": "",
    "notes": "",
    "servings": "Makes 16"
  },
  {
    "id": 287,
    "timestamp": 2456376.24269676,
    "title": "Rib marinade",
    "source": "",
    "time": "",
    "directions": "Wrap ribs in aluminum foil.%0D%0D%0ABake at 325 degrees for 2 to 3 hours.",
    "equipment": "",
    "prepTime": "",
    "servingIdeas": "",
    "VARCHAR": "",
    "suggestedWine": "",
    "notes": "",
    "servings": ""
  }
]

export default RECIPES;